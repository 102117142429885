import { Card, Col, Image, Row, Tag, Spin } from 'antd'
import commaNumber from 'comma-number'
import React from 'react'

const dataMapping = {
  unitOption: (data) => ({
    id: data._id,
    image: data.selectedImage,
    title: data.parsedItemTitle,
    attributes: data.checkedAttributes,
  }),
  generalOption: (data) => ({
    id: data?._id,
    image: data?.mainImageUris?.[0],
    title: data?.generalOptionTitle,
  }),
}

const typeMapping = {
  unitOption: '원물 카탈로그',
  generalOption: '브랜드 카탈로그',
}

const typeTagStyle = {
  unitOption: 'bg-green-500 border-0 text-white',
  generalOption: 'bg-blue-500 border-0 text-white',
}

function BiddingOptionItemCard({
  biddingOptionInfo,
  liveBiddingPrice,
  type = 'unitOption',
  simple = false,
}) {
  const mappedData = dataMapping[type](biddingOptionInfo) || {}

  const { id, image, title, attributes = null } = mappedData
  const simpleTitle = title?.length > 24 ? `${title.slice(0, 24)}...` : title

  return (
    <Card>
      <Row align='middle'>
        <Col span={8}>
          <Image
            src={image}
            alt='mainImage'
            width={120}
            height={120}
            loading='lazy'
            style={{ objectFit: 'cover', borderRadius: 8 }}
          />
        </Col>
        <Col span={16}>
          <Tag className={typeTagStyle[type]}>{typeMapping[type]}</Tag>
          <div className='font-bold text-md '>
            {simple ? simpleTitle : title}
          </div>
          <div className='text-gray-500 mb-4'>{id?.toString()}</div>
          <div className='mb-5'>
            <span>
              {!liveBiddingPrice ? (
                <>입찰 정보 없음</>
              ) : (
                <div className='flex flex-row'>
                  <div className='mr-2'>실시간 최저가</div>
                  <div className='text-blue-500 font-bold'>
                    {commaNumber(liveBiddingPrice)}원
                  </div>
                </div>
              )}
            </span>
          </div>
          {attributes &&
            !simple &&
            Object.entries(attributes || {}).map(([key, value]) => (
              <div className='flex flex-1 flex-row'>
                <div className='mr-2'>{key}</div>
                <div className='font-bold text-blue-500'>{value}</div>
              </div>
            ))}
        </Col>
      </Row>
    </Card>
  )
}
export default BiddingOptionItemCard
