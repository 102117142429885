import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from 'react-router-dom'
import OrderStore from '../stores/OrderStore'
import { observer } from 'mobx-react-lite'
import AuthStore from '../stores/AuthStore'
import SellerStore from '../stores/SellerStore'
import ItemStore from '../stores/ItemStore'
import 'react-pro-sidebar/dist/css/styles.css'
import Header from '../components/Header'
import socket from '../utils/socket'
import backendApis from '../utils/backendApis'
import Sidebar from '../components/Sidebar'
import Log from '../utils/log'

import AdminRouter from '../components/atoms/adminRouter'
import Modal from 'react-modal'
import CancelPreShippingOrderModal from './CancelPreShippingOrderModal'
import CancelOrderModal from './CancelOrderModal'
import WithdrawCancelingPreShippingOrderModal from './WithdrawCancelingPreShippingOrderModal'

import newbieGuideStage from '../data/newbieGuideStage'
import BusinessRegistrationNumberModal from '../components/atoms/businessRegistrationNumberModal'
import AdQuest from '../components/atoms/adQuest'
import TimedealQuest from '../components/atoms/timedealQuest'
import SellerQuest from '../utils/sellerQuest'
import PriceQuest from '../components/atoms/priceQuest'
import BeautyQuest from '../components/atoms/BeautyQuest'
import ProgressDisplay from '../components/molecules/progressDisplay'
import RevokeRefundRequestModal from './RevokeRefundRequestModal'
import * as ChannelService from '@channel.io/channel-web-sdk-loader'
import BusinessRegistrationModal from './Modals/BusinessRegistrationModal'

const AdminScreen = observer(() => {
  const location = useLocation()
  useEffect(() => {
    Log.event('entering_screen', location.pathname)
  }, [location])
  const [isAgreeToTnCModalOpen, setIsAgreeToTnCModalOpen] = useState(false)
  const [autoCanceledPreshipping, setautoCanceledPreshipping] = useState(false)
  const [timerCheck, setTimerCheck] = useState(false)
  const [openGuideModal, setOpenGuideModal] = useState(true)
  const [openPriceQuestModal, setOpenPriceQuestModal] = useState(true)
  const [openBeautyQuestModal, setOpenBeautyQuestModal] = useState(true)
  const [openAdQuestModal, setOpenAdQuestModal] = useState(true)
  const [openTimedealQuestModal, setOpenTimedealQuestModal] = useState(true)
  const [showQuest, setShowQuest] = useState(true)
  const newbieGuideClearCondition =
    !SellerStore?.sellerInfo?.tutorialInfo?.filter(
      (e) => e.stageName === newbieGuideStage.reward && e.cleared === true,
    ).length > 0
  const adQuestClearCondition = SellerQuest.rewardChecker('adQuest')
  const timedealQuestClearCondition = SellerQuest.rewardChecker('timedealQuest')
  const priceQuestClearCondition = SellerQuest.rewardChecker('priceQuest')
  const beautyQuestClearCondition = SellerQuest.rewardChecker('beautyQuest')

  const questClearInfo = {
    adQuestClearCondition,
    timedealQuestClearCondition,
    priceQuestClearCondition,
    newbieGuideClearCondition,
    beautyQuestClearCondition,
  }

  const channelTalkBoot = () => {
    if (!SellerStore.loadSellerInfo()) return

    const phoneNumber =
      SellerStore.sellerInfo.phoneNumber?.startsWith('010') &&
      SellerStore.sellerInfo.phoneNumber?.length === 11
        ? SellerStore.sellerInfo.phoneNumber
        : ''
    ChannelService.loadScript()
    ChannelService.boot({
      pluginKey: '4d7d756c-2816-4b29-88c0-21b3b60b5cab',
      memberId: SellerStore.sellerInfo._id,
      profile: {
        name: SellerStore.sellerInfo.sellerDisplayName,
        sellerName: SellerStore.sellerInfo.sellerName,
        mobileNumber: phoneNumber,
        email: SellerStore.sellerInfo.email,
        contactNumber: SellerStore.sellerInfo.contactNumber,
        landlineNumber: SellerStore.sellerInfo.contactNumber,
      },
    })
  }

  const QuestHandler = ({ questClearInfo }) => {
    if (!showQuest) return <></>
    if (!questClearInfo?.priceQuestClearCondition)
      return (
        <PriceQuest
          questInfo={SellerStore?.sellerInfo?.questInfo?.priceQuest}
          openModal={openPriceQuestModal}
          setOpenModal={setOpenPriceQuestModal}
          setShowQuest={setShowQuest}
        />
      )
    if (!questClearInfo?.adQuestClearCondition)
      return (
        <AdQuest
          questInfo={SellerStore?.sellerInfo?.questInfo?.adQuest}
          openModal={openAdQuestModal}
          setOpenModal={setOpenAdQuestModal}
          setShowQuest={setShowQuest}
        />
      )
    if (!questClearInfo?.timedealQuestClearCondition)
      return (
        <TimedealQuest
          questInfo={SellerStore?.sellerInfo?.questInfo?.timedealQuest}
          openModal={openTimedealQuestModal}
          setOpenModal={setOpenTimedealQuestModal}
          setShowQuest={setShowQuest}
        />
      )
    if (
      !questClearInfo?.beautyQuestClearCondition &&
      (SellerStore.sellerInfo?.permanentMarks?.isBeautySeller ||
        SellerStore.sellerInfo?.representCategoryId === 1)
    )
      return (
        <BeautyQuest
          questInfo={SellerStore?.sellerInfo?.questInfo?.beautyQuest}
          openModal={openBeautyQuestModal}
          setOpenModal={setOpenBeautyQuestModal}
          setShowQuest={setShowQuest}
        />
      )

    return <></>
  }

  useEffect(() => {
    setTimeout(() => setTimerCheck(true), 120000)
  }, [])

  const autoCancelPreshippingOrders = async (orders) => {
    if (autoCanceledPreshipping) {
      return
    }
    setautoCanceledPreshipping(true)
    SellerStore.setIsLoading(true)
    for (let i = 0; i < orders.length; i += 1) {
      const order = orders[i]
      const result = await backendApis.approveOrderCancel(
        order._id,
        order.totalPrice,
        order.shippingFee,
        order.itemInfo.shippingInfo.returnFee,
      )

      if (result?.status === 200) {
        OrderStore.setDisplayedOrders(
          OrderStore.displayedOrders.map((orderTemp) => {
            if (orderTemp._id === order._id) {
              return { ...orderTemp, status: 'canceled-pre-shipping' }
            }
            return orderTemp
          }),
        )
        OrderStore.changeOrdersNumPerStatus('canceling-pre-shipping', -1)
        OrderStore.changeOrdersNumPerStatus(
          'canceling-pre-shipping-pre-excel',
          -1,
        )
        OrderStore.changeOrdersNumPerStatus('canceled-pre-shipping', 1)
      }
    }
    SellerStore.setIsLoading(false)
    setautoCanceledPreshipping(false)
  }

  const history = useHistory()

  useEffect(() => {
    if (
      OrderStore.displayedOrders.filter(
        (order) =>
          order.status === 'canceling-pre-shipping' && !order.excelDownloadedAt,
      ).length > 0
      //  &&
      // !autoCanceledPreshipping
    ) {
      // confirm with popup box
      autoCancelPreshippingOrders(
        OrderStore.displayedOrders.filter(
          (order) =>
            order.status === 'canceling-pre-shipping' &&
            !order.excelDownloadedAt,
        ),
      )
    }
  }, [
    OrderStore.displayedOrders.filter(
      (order) =>
        order.status === 'canceling-pre-shipping' && !order.excelDownloadedAt,
    ).length > 0,
  ])

  useEffect(() => {
    const loadOrders = async () => {
      AuthStore.loadToken()
      const result = await SellerStore.loadSellerInfo()
      // if (
      //   SellerStore.sellerInfo.itemIds?.length > 0 &&
      //   ItemStore.itemsInfo.length === 0
      // ) {
      //   ItemStore.loadItemsInfo(SellerStore.sellerInfo.itemIds)
      // }
      if (!result) {
        localStorage.removeItem('@alwayz@seller@token@')
        AuthStore.setToken('')
        history.push('/login')
        return
      }
      await Promise.all([
        SellerStore.setIsLoading(true),
        SellerStore.loadCalculatedSellerPoints(),
        SellerStore.setSellerMaxWeeklyTotalOrdersLastMonth(),
        SellerStore.setSellerRecentTodayDeparturePenalty(),
        SellerStore.loadCalculatedSellerPeriodScores(),
        SellerStore.setIsLoading(false),
        OrderStore.loadShippingCompanies(),
        OrderStore.loadOrders(),
        ItemStore.loadUnansweredQuestions(),
        SellerStore.loadBanksInfo(),
        SellerStore.loadDashboardInfo(),
        SellerStore.loadItemsRank(SellerStore.sellerInfo._id),
        ItemStore.loadItemOrderGuarantees(),
        SellerStore.loadOrderForGoodService(),
      ])
    }
    socket?.connect()
    loadOrders()
  }, [])

  useEffect(() => {
    if (AuthStore.needToConnectSocket && SellerStore.sellerInfo._id) {
      SellerStore.initializeSocket()
      AuthStore.setNeedToConnectSocket(false)
    }
    channelTalkBoot()
  }, [AuthStore.needToConnectSocket, SellerStore.sellerInfo._id])

  return (
    <>
      <div className='flex flex-row flex-1 h-full bg-dashboard-background '>
        {SellerStore.isUploading && (
          <ProgressDisplay
            msg={SellerStore?.uploadingStatus?.msg}
            total={SellerStore?.uploadingStatus?.total}
            done={SellerStore?.uploadingStatus?.done}
            success={SellerStore?.uploadingStatus?.success}
            fail={SellerStore?.uploadingStatus?.fail}
            isComplete={SellerStore?.uploadingStatus?.isComplete}
            isAdNudgeOn={SellerStore?.uploadingStatus?.isAdNudgeOn}
            onAdNudgeClick={() => {
              SellerStore.setIsUploading(false)
              if (
                SellerStore?.uploadingStatus?.type === 'oneClick' ||
                SellerStore?.uploadingStatus?.type === 'winnerOneClick'
              ) {
                // 광고대량등록 넛지
                SellerStore.setUploadingStatus({
                  type: '',
                  msg: '',
                  doneMsg: '',
                  total: 0,
                  done: 0,
                  fail: 0,
                })
                history.push(`/ad-apply?batchApplyOn=${true}`)
              }
            }}
            onCompleteCallback={() => {
              SellerStore.setIsUploading(false)

              SellerStore.setUploadingStatus({
                type: '',
                msg: '',
                doneMsg: '',
                total: 0,
                done: 0,
                fail: 0,
              })
            }}
          />
        )}
        <div
          style={{
            position: 'fixed',
            width: 240,
            height: '100%',
            zIndex: 15,
            display: isAgreeToTnCModalOpen ? 'none' : 'block',
          }}
        >
          <Sidebar />
        </div>
        <div className='z-10 flex flex-col grow pl-60'>
          <Header />
          <div className='fixed z-50 right-5 top-20'>
            <div className='flex flex-col items-end'>
              <QuestHandler questClearInfo={questClearInfo} />
            </div>
          </div>
          <div className='flex flex-col items-start px-10 pt-16'>
            <AdminRouter sellerInfo={SellerStore?.sellerInfo} />
          </div>
        </div>
        {SellerStore?.sellerInfo?.businessRegistrationNumber &&
        !SellerStore.sellerInfo?.isValidBusinessRegistrationNumber &&
        !AuthStore.isMasterAccount ? (
          <BusinessRegistrationNumberModal />
        ) : (
          (SellerStore?.sellerInfo?.dataForValidation
            ?.isValidBusinessRegistrationNumber !== 'approved' ||
            SellerStore?.sellerInfo?.dataForValidation
              ?.isValidBusinessRegistrationNumberImage !== 'approved' ||
            SellerStore?.sellerInfo?.dataForValidation?.isValidBankAccount !==
              'approved') && <BusinessRegistrationModal />
        )}
        <Modal
          isOpen={OrderStore?.openCancelPreShippingOrderModal}
          onRequestClose={() => {
            OrderStore?.setOpenCancelPreShippingOrderModal(false)
          }}
          style={{
            overlay: {
              zIndex: 20,
            },
            content: {
              left: '20%',
              right: '20%',
              top: '20%',
              bottom: '20%',
              padding: 0,
            },
          }}
        >
          <CancelPreShippingOrderModal
            setOpenModal={OrderStore?.openCancelPreShippingOrderModal}
          />
        </Modal>
        <Modal
          isOpen={OrderStore?.openCancelOrderModal}
          onRequestClose={() => {
            OrderStore?.setOpenCancelOrderModal(false)
          }}
          style={{
            overlay: {
              zIndex: 20,
            },
            content: {
              left: '20%',
              right: '20%',
              top: '20%',
              bottom: '20%',
              padding: 0,
            },
          }}
        >
          <CancelOrderModal />
        </Modal>
        <Modal
          isOpen={OrderStore?.openRevokeRefundModal}
          onRequestClose={() => {
            OrderStore?.setOpenRevokeRefundModal(false)
          }}
          style={{
            overlay: {
              zIndex: 20,
            },
            content: {
              left: '20%',
              right: '20%',
              top: '20%',
              bottom: '20%',
              padding: 0,
            },
          }}
        >
          <RevokeRefundRequestModal />
        </Modal>
        <Modal
          isOpen={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
          onRequestClose={() => {
            OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(false)
          }}
          style={{
            overlay: {
              zIndex: 20,
            },
            content: {
              left: '20%',
              right: '20%',
              top: '20%',
              bottom: '20%',
              padding: 0,
            },
          }}
        >
          <WithdrawCancelingPreShippingOrderModal
            id='modal'
            setOpenModal={
              OrderStore?.openWithdrawCancelingPreShippingOrderModal
            }
          />
        </Modal>
      </div>
    </>
  )
})

export default AdminScreen
