import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import Button from '../components/atoms/button'
import CongratIcon from '../images/congrat.png'
import MedalIcon from '../images/gold-medal.png'
import backendApis from '../utils/backendApis'
import sellerInfoParser from '../utils/sellerInfoParser'
import commaNumber from 'comma-number'

const FirstItemRegisterNudgeScreen = observer(() => {
  const [externalItemLength, setExternalItemLength] = useState(0)
  const sellerInfo = SellerStore?.sellerInfo
  const sellerName = sellerInfo?.sellerDisplayName
  const bizNumber = sellerInfoParser.bizNumParser(
    sellerInfo?.businessRegistrationNumber,
  )
  const condition = {
    bizNumber,
    oneClickRegisteredAt: null,
    oneClickFailedAt: null,
    isHidden: { $ne: true },
  }
  const checkTotalLength = async (condition) => {
    if (!condition?.bizNumber) return
    const result = await backendApis.getSellerExternalItemDataTotalLength(
      condition,
    )
    if (result?.status === 200) setExternalItemLength(result?.data)
  }

  useEffect(() => {
    checkTotalLength(condition)
  }, [sellerInfo])
  const history = useHistory()

  return (
    <div className='flex flex-col items-center justify-center w-full p-20 mt-8 bg-white rounded-lg'>
      {externalItemLength > 0 ? (
        <>
          {' '}
          <div className='flex flex-row items-center justify-center flex-1 mb-10 select-none'>
            <img alt='congraturations' src={MedalIcon} className='w-40 h-40' />
          </div>
          <div className='text-2xl font-bold'>
            <span className='font-bold text-blue-500'>{sellerName}</span>
            님은
          </div>
          <div className='text-2xl font-bold'>원클릭 등록 대상입니다</div>
          <br />
          <div className='text-lg'>
            <div className='flex flex-col items-center justify-center flex-1'>
              <p>
                외부 판매 상품이{' '}
                <span className='font-bold text-blue-500'>
                  {commaNumber(externalItemLength)}
                </span>
                건 확인되었습니다.
              </p>
              <p>
                지금 바로 리뷰까지{' '}
                <span className='font-bold text-blue-500'>클릭 한 번</span>에
                연동해보세요!
              </p>
              <Button
                className='px-12 py-2 mt-10'
                type='button'
                appearance='positive'
                size='lg'
                onClick={() => {
                  history.push('/items/one-click-register')
                }}
              >
                원클릭 등록하기
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='flex flex-row items-center justify-center flex-1 mb-20 select-none'>
            <img
              alt='congraturations'
              src={CongratIcon}
              className='w-40 h-40'
            />
          </div>
          <div className='text-2xl font-bold'>상품을 등록해 주세요.</div>
          <br />
          <div className='text-lg'>
            <div className='flex flex-col items-center justify-center flex-1'>
              <p>
                등록된 상품이 {SellerStore.sellerInfo?.itemId?.length || 0}건
                있습니다.
              </p>
              <p>지금 바로, 판매 상품을 등록해 주세요.</p>
              <Button
                className='px-12 py-2 mt-10'
                type='button'
                appearance='positive'
                size='lg'
                onClick={() => {
                  history.push('/items-type/registerations')
                }}
              >
                상품등록 하기
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
})

export default FirstItemRegisterNudgeScreen
