import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import commaNumber from 'comma-number'
import DatePicker from 'react-tailwindcss-datepicker'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'
import { Table } from '../../components/ADS/molecules'
import { Tooltip, Badge } from '../../components/ADS/atoms'
import Pagination from '../../components/molecules/pagination'
import Page from '../../components/atoms/page'
import Button from '../../components/atoms/button'
import { Bar } from 'react-chartjs-2'
import { FcBullish } from 'react-icons/fc'

const PAGE_ORDER_NUM = 50

const AdMonitoringScreen = observer(() => {
  const [adCreditUsageData, SetAdCreditUsageData] = useState([])
  const [pageIndex, setPageIndex] = useState(1)
  const [dateFiter, setDateFilter] = useState({ startDate: '', endDate: '' })
  const [adTypeFilter, setAdTypeFilter] = useState('default')
  const [unitFilter, setUnitFilter] = useState('daily')

  const fetchData = async () => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const result = await backendApis.getAdItemCreditUsage(
      adTypeFilter,
      false,
      dateFiter,
      unitFilter,
    )
    if (result?.status === 200) {
      SetAdCreditUsageData(result?.data)
    }
    SellerStore.setIsLoading(false)
  }

  const adDataColumns = useMemo(() => {
    const result = [
      { Header: '날짜', accessor: 'date' },
      { Header: '광고 유형', accessor: 'adType' },
      {
        Header: (
          <>
            클릭수
            <Tooltip text='광고가 클릭된 횟수입니다.' />
          </>
        ),
        accessor: 'visits',
      },
      {
        Header: (
          <>
            판매수
            <Tooltip
              text='상품이 광고로 판매된 횟수입니다.<br/>
                광고 기여도가 있는 판매건만 집계됩니다.'
            />
          </>
        ),
        accessor: 'purchase',
      },
      {
        Header: (
          <>
            전환율
            <Tooltip text='전환율은 (판매수/클릭수)로 계산되는 지표입니다.' />
          </>
        ),
        accessor: 'cvr',
      },
      {
        Header: (
          <>
            총 비용(VAT 포함)
            <Tooltip text='부가세(VAT)가 포함된 광고 소진액입니다.<br/> 주문 취소로 재지급된 크레딧은 포함하지 않은 값입니다.' />
          </>
        ),
        accessor: 'adCost',
      },
      {
        Header: (
          <>
            ROAS
            <Tooltip
              text='ROAS는 (광고 전환 매출/부가세 제외 광고비)로 계산되며<br/>
                  광고가 얼마나 효율적인지 나타내는 지표입니다.<br/>
                (ex. ROAS 600%이면 투입한 광고 비용의 <br/>6배만큼 매출이 발생한 것입니다.)'
            />
          </>
        ),
        accessor: 'roas',
      },
      {
        Header: (
          <>
            광고 전환 매출
            <Tooltip text='광고로 발생한 매출액입니다.' />
          </>
        ),
        accessor: 'revenueWithAd',
      },
    ]

    if (adCreditUsageData?.find((e) => e?.itemTitle)) {
      result.splice(2, 0, { Header: '광고 상품명', accessor: 'itemTitle' })
    }
    return result
  }, [adCreditUsageData])

  const chart = useMemo(() => {
    const result = {
      type: 'bar',
      data: {
        labels: adCreditUsageData?.map((item) => item?.date),
        datasets: [
          {
            label: '광고 판매수',
            data: adCreditUsageData?.map((item) => item?.purchase),
            backgroundColor: '#2695FF',
            borderWidth: 0,
          }, // 광고 판매수, 광고 전환매출 추가 예정
        ],
      },
      options: {
        indexAxis: 'x',
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
          },
        },
      },
    }
    if (adCreditUsageData?.find((e) => e?.itemTitle)) {
      result.data.labels = adCreditUsageData?.map((item) => item?.itemTitle)
    }
    return result
  }, [adCreditUsageData])

  const adData = useMemo(() => {
    return adCreditUsageData?.map((e, index) => ({
      index: index + 1,
      date: e?.date,
      adType: (
        <div key={e?._id}>
          {e?.adType === 'searchAd' ? (
            <Badge text='판매 보장 광고(CPS)' className='mb-2' color='purple' />
          ) : (
            <>
              {e?.adType === 'cpcAd' ? (
                <Badge
                  text='파워 퍼포먼스 광고(CPC)'
                  className='mb-2'
                  color='red'
                />
              ) : (
                <>
                  <Badge
                    text='판매 보장 광고(CPS)'
                    className='mb-2'
                    color='purple'
                  />
                  <br />
                  <Badge
                    text='파워 퍼포먼스 광고(CPC)'
                    className='mb-2'
                    color='red'
                  />
                </>
              )}
            </>
          )}
        </div>
      ),
      itemTitle: (
        <div
          key={e?._id}
          className='flex flex-col items-start whitespace-normal'
        >
          {e?.itemTitle}
        </div>
      ),
      keywords: (
        <div key={e?._id} className='flex flex-col flex-wrap w-min'>
          {e?.detailInfo?.keywords?.map((keywords) => (
            <div className='mb-2'>
              <Badge text={keywords?.keyword ?? '-'} color='gray' />
              <span>
                클릭수: {commaNumber(keywords?.click || 0)} / 판매수:{' '}
                {commaNumber(keywords?.purchase || 0)}
              </span>
            </div>
          ))}
        </div>
      ),
      visits: <div className='text-right'>{commaNumber(e?.adVisits) || 0}</div>,
      purchase: (
        <div className='text-right'>{commaNumber(e?.purchase) || 0}</div>
      ),
      cvr: (
        <div className='text-right'>
          {e?.adVisits > 0
            ? ((100 * (e?.purchase || 0)) / e?.adVisits)?.toFixed(1)
            : '- '}
          %
        </div>
      ),
      adCost: <div className='text-right'>{commaNumber(e?.adCost) || 0}원</div>,
      roas: (
        <div className='text-right'>
          {e?.adCost > 0
            ? ((100 * (e?.revenueWithAd || 0)) / e?.adCost)?.toFixed(1)
            : '- '}
          %
        </div>
      ),
      revenueWithAd: (
        <div className='text-right'>{commaNumber(e?.revenueWithAd) || 0}원</div>
      ),
    }))
  }, [adCreditUsageData])

  return (
    <div className='w-[85vw] pr-10'>
      <Page title='광고 성과 분석' Icon={FcBullish}>
        <div className='flex flex-wrap justify-between mb-4'>
          <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[405px] mr-4 flex-auto'>
            <div className='flex flex-row items-center mb-5 text-xl font-bold'>
              광고 성과 그래프
            </div>
            <Bar
              data={chart.data}
              width={50}
              height={15}
              options={chart.options}
            />
          </div>

          <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[405px] flex-auto'>
            <div className='flex flex-row items-center mb-5 text-xl font-bold'>
              광고 조회 필터
            </div>
            <div className='mb-4 '>
              <h3 className='mb-2 font-semibold text-gray-900'>조회 기간</h3>
              <DatePicker
                displayFormat='YYYY년 MM월 DD일'
                placeholder='조회 기간 선택'
                value={dateFiter}
                onChange={(d) => setDateFilter(d)}
                i18n='ko'
                inputClassName='rounded-md focus:ring-0 dark:bg-white dark:placeholder:text-gray-400 dark:border-gray-300 dark:text-gray-800 '
                showShortcuts
                configs={{
                  shortcuts: {
                    today: '오늘',
                    yesterday: '어제',
                    currentMonth: '이번 달',
                    pastMonth: '지난 달',
                    past: (period) => `지난 ${period}일`,
                  },
                }}
              />
            </div>

            <div className='mb-4'>
              <h3 className='mb-2 font-semibold text-gray-900 '>광고 유형</h3>
              <div className='items-center text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-lg w-min sm:flex'>
                <button
                  type='button'
                  className={`inline-block py-3 px-6 rounded-lg whitespace-nowrap ${
                    adTypeFilter === 'default'
                      ? 'text-gray-900 active shadow-md bg-white'
                      : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                  }`}
                  onClick={() => setAdTypeFilter('default')}
                >
                  전체
                </button>
                <button
                  type='button'
                  className={`inline-block py-3 px-4 rounded-lg whitespace-nowrap ${
                    adTypeFilter === 'searchAd'
                      ? 'text-gray-900 active shadow-md bg-white'
                      : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                  }`}
                  onClick={() => setAdTypeFilter('searchAd')}
                >
                  판매 보장 광고(CPS)
                </button>
                <button
                  type='button'
                  className={`inline-block py-3 px-4 rounded-lg whitespace-nowrap ${
                    adTypeFilter === 'cpcAd'
                      ? 'text-gray-900 active shadow-md bg-white'
                      : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                  }`}
                  onClick={() => setAdTypeFilter('cpcAd')}
                >
                  파워 퍼포먼스 광고(CPC)
                </button>
              </div>
            </div>

            <div className='mb-4'>
              <h3 className='mb-2 font-semibold text-gray-900 '>조회 기준</h3>
              <div className='items-center text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-lg w-min sm:flex'>
                <button
                  type='button'
                  className={`inline-block py-3 px-6 rounded-lg whitespace-nowrap text-gray-400 ${
                    unitFilter === 'daily'
                      ? 'text-gray-900 active shadow-md bg-white'
                      : 'hover:text-gray-900 hover:bg-gray-200'
                  }`}
                  onClick={() => setUnitFilter('daily')}
                >
                  일별
                </button>
                <button
                  type='button'
                  className={`inline-block py-3 px-6 rounded-lg whitespace-nowrap text-gray-400 ${
                    unitFilter === 'byAd'
                      ? 'text-gray-900 active shadow-md bg-white'
                      : 'hover:text-gray-900 hover:bg-gray-200'
                  }`}
                  onClick={() => setUnitFilter('byAd')}
                >
                  광고별
                </button>
              </div>
            </div>

            <div className='flex justify-start mt-6'>
              <Button
                className='flex items-center px-8 py-3 mr-4 text-center'
                type='button'
                appearance={
                  !dateFiter?.startDate || dateFiter?.startDate === ''
                    ? 'disabled'
                    : 'positive'
                }
                disabled={
                  !!(!dateFiter?.startDate || dateFiter?.startDate === '')
                }
                onClick={() => fetchData()}
              >
                조회하기
              </Button>
            </div>
          </div>
        </div>

        <Table columns={adDataColumns} data={adData} />
        <Pagination
          page={pageIndex}
          setPage={setPageIndex}
          total={50}
          limit={PAGE_ORDER_NUM}
        />
      </Page>
    </div>
  )
})
export default AdMonitoringScreen
