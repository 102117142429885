import ItemStore from '../../stores/ItemStore'
import SellerStore from '../../stores/SellerStore'
import AuthStore from '../../stores/AuthStore'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import Button from '../atoms/button'
import ItemRankReportModal from './itemRankReportModal'
import backendApis from '../../utils/backendApis'
import { Tooltip } from '../ADS/atoms'

const ItemButtonList = observer(
  ({
    itemInfo,
    isRankItem,
    itemRankData,
    itemsInfo = [],
    setItemsInfo = () => {},
    callbackFunction = () => {},
  }) => {
    const history = useHistory()

    const SelectDetailScreen = () => {
      if (itemInfo?.trendFashionInfo?.brandCode) {
        return (
          <Button
            type='button'
            appearance='teritiary'
            size='none'
            textColor='black'
            className='mr-2'
            onClick={() => {
              history.push(
                '/brandfashion-items/details',
                JSON.parse(JSON.stringify({ itemInfo })),
              )
            }}
          >
            수정
          </Button>
        )
      }
      if (
        itemInfo?.trendFashionInfo &&
        !itemInfo?.trendFashionInfo?.brandCode
      ) {
        return (
          <Button
            type='button'
            appearance='teritiary'
            size='none'
            textColor='black'
            className='mr-2'
            onClick={() => {
              history.push(
                '/trendfashion-items/details',
                JSON.parse(JSON.stringify({ itemInfo })),
              )
            }}
          >
            수정
          </Button>
        )
      }
      return (
        <Button
          type='button'
          appearance='teritiary'
          textColor='black'
          size='none'
          className='mr-2'
          onClick={() => {
            history.push(
              '/items/details',
              JSON.parse(JSON.stringify({ itemInfo })),
            )
          }}
        >
          수정
        </Button>
      )
    }

    return (
      <>
        <div className='flex flex-col items-start flex-1'>
          <div className='flex flex-row '>
            {/* {isRankItem && !itemInfo?.itemRankTestingSku ? (
              <ItemRankReportModal
                text='등급보고서'
                itemInfo={itemInfo}
                itemRankData={itemRankData}
                callbackFunction={callbackFunction}
              />
            ) : (
              <></>
            )} */}

            <SelectDetailScreen />

            {!itemInfo.soldOut && (
              <Button
                type='button'
                appearance='teritiary'
                size='none'
                textColor='black'
                className='mr-2'
                onClick={async () => {
                  const proceedSoldOut =
                    window.confirm('품절 처리를 진행하시겠습니까?')
                  if (!proceedSoldOut) {
                    return
                  }
                  SellerStore.setIsLoading(true)

                  const result = await backendApis.setItemSoldOut(itemInfo._id)
                  if (result?.status === 200) {
                    ItemStore.setItemOrderGuaranteesInfo(
                      ItemStore.itemOrderGuaranteesInfo.filter(
                        (item) => item._id !== itemInfo._id,
                      ),
                    )

                    await backendApis.deletePreviousDeals(
                      itemInfo._id,
                      'seller set item sold out',
                    )

                    callbackFunction()
                  }
                  SellerStore.setIsLoading(false)
                }}
              >
                품절
              </Button>
            )}
            <Button
              type='button'
              appearance='teritiary'
              size='none'
              textColor='black'
              className='mr-2'
              onClick={async () => {
                const proceedDelete = window.confirm(
                  '상품 삭제를 진행하시겠습니까? 삭제된 상품은 다시 복구할 수 없습니다.',
                )
                if (!proceedDelete) {
                  return
                }
                SellerStore.setIsLoading(true)

                const result = await backendApis.removeItemFromSeller(
                  itemInfo._id,
                )
                if (result?.status === 200) {
                  ItemStore.setItemOrderGuaranteesInfo(
                    ItemStore.itemOrderGuaranteesInfo.filter(
                      (item) => item._id !== itemInfo._id,
                    ),
                  )

                  await backendApis.deletePreviousDeals(
                    itemInfo._id,
                    'seller removed item',
                  )

                  callbackFunction()
                }
                SellerStore.setIsLoading(false)
              }}
            >
              삭제
            </Button>
            {SellerStore.sellerInfo?._id === '6238198180322c376f1ebece' && (
              <Button
                type='button'
                appearance='teritiary'
                size='none'
                className='mr-2'
                textColor='black'
                onClick={async () => {
                  const manualItemCode =
                    window.prompt('상품 고유 코드를 기입해주세요')
                  if (!manualItemCode) {
                    return
                  }
                  SellerStore.setIsLoading(true)

                  const result = await backendApis.setItemManualCode(
                    itemInfo._id,
                    manualItemCode,
                  )
                  if (result?.status === 2000) {
                    callbackFunction()
                  }
                  SellerStore.setIsLoading(false)
                }}
              >
                코드기입
                {!!itemInfo?.manualItemCode && (
                  <div style={{ fontSize: 12 }}>
                    (기입된 코드: {itemInfo?.manualItemCode})
                  </div>
                )}
              </Button>
            )}
            {itemInfo.soldOut && (
              <Button
                type='button'
                appearance='teritiary'
                size='none'
                textColor='black'
                className='mr-2'
                onClick={async () => {
                  SellerStore.setIsLoading(true)
                  const result = await backendApis.updateItemRankTest(
                    itemInfo,
                    parseInt(itemInfo.teamPurchasePrice, 10),
                  )
                  if (result?.status === 2000) {
                    const data = await backendApis.setItemSalesResume(
                      itemInfo._id,
                    )
                    if (data?.status === 200) {
                      alert(
                        '정상적으로 품절 해제 되었습니다. 아이템 등급 테스트가 진행됩니다.',
                      )
                      callbackFunction()
                      SellerStore.setIsLoading(false)
                      return
                    }
                    callbackFunction()
                  } else {
                    alert(
                      '품절 해제 중 문제가 발생했습니다. 판매자 지원센터로 문의해주세요.',
                    )
                  }
                  SellerStore.setIsLoading(false)
                }}
              >
                판매재개
              </Button>
            )}
          </div>
          <div>
            {AuthStore.isMasterAccount && (
              <Button
                appearance='teritiary'
                size='none'
                className='mr-2'
                textColor='black'
                type='button'
                onClick={async () => {
                  const duplicateItem = window.confirm(
                    `${itemInfo.itemTitle} 상품을 복제하시겠습니까?`,
                  )
                  if (duplicateItem) {
                    SellerStore.setIsLoading(true)
                    const result = await backendApis.duplicateItem(itemInfo._id)
                    if (result?.status === 200) {
                      await ItemStore.loadItemsInfo([
                        ...SellerStore.sellerInfo.itemIds,
                        result.data,
                      ])
                      callbackFunction()
                    }
                    SellerStore.setIsLoading(false)
                  }
                }}
              >
                상품복제
              </Button>
            )}
            {/* <Button
              type='button'
              appearance='teritiary'
              size='none'
              className='mr-2'
              onClick={async () => {
                const matchingID = window.prompt('상품 매칭ID를 기입해주세요.')
                if (!matchingID) {
                  return
                }
                if (matchingID?.length < 10) {
                  alert('매칭ID는 10자리 이상으로 기입해주세요.')
                  return
                }
                SellerStore.setIsLoading(true)

                const result = await backendApis.setItemMatchindID(
                  itemInfo._id,
                  matchingID,
                )
                if (result?.status === 2000) {
                  if (result?.data?.isUpdate === true) {
                    setItemsInfo(
                      itemsInfo.map((itemInfoTemp) => {
                        if (
                          itemInfo._id.toString() ===
                          itemInfoTemp._id.toString()
                        ) {
                          return { ...itemInfoTemp, matchingID }
                        }
                        return itemInfoTemp
                      }),
                    )
                  }
                  alert(result?.data?.message)
                  callbackFunction()
                }
                SellerStore.setIsLoading(false)
              }}
            >
              <div className='flex flex-col items-start justify-center flex-1'>
                <div>
                  상품매칭
                  <Tooltip
                    text={`상품 매칭 관련 제안을 받으신 경우 <br/>
                  매칭ID를 입력해주시면 됩니다.`}
                  />
                </div>
                <div>
                  {!!itemInfo?.matchingID &&
                    itemInfo?.matchingID?.length > 0 && (
                      <div style={{ fontSize: 12 }}>
                        (매칭ID: {itemInfo?.matchingID})
                      </div>
                    )}
                </div>
              </div>
            </Button> */}
          </div>
        </div>
      </>
    )
  },
)
export default ItemButtonList
