import { useEffect, useState } from 'react'
import SellerStore from '../../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import ImageUploading from 'react-images-uploading'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import Button from '../../components/atoms/button'

export default observer(function BusinessRegistrationModal({
  onClose = () => {},
}) {
  const [showModal, setShowModal] = useState(true)
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState('')
  const [businessRegistrationNumberImage, setBusinessRegistrationNumberImage] =
    useState('')
  const [bankInfo, setBankInfo] = useState([])
  const [sellerBankCode, setSellerBankCode] = useState('')
  const [sellerBankAccountNumber, setSellerBankAccountNumber] = useState('')
  const [sellerBankAccountHolderName, setSellerBankAccountHolderName] =
    useState('')
  const [isReLoad, setIsReLoad] = useState(false)

  const handleClose = () => {
    setShowModal(false)
    onClose()
  }

  const handelReject = async (key) => {
    const keyMap = {
      isValidBusinessRegistrationNumber: setBusinessRegistrationNumber,
      isValidBusinessRegistrationNumberImage:
        setBusinessRegistrationNumberImage,
      isValidBankAccount: setSellerBankAccountNumber,
    }
    if (!Object.keys(keyMap).includes(key)) {
      return
    }
    try {
      const result = await backendApis.rejectRegistrationInfoByKey(key)
      if (result?.status === 200 && result?.data) {
        alert('취소 신청이 완료되었습니다.')
        SellerStore.loadSellerInfo()
      }
    } catch (e) {
      alert(
        '취소 신청에 실패했습니다. 추후에 다시 시도하거나 판매자 지원센터로 문의주세요',
      )
    }
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false)
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onClose])

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()
    setBusinessRegistrationNumber(
      sellerBusinessInfo?.data?.businessRegistrationNumber,
    )
    setBusinessRegistrationNumberImage(
      sellerBusinessInfo?.data?.businessRegistrationNumberImage,
    )
    setSellerBankCode(sellerBusinessInfo?.data?.bankAccountInfo?.bankCode)
    setSellerBankAccountNumber(
      sellerBusinessInfo?.data?.bankAccountInfo?.bankAccountNumber,
    )
    setSellerBankAccountHolderName(
      sellerBusinessInfo?.data?.bankAccountInfo?.depositorName,
    )
  }, [
    businessRegistrationNumber,
    businessRegistrationNumberImage,
    sellerBankAccountHolderName,
    sellerBankCode,
    sellerBankAccountNumber,
  ])

  useEffect(() => {
    const loadBankInfo = async () => {
      const bankInfo = await backendApis.loadBanksInfo()
      setBankInfo(bankInfo?.data)
    }
    loadBankInfo()
  }, [])

  useEffect(async () => {
    const loadSellerInfo = async () => {
      await SellerStore.loadSellerInfo()
    }
    loadSellerInfo()
  }, [isReLoad])

  const Header = ({ onClose }) => {
    return (
      <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
        <h3 className='text-xl font-semibold text-black'>
          <span className='text-yellow-500'>⚠️</span> 사업자 정보 등록
        </h3>
        <div className='flex flex-row items-center justify-end flex-1'>
          <TooltipOrigin
            arrow='true'
            theme='dark'
            title='❎ 키보드 esc로도 종료하실 수 있어요.'
            animation='fade'
            position='top'
          >
            <button
              type='button'
              onClick={onClose}
              className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
            >
              esc
            </button>
          </TooltipOrigin>
        </div>
      </div>
    )
  }
  const Explain = () => {
    return (
      <div className='mb-5 text-left'>
        올웨이즈는 사업주님의 안전하고 편리한 판매활동을 위해 사업자정보 적합성
        검사를 진행하고 있습니다.
        <br />
        <br />
        보안 강화를 위해 기존 사업주님들께서도 사업자정보 재 업데이트
        부탁드립니다.
        <br />
        <span className='font-bold text-blue-500'>
          ✅ 10월 1일부터 적합성검사를 통과하지 않은 사업주님들은 판매활동 및
          정산이 중단됩니다.
          <br />✅ 미승인으로 인한 판매 및 정산 상의 불이익이 발생하지 않도록
          기한 내 업데이트 권장 드립니다.
        </span>
        <br />
        <br />
        📌 제출 후 최대 3일 이내 검토가 완료되며 부적합 정보일 경우 판매활동 및
        정산이 제한됩니다.
      </div>
    )
  }
  const getStatusText = (info, validity, type) => {
    if (!info) {
      return <span className='ml-2 text-gray-500'>⚪ 미등록</span>
    }
    if (validity === 'approved') {
      return <span className='ml-2 text-green-500'>🟢 승인</span>
    }
    if (validity === 'pending') {
      return (
        <>
          <span className='ml-2 text-orange-500  mr-2'>🟠 검토중</span>
          <Button onClick={() => handelReject(type)}>취소신청</Button>
        </>
      )
    }
    if (validity === 'rejected') {
      return (
        <>
          <span className='ml-2 text-red-500'>🔴 반려/재등록필요</span>
        </>
      )
    }
    return null
  }

  const BusinessNumberSection = ({ sellerInfo }) => {
    const [bisNumTemp, setBisNumTemp] = useState('')
    const isValidBusinessRegistrationNumber =
      sellerInfo?.dataForValidation?.isValidBusinessRegistrationNumber
    const isBusinessNumberValid = (number) => {
      const regex = /^\d{3}-\d{2}-\d{5}$/
      return regex.test(number)
    }

    const handleRegisterClick = async () => {
      if (!isBusinessNumberValid(bisNumTemp)) {
        alert('사업자등록번호는 000-00-00000 형식이어야 합니다.')
        return
      }
      const isConfirmed = window.confirm('사업자등록번호를 등록하시겠습니까?')
      if (isConfirmed) {
        try {
          const updateResult =
            await backendApis.registerBusinessRegistrationNumber(bisNumTemp)
          if (updateResult?.status === 200) {
            setBusinessRegistrationNumber(bisNumTemp)
            alert('등록이 완료되었습니다.')
            setIsReLoad((prev) => !prev)
          } else {
            alert('오류가 발생했습니다. 다시 시도해주세요.')
            setIsReLoad((prev) => !prev)
          }
        } catch (error) {
          console.error('Error during registration:', error)
          alert('오류가 발생했습니다. 다시 시도해주세요.')
          setIsReLoad((prev) => !prev)
        }
      }
    }

    const isDisabled =
      !!businessRegistrationNumber &&
      isValidBusinessRegistrationNumber !== 'rejected'

    const showRegisterButton =
      bisNumTemp?.length === 12 &&
      (!businessRegistrationNumber ||
        isValidBusinessRegistrationNumber === 'rejected')

    return (
      <div className='p-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <span className='font-bold mr-3'>사업자등록번호</span>
            <input
              type='text'
              placeholder='사업자번호 (- 포함)'
              className='px-3 border border-gray-300 rounded-md'
              value={
                isValidBusinessRegistrationNumber === 'rejected'
                  ? bisNumTemp
                  : businessRegistrationNumber || bisNumTemp
              }
              onChange={(e) => setBisNumTemp(e.target.value)}
              maxLength='12'
              disabled={isDisabled}
            />
          </div>
          {showRegisterButton && (
            <button
              type='submit'
              className='ml-3 px-3 py-2 bg-blue-500 text-white rounded-md'
              onClick={handleRegisterClick}
            >
              등록하기
            </button>
          )}
          <div className='flex items-center'>
            {getStatusText(
              businessRegistrationNumber,
              isValidBusinessRegistrationNumber,
              'isValidBusinessRegistrationNumber',
            )}
          </div>
        </div>
      </div>
    )
  }

  const BusinessRegistrationImageSection = ({ sellerInfo }) => {
    const isValidBusinessRegistrationNumberImage =
      sellerInfo?.dataForValidation?.isValidBusinessRegistrationNumberImage

    const handleImageUpload = async (imageList) => {
      try {
        const response =
          await backendApis.registerBusinessRegistrationNumberImage(
            imageList,
            sellerInfo._id,
          )
        if (response?.status === 200) {
          setBusinessRegistrationNumberImage(imageList)
          alert('이미지 업로드가 완료되었습니다.')
          setIsReLoad((prev) => !prev)
        } else {
          alert('이미지 업로드에 실패했습니다. 다시 시도해주세요.')
          setIsReLoad((prev) => !prev)
        }
      } catch (error) {
        console.error('Image upload error:', error)
        alert('이미지 업로드 중 오류가 발생했습니다.')
        setIsReLoad((prev) => !prev)
      }
    }

    return (
      <div className='p-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <span className='font-bold mr-3'>사업자등록증</span>
            {businessRegistrationNumberImage &&
            isValidBusinessRegistrationNumberImage !== 'rejected' ? (
              <div className='flex items-center'>
                <span className='px-3 py-2 border border-gray-300 rounded-md ml-3'>
                  사업자등록증 제출 완료
                </span>
              </div>
            ) : (
              <ImageUploading
                multiple
                value={[]}
                onChange={(imageList) => {
                  const isConfirmed =
                    window.confirm('사업자등록증을 업로드하시겠습니까?')
                  if (isConfirmed) {
                    handleImageUpload(imageList)
                  }
                }}
                maxFileSize={5e6}
                acceptType={['jpg', 'png', 'jpeg']}
                resolutionType='more'
                resolutionWidth={800}
                resolutionHeight={800}
                onError={(e) => {
                  if (e.maxFileSize) {
                    alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                  } else if (e.acceptType) {
                    alert(
                      '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                    )
                  } else if (e.resolution) {
                    alert(
                      '선명한 이미지를 올려주세요.\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                    )
                  }
                }}
                allowNonImageType='false'
                maxNumber={10}
                dataURLKey='sourceUri'
              >
                {({ onImageUpload }) => (
                  <span
                    role='button'
                    tabIndex={0}
                    onClick={onImageUpload}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        onImageUpload()
                      }
                    }}
                    className='px-3 py-2 border border-gray-300 rounded-md cursor-pointer ml-3 text-gray-500'
                  >
                    사업자등록증 업로드
                  </span>
                )}
              </ImageUploading>
            )}
          </div>
          <div className='flex items-center'>
            {getStatusText(
              businessRegistrationNumberImage,
              isValidBusinessRegistrationNumberImage,
              'isValidBusinessRegistrationNumberImage',
            )}
          </div>
        </div>
      </div>
    )
  }

  const BankAccountSection = ({ bankInfo, sellerInfo }) => {
    const [selectedAccount, setSelectedAccount] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [accountHolderName, setAccountHolderName] = useState('')
    const handleAccountChange = (event) => {
      setSelectedAccount(event.target.value)
    }
    const isValidBankAccount = sellerInfo?.dataForValidation?.isValidBankAccount

    const handleRegister = async () => {
      const isConfirmed = window.confirm('계좌정보를 등록하시겠습니까?')
      if (isConfirmed) {
        try {
          const bankId = bankInfo.find(
            (bank) => bank.bankCode === selectedAccount,
          )?._id
          const bankAccountInfo = {
            bankId,
            bankCode: selectedAccount,
            bankAccountNumber: accountNumber,
            depositorName: accountHolderName,
          }
          const updateResult = await backendApis.registerBankAccountInfoV2(
            bankAccountInfo,
          )
          if (updateResult?.status === 200) {
            setSellerBankCode(selectedAccount)
            setSellerBankAccountNumber(accountNumber)
            setSellerBankAccountHolderName(accountHolderName)
            alert('사업자계좌 등록이 완료되었습니다.')
            setIsReLoad((prev) => !prev)
          } else {
            alert('사업자계좌 등록에 실패했습니다. 다시 시도해주세요.')
            setIsReLoad((prev) => !prev)
          }
        } catch (error) {
          console.error('Error during registration:', error)
          alert('오류가 발생했습니다. 다시 시도해주세요.')
          setIsReLoad((prev) => !prev)
        }
      }
    }

    const isDisabled = !!(
      sellerBankCode &&
      sellerBankAccountNumber &&
      sellerBankAccountHolderName
    )

    return (
      <div className='p-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <span className='font-bold mr-3'>사업자계좌</span>
            {isDisabled && isValidBankAccount !== 'rejected' ? (
              <span className='px-3 py-2 border border-gray-300 rounded-md ml-6'>
                {
                  bankInfo.find((bank) => bank.bankCode === sellerBankCode)
                    ?.bankName
                }
              </span>
            ) : (
              <select
                className='px-3 border border-gray-300 rounded-md ml-6'
                value={selectedAccount}
                onChange={handleAccountChange}
              >
                <option value='' disabled>
                  은행 선택
                </option>
                {bankInfo.map((bank) => (
                  <option key={bank._id} value={bank.bankCode}>
                    {bank.bankName}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className='mt-4'>
          <div className='flex items-center'>
            <span className='font-bold mr-3'>계좌번호</span>
            {isDisabled && isValidBankAccount !== 'rejected' ? (
              <span className='px-3 py-2 border border-gray-300 rounded-md ml-9'>
                {sellerBankAccountNumber}
              </span>
            ) : (
              <input
                type='text'
                className='px-3 py-2 border border-gray-300 rounded-md ml-9'
                placeholder='계좌번호 ( - 제외)'
                value={accountNumber}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, '')
                  setAccountNumber(numericValue)
                }}
              />
            )}
          </div>
          <div className='flex items-center justify-between mt-4'>
            <div className='flex items-center'>
              <span className='font-bold mr-3'>예금주명</span>
              {isDisabled && isValidBankAccount !== 'rejected' ? (
                <span className='px-3 py-2 border border-gray-300 rounded-md ml-9'>
                  {sellerBankAccountHolderName}
                </span>
              ) : (
                <input
                  type='text'
                  className='px-3 py-2 border border-gray-300 rounded-md ml-9'
                  placeholder='예금주명을 입력하세요'
                  value={accountHolderName}
                  onChange={(e) => setAccountHolderName(e.target.value)}
                />
              )}
            </div>
            {(!isDisabled || isValidBankAccount === 'rejected') &&
              accountNumber.length >= 10 &&
              accountHolderName.length > 0 && (
                <button
                  type='button'
                  className='ml-4 px-4 py-2 bg-blue-500 text-white rounded-md'
                  onClick={handleRegister}
                >
                  등록
                </button>
              )}
            <div className='flex items-center'>
              {getStatusText(
                isDisabled,
                isValidBankAccount,
                'isValidBankAccount',
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none'>
            <div className='relative w-full max-w-5xl mx-auto my-6 shadow-lg'>
              <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                <Header
                  onClose={() => {
                    handleClose()
                  }}
                />
                <div className='relative flex flex-col items-center justify-center px-10 py-10'>
                  <Explain />
                  <div className='w-2/3'>
                    <BusinessNumberSection
                      sellerInfo={SellerStore?.sellerInfo}
                    />
                  </div>
                  <hr className='my-1 w-2/3 border-t border-gray-300 border-dotted' />
                  <div className='w-2/3'>
                    <BusinessRegistrationImageSection
                      sellerInfo={SellerStore?.sellerInfo}
                    />
                  </div>
                  <hr className='my-1 w-2/3 border-t border-gray-300 border-dotted' />
                  <div className='w-2/3'>
                    <BankAccountSection
                      bankInfo={bankInfo}
                      sellerInfo={SellerStore?.sellerInfo}
                    />
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                <button
                  className='px-6 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                  type='button'
                  onClick={() => setShowModal(false)}
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
