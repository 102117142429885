import { Col, Card, Row, Badge, Avatar, Tag, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react'
import backendApis from '../../../utils/backendApis'
import { uuid } from 'short-uuid'
import commaNumber from 'comma-number'
import SellerStore from '../../../stores/SellerStore'
import User from '../../../images/user.png'
import moment from 'moment'
import { EllipsisOutlined } from '@ant-design/icons'
import { unit } from 'mathjs'

// 입찰 유형에 따른 한글 표기
const typeToKor = {
  auto: '자동 입찰',
  fixed: '고정가 입찰',
}

// 동물 이미지 URL 리스트 (예시)
const animalImages = [
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Luna',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sam',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Sheba',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Maggie',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Salem',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Rascal',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Bear',
  'https://api.dicebear.com/9.x/thumbs/svg?seed=Muffin',
]

// 해시 함수를 통해 고유 인덱스 생성
const getHash = (str) => {
  let hash = 5381
  for (let i = 0; i < str.length; i += 1) {
    hash = (hash << 5) + hash + str.charCodeAt(i) // hash * 33 + c
  }
  return Math.abs(hash)
}

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

// 입찰 분석 컴포넌트
const Analytics = ({ liveBiddingList, myBid = {} }) => {
  if (!liveBiddingList.length) return null
  const winningBid = liveBiddingList[0]?.optionTeamPurchasePrice || 0
  const myBidPrice = myBid?.optionTeamPurchasePrice || 0
  const type = myBid?.biddingType
  const priceDifference =
    parseInt(winningBid - myBidPrice, 10) < 10
      ? parseInt(winningBid - myBidPrice, 10)
      : 10
  const isWinning = myBid?.rank === 1
  const priceDifferenceText = !isWinning ? (
    <div>
      <span className='text-blue-500 font-bold'>
        {`${commaNumber(Math.abs(priceDifference))}원`}
      </span>
      만 낮추면 특가전 노출이 가능해요!
    </div>
  ) : (
    <div>
      <div>
        축하합니다!{' '}
        <span className='text-blue-500 font-bold'>
          <Tooltip
            title={
              <div>
                1위 상품은{' '}
                <span className='text-blue-500 font-bold'>특가전</span>에
                노출됩니다.
              </div>
            }
          >
            현재 1위
          </Tooltip>
        </span>
        입니다.
      </div>
    </div>
  )

  return (
    <div>
      {!isEmptyObject(myBid) > 0 ? (
        <Col>
          <Row>
            <Col>
              <strong>1위 입찰가: </strong>
              {commaNumber(winningBid)}원
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>
              <strong>내 {type === 'auto' ? '자동' : '고정'} 입찰가: </strong>
              {commaNumber(myBidPrice)}원
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col>{priceDifferenceText}</Col>
          </Row>
        </Col>
      ) : (
        <Row>
          <div>
            지금 {commaNumber(winningBid)}원 보다 싼 가격으로 입찰하시면{' '}
            <span className='text-blue-500 font-bold'>노출량 100%</span>를
            획득하실 수 있어요.
          </div>
        </Row>
      )}
    </div>
  )
}

const apiGroups = {
  unitOption: {
    getLiveBiddingList: backendApis.getUnitOptionBiddingList,
  },
  generalOption: {
    getLiveBiddingList: backendApis.getGeneralOptionLiveBiddingList,
  },
}

const LiveBiddingList = ({
  biddingOptionId,
  itemId,
  optionId,
  type = 'unitOption',
}) => {
  const [liveBiddingInfo, setLiveBiddingInfo] = useState([])
  const liveBiddingList = liveBiddingInfo?.biddingList || []
  const sellerImage = SellerStore?.sellerInfo?.sellerImageUri || User
  const totalCount = liveBiddingInfo?.totalCount
  const lastUpdatedAt = liveBiddingInfo?.lastUpdatedAt
    ? moment(new Date(liveBiddingInfo?.lastUpdatedAt)).format(
        'YYYY-MM-DD HH:mm',
      )
    : null

  useEffect(() => {
    const fetchLiveBiddingInfo = async () => {
      const result = await apiGroups[type]?.getLiveBiddingList(
        biddingOptionId,
        itemId,
        optionId,
      )
      if (result?.status === 200) {
        setLiveBiddingInfo(result?.data)
      }
    }
    fetchLiveBiddingInfo()
  }, [biddingOptionId, itemId, optionId])

  const getAnimalImage = (id) => {
    const hash = getHash(id)
    const index = hash % animalImages.length
    return animalImages[index]
  }

  const myBid = liveBiddingList.find((bid) => bid.isMine)
  return (
    <div className='flex flex-1 flex-col justify-between h-full pb-16'>
      {liveBiddingList?.length > 0 ? (
        <div className='flex flex-1 w-full flex-col justify-between'>
          <div>
            <div className='text-gray-400'>{lastUpdatedAt} 기준</div>
            <div className='mb-5'>
              전체 입찰 상품 수{' '}
              <span className='font-bold text-blue-500'>{totalCount}개</span>
            </div>
            <div>
              {liveBiddingList?.map((bid, index) => {
                const isWinning = bid?.rank === 1
                return (
                  <div>
                    {bid?.rank !== index + 1 && (
                      <div className='mb-2 flex flex-row flex-1 justify-center items-center'>
                        <EllipsisOutlined size={24} />
                      </div>
                    )}
                    <Card
                      type='inner'
                      key={uuid()}
                      className={`mb-2 w-full rounded-xl ${
                        bid.isMine ? 'bg-slate-700 text-white' : 'bg-slate-100'
                      }`}
                    >
                      <Row gutter={18}>
                        {bid?.isMine && (
                          <div className='bg-slate-700 text-white rounded-lg absolute -top-2 -left-2 text-xs px-2 py-1'>
                            내 상품
                          </div>
                        )}
                        <Col>
                          <Avatar
                            src={
                              bid?.isMine
                                ? sellerImage
                                : getAnimalImage(
                                    bid?.biddingId?.toString() || bid?.rank,
                                  )
                            }
                            size={48}
                            className='shadow-sm border-2 border-white'
                          />
                        </Col>
                        <Col>
                          <Row className='mb-1'>
                            <Row className='mr-2'>
                              {isWinning ? (
                                <Badge
                                  status='success'
                                  style={
                                    bid?.isMine ? { color: '#ffffff' } : {}
                                  }
                                  text='현재 1위'
                                />
                              ) : (
                                <Badge
                                  status='error'
                                  style={
                                    bid?.isMine ? { color: '#ffffff' } : {}
                                  }
                                  text={`${bid?.rank}위`}
                                />
                              )}
                            </Row>
                            <Tag
                              color={
                                bid?.biddingType === 'auto'
                                  ? '#3b82f6'
                                  : '#71717a'
                              }
                            >
                              {typeToKor?.[bid?.biddingType]}
                            </Tag>
                          </Row>
                          <Row>
                            <strong>입찰가:</strong>{' '}
                            {commaNumber(bid?.optionTeamPurchasePrice)}원{' '}
                            {/* <span>
                              {!isWinning &&
                                `(${
                                  bid?.optionTeamPurchasePrice -
                                  liveBiddingList[0]?.optionTeamPurchasePrice
                                }원 비싸요)`}
                            </span> */}
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <Card>
              <Col>
                <Row className='font-bold mb-2 rounded-lg text-gray-600'>
                  입찰 분석
                </Row>
                <Row>
                  <Analytics liveBiddingList={liveBiddingList} myBid={myBid} />
                </Row>
              </Col>
            </Card>
          </div>
        </div>
      ) : (
        <div>
          <div>입찰 정보가 없습니다.</div>
          <div>
            지금 입찰하고{' '}
            <span className='font-bold text-blue-500'>폭발적인 노출</span>을
            경험해보세요!
          </div>
          <div className='mt-5 text-slate-500'>
            *상품 연동 중에는 실시간 순위가 표시되지 않습니다.
          </div>
        </div>
      )}
    </div>
  )
}

export default LiveBiddingList
