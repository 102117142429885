import React, { useEffect, useState } from 'react'
import { Modal, Checkbox } from 'antd'

const AttributeModal = ({
  title = '다음 항목을 확인해주세요',
  content = <div>다음 항목을 체크해주세요.</div>,
  isVisible,
  attributes,
  onConfirm,
  onCancel,
}) => {
  const [checkedAttributes, setCheckedAttributes] = useState([])

  const handleCheckboxChange = (attribute) => {
    setCheckedAttributes((prevCheckedAttributes) => {
      if (prevCheckedAttributes.includes(attribute)) {
        return prevCheckedAttributes.filter((item) => item !== attribute)
      }
      return [...prevCheckedAttributes, attribute]
    })
  }

  const handleOk = () => {
    onConfirm(checkedAttributes)
  }

  useEffect(() => {
    setCheckedAttributes([])
  }, [])

  return (
    <>
      {attributes && (
        <Modal
          title={title}
          open={isVisible}
          onOk={handleOk}
          onCancel={() => {
            setCheckedAttributes([])
            onCancel()
          }}
          okButtonProps={{
            disabled:
              checkedAttributes.length !== Object.keys(attributes).length,
            type: 'primary',
            className: 'bg-blue-500',
          }}
          okText='확인'
          cancelText='취소'
        >
          {content}
          <div>
            {Object.keys(attributes).map((key) => (
              <div key={key}>
                <Checkbox
                  onChange={() => handleCheckboxChange(key)}
                  checked={checkedAttributes.includes(key)}
                >
                  {`${key}: ${attributes[key]}`}
                </Checkbox>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  )
}

export default AttributeModal
