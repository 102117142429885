/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../../stores/OrderStore'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'
import moment from 'moment'
import socket from '../../utils/socket'
import FastShippingOrderMent from './FastShippingOrderMent'
import Format from '../../utils/format'

// const shippingCompaniesInfo = [
//   { shippingCompanyName: '우체국', shippingCompanyCode: '01' },
//   { shippingCompanyName: '한진 택배', shippingCompanyCode: '05' },
// ]

const PreShippingOrder = observer(
  ({ order, index, shippingCompanyId, orders, setOrders }) => {
    // const [shippingInfo, setShippingInfo] = useState(null)
    const [extraOrderNumber, setExtraOrderNumber] = useState('')
    const [shippingNumber, setShippingNumber] = useState('')

    useEffect(() => {
      if (
        SellerStore.sellerInfo._id &&
        OrderStore.shippingCompaniesInfo.length > 0
      ) {
        if (SellerStore.sellerInfo.defaultShippingCompanyId) {
          const correspondingShippingCompanyInfo =
            OrderStore.shippingCompaniesInfo.find(
              (shippingCompanyInfo) =>
                shippingCompanyInfo._id ===
                SellerStore.sellerInfo.defaultShippingCompanyId,
            )
          OrderStore.setDisplayedOrders(
            OrderStore.displayedOrders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return {
                  ...orderTemp,
                  shippingInfo: {
                    ...orderTemp.shippingInfo,
                    ...correspondingShippingCompanyInfo,
                  },
                }
              }
              return orderTemp
            }),
          )
          if (setOrders) {
            OrderStore.setUserOrders(
              orders.map((orderTemp) => {
                if (orderTemp._id === order._id) {
                  return {
                    ...orderTemp,
                    shippingInfo: {
                      ...orderTemp.shippingInfo,
                      ...correspondingShippingCompanyInfo,
                    },
                  }
                }
                return orderTemp
              }),
            )
          }
          // setShippingInfo({
          //   ...shippingInfo,
          //   ...correspondingShippingCompanyInfo,
          // })
        }
      }
    }, [SellerStore.sellerInfo._id, OrderStore.shippingCompaniesInfo.length])
    useEffect(() => {
      const correspondingShippingCompanyInfo =
        OrderStore.shippingCompaniesInfo.find(
          (shippingCompanyInfo) =>
            shippingCompanyInfo._id === shippingCompanyId,
        )
      if (correspondingShippingCompanyInfo) {
        OrderStore.setDisplayedOrders(
          OrderStore.displayedOrders.map((orderTemp) => {
            if (orderTemp._id === order._id) {
              return {
                ...orderTemp,
                shippingInfo: {
                  ...orderTemp.shippingInfo,
                  ...correspondingShippingCompanyInfo,
                },
              }
            }
            return orderTemp
          }),
        )
        if (setOrders) {
          OrderStore.setUserOrders(
            orders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return {
                  ...orderTemp,
                  shippingInfo: {
                    ...orderTemp.shippingInfo,
                    ...correspondingShippingCompanyInfo,
                  },
                }
              }
              return orderTemp
            }),
          )
        }
      } else {
        OrderStore.setDisplayedOrders(
          OrderStore.displayedOrders.map((orderTemp) => {
            if (orderTemp._id === order._id) {
              return {
                ...orderTemp,
                shippingInfo: null,
              }
            }
            return orderTemp
          }),
        )
        if (setOrders) {
          OrderStore.setUserOrders(
            orders.map((orderTemp) => {
              if (orderTemp._id === order._id) {
                return {
                  ...orderTemp,
                  shippingInfo: null,
                }
              }
              return orderTemp
            }),
          )
        }
      }
    }, [shippingCompanyId])
    const optionsText = order.itemInfo.optionsInfo.optionNames.map(
      (value, optionIndex) => {
        const optionDetail =
          order.itemInfo.optionsInfo.totalOptions[optionIndex][
            order.selectedOption[optionIndex]
          ].name
        return `${optionIndex}. ${value}: ${optionDetail}`
      },
    )
    return (
      // <li key={order._id}>{order._id}</li>
      <tr key={order._id} style={{ fontSize: 14 }}>
        <td>{order._id}</td>
        <td>
          <div>{order.itemInfo.itemTitle},</div>
          {optionsText.map((option) => (
            <div key={option}>{option},</div>
          ))}
          <div>{order.quantity}개</div>
        </td>
        <td>
          <div>{Format.Recipient(order.addressInfo?.recipient)}</div>
          <div>{order.addressInfo?.recipientPhoneNumber}</div>
        </td>
        <td>
          <div>{order.addressInfo?.detailAddress}</div>
          <div>{order.addressInfo?.frontDoorPassword}</div>
        </td>
        <td>{moment(order.preShippingTimeStamp).format('YYYY.MM.DD HH:mm')}</td>
        <td>
          {SellerStore.sellerInfo._id && (
            <select
              style={{ width: 80 }}
              id={`shipping_company_${order._id}`}
              name={`shipping_company_${order._id}`}
              onChange={(e) => {
                // console.log(`wowwow ${JSON.stringify(e.target.value)}`)
                const correspondingShippingCompanyInfo =
                  OrderStore.shippingCompaniesInfo.find(
                    (shippingCompanyInfo) =>
                      shippingCompanyInfo.shippingCompanyCode ===
                      e.target.value,
                  )
                if (correspondingShippingCompanyInfo) {
                  OrderStore.setDisplayedOrders(
                    OrderStore.displayedOrders.map((orderTemp) => {
                      if (orderTemp._id === order._id) {
                        return {
                          ...orderTemp,
                          shippingInfo: {
                            ...orderTemp.shippingInfo,
                            ...correspondingShippingCompanyInfo,
                          },
                        }
                      }
                      return orderTemp
                    }),
                  )
                  if (setOrders) {
                    OrderStore.setUserOrders(
                      orders.map((orderTemp) => {
                        if (orderTemp._id === order._id) {
                          return {
                            ...orderTemp,
                            shippingInfo: {
                              ...orderTemp.shippingInfo,
                              ...correspondingShippingCompanyInfo,
                            },
                          }
                        }
                        return orderTemp
                      }),
                    )
                  }
                } else {
                  // setShippingInfo(null)
                  OrderStore.setDisplayedOrders(
                    OrderStore.displayedOrders.map((orderTemp) => {
                      if (orderTemp._id === order._id) {
                        return {
                          ...orderTemp,
                          shippingInfo: null,
                        }
                      }
                      return orderTemp
                    }),
                  )
                  if (setOrders) {
                    OrderStore.setUserOrders(
                      orders.map((orderTemp) => {
                        if (orderTemp._id === order._id) {
                          return {
                            ...orderTemp,
                            shippingInfo: null,
                          }
                        }
                        return orderTemp
                      }),
                    )
                  }
                }
              }}
              // defaultValue={
              //   SellerStore.sellerInfo.defaultShippingCompanyId
              //     ? OrderStore.shippingCompaniesInfo.find(
              //         (shippingCompanyInfo) =>
              //           shippingCompanyInfo._id ===
              //           SellerStore.sellerInfo.defaultShippingCompanyId,
              //       ).shippingCompanyCode
              //     : ''
              // }
              value={order.shippingInfo?.shippingCompanyCode || ''}
            >
              <option value=''>===</option>
              {/* <option value=''>===</option>
          <option value='2021'>2021</option>
          <option value='2020'>2020</option>
          <option value='2019'>2019</option> */}
              {OrderStore.shippingCompaniesInfo.map((shippingCompanyInfo) => (
                <option
                  key={shippingCompanyInfo.shippingCompanyCode}
                  value={shippingCompanyInfo.shippingCompanyCode}
                  //   onChange={(e) => {
                  //     console.log(`wowwow${JSON.stringify(e.nativeEvent)}`)
                  //   }}
                >
                  {shippingCompanyInfo.shippingCompanyName}
                </option>
              ))}
            </select>
          )}
        </td>
        <td>
          <input
            type='text'
            value={shippingNumber}
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setShippingNumber(e.target.value)
            }}
          />
        </td>
        <td>
          <button
            type='button'
            onClick={async () => {
              if (order.shippingInfo && shippingNumber) {
                if (SellerStore.isLoading) {
                  return
                }
                SellerStore.setIsLoading(true)
                const shippingInfos = [
                  {
                    orderId: order._id,
                    shippingInfo: {
                      ...order.shippingInfo,
                      shippingNumber: shippingNumber.replace(/-/g, '').trim(),
                    },
                  },
                ]
                const status = 'pre-shipping'
                const result = await backendApis.postShippingInfosInBulk(
                  shippingInfos,
                  status,
                )
                SellerStore.setIsLoading(false)
                if (result.status === 2000) {
                  if (order.excelDownloadedAt) {
                    OrderStore.changeOrdersNumPerStatus(
                      'pre-shipping-post-excel',
                      -1,
                    )
                  } else {
                    OrderStore.changeOrdersNumPerStatus(
                      'pre-shipping-pre-excel',
                      -1,
                    )
                  }
                  OrderStore.setDisplayedOrders(
                    OrderStore.displayedOrders.map((orderTemp) => {
                      if (orderTemp._id === order._id) {
                        return {
                          ...orderTemp,
                          status: 'shipping',
                          shippingInfo: {
                            ...orderTemp.shippingInfo,
                            shippingNumber: shippingNumber
                              .replace(/-/g, '')
                              .trim(),
                          },
                        }
                      }
                      return orderTemp
                    }),
                  )
                  if (setOrders) {
                    OrderStore.setUserOrders(
                      orders.map((orderTemp) => {
                        if (orderTemp._id === order._id) {
                          return {
                            ...orderTemp,
                            status: 'shipping',
                            shippingInfo: {
                              ...orderTemp.shippingInfo,
                              shippingNumber: shippingNumber
                                .replace(/-/g, '')
                                .trim(),
                            },
                          }
                        }
                        return orderTemp
                      }),
                    )
                  }
                  socket?.emit(
                    'uploadShippingInfo',
                    SellerStore.sellerInfo._id,
                    order._id,
                    {
                      ...order.shippingInfo,
                      shippingNumber: shippingNumber.replace(/-/g, '').trim(),
                    },
                  )
                  OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
                  OrderStore.changeOrdersNumPerStatus('shipping', 1)
                  alert('주문 업로드가 완료되었습니다.')
                } else {
                  alert(
                    '업로드되지 않은 주문이 있습니다. 주문 내용을 다시 확인하세요.',
                  )
                }
              }
              //   console.log(`order id: ${order._id} shipping info: ${shippingInfo}`)
            }}
          >
            운송장
            <br />
            업로드
          </button>
        </td>
        <td>
          <button
            type='button'
            onClick={async () => {
              if (SellerStore.isLoading) {
                return
              }
              SellerStore.setIsLoading(true)
              const result = await backendApis.postOrderPostalShippingInfo(
                order._id,
                {
                  ...order.shippingInfo,
                  // shippingNumber: shippingNumber.replace(/-/g, ''),
                  isPostalShipping: true,
                },
              )

              SellerStore.setIsLoading(false)
              if (result.status === 200) {
                const excelDownloadedAt = order.excelDownloadedAt
                if (order.excelDownloadedAt) {
                  OrderStore.changeOrdersNumPerStatus(
                    'pre-shipping-post-excel',
                    -1,
                  )
                } else {
                  OrderStore.changeOrdersNumPerStatus(
                    'pre-shipping-pre-excel',
                    -1,
                  )
                }
                OrderStore.setDisplayedOrders(
                  OrderStore.displayedOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'shipping',
                        shippingInfo: {
                          ...orderTemp.shippingInfo,
                          isPostalShipping: true,
                          // shippingNumber: shippingNumber.replace(/-/g, ''),
                        },
                      }
                    }
                    return orderTemp
                  }),
                )
                if (setOrders) {
                  OrderStore.setUserOrders(
                    orders.map((orderTemp) => {
                      if (orderTemp._id === order._id) {
                        return {
                          ...orderTemp,
                          status: 'shipping',
                          shippingInfo: {
                            ...orderTemp.shippingInfo,
                            isPostalShipping: true,
                            // shippingNumber: shippingNumber.replace(/-/g, ''),
                          },
                        }
                      }
                      return orderTemp
                    }),
                  )
                }
                socket?.emit(
                  'uploadShippingInfo',
                  SellerStore.sellerInfo._id,
                  order._id,
                  {
                    ...order.shippingInfo,
                    isPostalShipping: true,
                    // shippingNumber: shippingNumber.replace(/-/g, ''),
                  },
                  !!excelDownloadedAt,
                )
                OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
                OrderStore.changeOrdersNumPerStatus('shipping', 1)
              }
            }}
          >
            우편
            <br />
            발송
          </button>
        </td>

        <td>
          <button
            type='button'
            onClick={async () => {
              if (SellerStore.isLoading) {
                return
              }
              SellerStore.setIsLoading(true)
              const result = await backendApis.postOrderDirectShippingInfo(
                order._id,
                {
                  ...order.shippingInfo,
                  // shippingNumber: shippingNumber.replace(/-/g, ''),
                  isDirectShipping: true,
                },
              )

              SellerStore.setIsLoading(false)
              if (result.status === 200) {
                const excelDownloadedAt = order.excelDownloadedAt
                if (order.excelDownloadedAt) {
                  OrderStore.changeOrdersNumPerStatus(
                    'pre-shipping-post-excel',
                    -1,
                  )
                } else {
                  OrderStore.changeOrdersNumPerStatus(
                    'pre-shipping-pre-excel',
                    -1,
                  )
                }
                OrderStore.setDisplayedOrders(
                  OrderStore.displayedOrders.map((orderTemp) => {
                    if (orderTemp._id === order._id) {
                      return {
                        ...orderTemp,
                        status: 'shipping',
                        shippingInfo: {
                          ...orderTemp.shippingInfo,
                          isDirectShipping: true,
                          // shippingNumber: shippingNumber.replace(/-/g, ''),
                        },
                      }
                    }
                    return orderTemp
                  }),
                )
                if (setOrders) {
                  OrderStore.setUserOrders(
                    orders.map((orderTemp) => {
                      if (orderTemp._id === order._id) {
                        return {
                          ...orderTemp,
                          status: 'shipping',
                          shippingInfo: {
                            ...orderTemp.shippingInfo,
                            isDirectShipping: true,
                            // shippingNumber: shippingNumber.replace(/-/g, ''),
                          },
                        }
                      }
                      return orderTemp
                    }),
                  )
                }
                socket?.emit(
                  'uploadShippingInfo',
                  SellerStore.sellerInfo._id,
                  order._id,
                  {
                    ...order.shippingInfo,
                    isDirectShipping: true,
                    // shippingNumber: shippingNumber.replace(/-/g, ''),
                  },
                  !!excelDownloadedAt,
                )
                OrderStore.changeOrdersNumPerStatus('pre-shipping', -1)
                OrderStore.changeOrdersNumPerStatus('shipping', 1)
              }
            }}
          >
            직접
            <br />
            배송
          </button>
        </td>

        <td>
          <button
            type='button'
            onClick={async () => {
              // 현일 - 수정로직 시작
              if (
                confirm(
                  "실제 취소사유와 다르게 취소가 되는 경우,\n'고의적 부당행위'로 불이익이 발생할 수 있으므로 주의해주세요.\n주문 취소를 진행하시겠습니까?",
                )
              ) {
                OrderStore?.setOpenCancelPreShippingOrderModal(true)
                OrderStore?.setCancelOrderInfo({
                  ...order,
                })
              }
              // console.log(
              //   'cancelOrderInfo is ...',
              //   JSON.stringify(OrderStore?.cancelOrderInfo),
              // )
            }}
          >
            취소
            <br />
            승인
          </button>
        </td>
        {/* 쿠폰비용 */}
        <td>{order.totalPrice}</td>
        <td>
          {(order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1)}
        </td>
        <td>{order.selectedCouponInfo?.finalDiscountAmount || 0}</td>
        <td>
          {order.totalPrice +
            (order.selectedCouponInfo?.finalDiscountAmount || 0) +
            (order.selectedOption?.platformSupportPrice || 0) *
              (order.quantity || 1)}
        </td>
        <td>{order.shippingFee}</td>
        <td
          className={
            !!order?.itemInfo?.characteristicItem?.fastShippingItem &&
            moment(order.preShippingTimeStamp) > moment('2022-08-30')
              ? 'text-blue-500 font-extrabold bg-emerald-100'
              : ''
          }
        >
          <FastShippingOrderMent
            isFastShippingItem={
              !!order?.itemInfo?.characteristicItem?.fastShippingItem
            }
            preShippingTimeStamp={order.preShippingTimeStamp}
          />
        </td>
      </tr>
    )
  },
)

export default PreShippingOrder
