import React from 'react'
import { observer } from 'mobx-react-lite'
import CancelingPreShippingOrders from '../components/CancelingPreShippingOrders'
import OrderStore from '../stores/OrderStore'
import CancelingPreShippingOrdersAfterExcel from '../components/CancelingPreShippingOrdersAfterExcel'
import WithdrawCancelingPreShippingOrderModal from './WithdrawCancelingPreShippingOrderModal'
import Modal from 'react-modal'
import Title from '../components/ADS/atoms/title'
import Tabs from '../components/ADS/molecules/tabs'
import Button from '../components/atoms/button'

const CancelingPreShippingsScreen = observer(() => {
  const tabContArr = [
    // {
    //   title: `상품준비중 취소 요청(엑셀 추출 전) (${(
    //     OrderStore.ordersNumPerStatus['canceling-pre-shipping-pre-excel'] || 0
    //   ).toLocaleString()})`,
    //   content: (
    //     <CancelingPreShippingOrders
    //       orders={OrderStore.displayedOrders.filter(
    //         (order) =>
    //           order.status === 'canceling-pre-shipping' &&
    //           !order.excelDownloadedAt,
    //       )}
    //     />
    //   ),
    // },
    {
      title: `출고 중지 요청(엑셀 추출 후) (${(
        OrderStore.ordersNumPerStatus['canceling-pre-shipping-post-excel'] || 0
      ).toLocaleString()})`,
      content: (
        <CancelingPreShippingOrdersAfterExcel
          orders={OrderStore.displayedOrders.filter(
            (order) =>
              order.status === 'canceling-pre-shipping' &&
              order.excelDownloadedAt,
          )}
        />
      ),
    },
  ]
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: '0',
          textAlign: 'center',
          justifyContent: 'center',
          alignmentBaseline: 'baseline',
        }}
      >
        <Title text='출고 중지 요청' />
        <a
          href='https://drive.google.com/u/0/uc?id=1hcXlC-c90Le8oqIRhBWkCqeA6ePvgZ_E&export=download'
          target='_blank'
          rel='noreferrer'
          style={{ marginLeft: 10, padding: '40px 0px 20px' }}
        >
          <Button size='sm'>취소처리 매뉴얼</Button>
        </a>
      </div>
      <Tabs tabContArr={tabContArr} />
      <Modal
        isOpen={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
        onRequestClose={() => {
          OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 0,
            // zIndex: 20,
            // marginRight: '-50%',
          },
        }}
      >
        <WithdrawCancelingPreShippingOrderModal
          id='modal'
          setOpenModal={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
          // phoneNumberForSearch={UserStore?.searchedUsers?.phoneNumber}
          // searchedQuestions={searchedQuestions}
          // users={ItemStore.itemQuestions.find(
          //   (orderTemp) => orderTemp?.orderId === orderId,
          // )}
          // analytics={analytics}
          // logEvent={logEvent}
        />
      </Modal>
    </>
  )
})

export default CancelingPreShippingsScreen
