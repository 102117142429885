import { Button, Col, Image, Row, Spin, Tag, Tooltip, message } from 'antd'
import commaNumber from 'comma-number'
import React from 'react'
import {
  CheckCircleTwoTone,
  CopyOutlined,
  ExclamationCircleTwoTone,
  FireTwoTone,
  StarTwoTone,
  LikeTwoTone,
} from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import OscillatingChart from './oscillatingChart'

const toValidPrice = (price) => {
  return price === Infinity || Number.isNaN(price) ? null : price
}

const dataMapping = {
  unitOption: (data) => ({
    id: data?._id,
    image: data?.selectedImage,
    title: data?.parsedItemTitle,
    attributes: data?.checkedAttributes,
    topOptions: data?.topOptions,
    externalPriceInfo: data?.externalPriceInfo,
    applicationInfo: data?.applicationInfo,
    gmvProjection: data?.gmvProjection,
    inspectionStatus: data?.inspectionStatus,
    biddingApplied: data?.biddingApplied,
    totalMappingCount: data?.totalMappingCount || 0,
  }),
  generalOption: (data) => ({
    id: data?._id,
    image: data?.mainImageUris?.[0],
    title: data?.generalOptionTitle,
    attributes: null,
    topOptions: data.topOptions,
    // externalPriceInfo: data.externalPriceInfo,
    applicationInfo: data.applicationInfo,
    monthlyOrderCount: data.monthlyOrderCount,
    biddingApplied: data?.biddingApplied,
    totalMappingCount: data?.totalMappingCount,
  }),
}

const BiddingOptionCard = ({
  data,
  type = 'unitOption', // or 'generalOption'
  onMappingButtonClick = () => {},
  onBiddingButtonClick = () => {},
}) => {
  const {
    id,
    image,
    title,
    attributes,
    topOptions = [],
    externalPriceInfo = [],
    applicationInfo = null,
    gmvProjection,
    monthlyOrderCount,
    biddingApplied,
    totalMappingCount,
  } = dataMapping[type](data)

  const internalLowestPrice =
    topOptions?.length > 0
      ? toValidPrice(
          Math.min(
            ...topOptions?.map((option) =>
              parseInt(option.optionTeamPurchasePrice, 10),
            ),
          ),
        )
      : null

  const externalLowestPrice =
    externalPriceInfo?.length > 0
      ? toValidPrice(
          Math.min(...externalPriceInfo?.map((e) => parseInt(e?.price, 10))),
        )
      : null

  const liveBiddingPrice =
    internalLowestPrice != null && externalLowestPrice != null
      ? toValidPrice(Math.min(internalLowestPrice, externalLowestPrice))
      : toValidPrice(internalLowestPrice ?? externalLowestPrice ?? null)

  const gmv = gmvProjection
    ? parseInt(gmvProjection, 10)
    : parseInt((monthlyOrderCount / 30) * liveBiddingPrice, 10)

  const applicationStatus = applicationInfo?.inspectionStatus || 'none'

  const handleCopy = () => {
    if (id) {
      copy(id.toString())
      message.success('ID가 클립보드에 복사되었습니다.')
    }
  }

  const ApplicationButton = () => {
    if (applicationStatus === 'none' || applicationStatus === 'rejected') {
      return (
        <Button
          type='primary'
          className='bg-blue-500'
          onClick={() => onMappingButtonClick(data)}
        >
          상품 연동하기
        </Button>
      )
    }
    if (applicationStatus === 'applied') {
      return (
        <div>
          <div>
            <Spin className='mr-2' size='small' />
            신청 처리 중입니다.
          </div>
          <div className='text-xs text-slate-500 mt-2'>
            48시간 이내에 처리됩니다.
          </div>
        </div>
      )
    }
    if (applicationStatus === 'approved') {
      return (
        <div className='text-center'>
          <div className='mb-2 text-xs text-slate-500'>
            <CheckCircleTwoTone twoToneColor='#52c41a' /> 연동 완료된
            상품입니다.
          </div>
        </div>
      )
    }
    return (
      <Button
        type='primary'
        className='bg-blue-500'
        onClick={() => onMappingButtonClick(data)}
      >
        상품 연동하기
      </Button>
    )
  }

  const BiddingButton = ({ applied = false }) => {
    if (!applicationStatus || applicationStatus === 'none') {
      return (
        <div className='text-xs text-slate-500'>
          상품 연동 후 입찰 가능합니다.
        </div>
      )
    }
    if (applicationStatus === 'applied' || applicationStatus === 'approved') {
      return (
        <div className='flex flex-1 flex-col w-full justify-center items-center'>
          {applied === false ? (
            <div className=' text-xs text-slate-500 mb-2'>
              <ExclamationCircleTwoTone twoToneColor='#ef4444' /> 입찰 신청이
              필요합니다.
            </div>
          ) : (
            <div className='mb-2 text-xs text-slate-500'>
              <CheckCircleTwoTone twoToneColor='#52c41a' /> 입찰 완료된
              상품입니다.
            </div>
          )}
          <Button onClick={() => onBiddingButtonClick(data, applicationInfo)}>
            입찰 관리하기
          </Button>
        </div>
      )
    }
    if (applicationStatus === 'suspended' || applicationStatus === 'rejected') {
      return (
        <div className='text-center'>
          <div className='text-xs text-slate-500'>
            지난 신청건이 반려되었습니다.
          </div>
          <div className='text-xs text-slate-500'>
            동일한 상품으로 연동신청해주세요.
          </div>
          {applicationStatus === 'suspended' && (
            <div className='mt-2 text-slate-500 text-xs'>
              사유:{' '}
              <span className='font-bold text-orange-500'>
                <Tooltip title={applicationInfo?.suspendedReason}>
                  {applicationInfo?.suspendedReason?.slice(0, 22)}
                  {applicationInfo?.suspendedReason?.length > 22 && '...'}
                </Tooltip>
              </span>
            </div>
          )}
          {applicationStatus === 'rejected' && (
            <div className='mt-2 text-slate-500 text-xs'>
              사유:{' '}
              <span className='font-bold text-orange-500'>
                <Tooltip title='다른 상품입니다.'>다른 상품입니다.</Tooltip>
              </span>
            </div>
          )}
        </div>
      )
    }
    return <>{applicationStatus}</>
  }

  return (
    <div className='flex-1 flex w-full p-4 items-center rounded-md bg-white'>
      <div
        style={{
          width: '100%',
          flex: 1,
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <Col span={3} style={{ position: 'relative' }}>
          <Image
            src={image}
            width={120}
            height={120}
            className='rounded-lg'
            preview={false}
            style={{ display: 'block', borderRadius: '8px' }} // 이미지 스타일 설정
          />
          <Button
            size='small'
            className='w-14'
            icon={<CopyOutlined />}
            onClick={handleCopy}
            style={{
              position: 'absolute',
              top: '82%', // 이미지의 중앙에 배치
              left: '66%', // 이미지의 중앙에 배치
              transform: 'translate(-50%, -50%)', // 중앙 정렬을 위해 변환
              zIndex: 1, // 이미지 위에 버튼이 나타나도록 설정
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // 반투명 배경으로 가독성 향상
              border: 'none', // 버튼 테두리 제거
              borderRadius: '8px',
            }}
          />
        </Col>
        <Col span={5} className='pl-5'>
          <div className='h-full justify-center flex-1 flex flex-col'>
            <div className='font-bold flex flex-row'>
              <div>{title}</div>
            </div>
            <div>
              {liveBiddingPrice ? (
                <div>
                  실시간 최저가{' '}
                  <span className='text-blue-500 font-bold'>{`${commaNumber(
                    liveBiddingPrice,
                  )}원`}</span>
                </div>
              ) : (
                <div>입찰 정보 없음</div>
              )}
            </div>
            <div className='mt-5'>
              {totalMappingCount >= 8 && (
                <Tooltip title='경쟁이 활발하게 일어나는 상품입니다.'>
                  <Tag color='red'>
                    <FireTwoTone twoToneColor='#eb2f96' />
                    {` HOT`}
                  </Tag>
                </Tooltip>
              )}
              {totalMappingCount > 3 && totalMappingCount < 8 && (
                <Tooltip title='경쟁이 일어나는 상품입니다.'>
                  <Tag color='blue'>경쟁 일반</Tag>
                </Tooltip>
              )}
              {totalMappingCount <= 3 && (
                <Tooltip title='경쟁 강도가 낮은 상품입니다.'>
                  <Tag color='green'>
                    <LikeTwoTone twoToneColor='#52c41a' />
                    {` 경쟁 약함`}
                  </Tag>
                </Tooltip>
              )}
            </div>
          </div>
        </Col>
        <Col span={3} className='pl-5 overflow-auto'>
          <div className='h-full justify-center flex-1 flex flex-col'>
            <Row
              align='middle'
              justify='start'
              className='text-xs text-slate-500'
            >
              {attributes &&
                Object.entries(attributes || {}).map(([key, value]) => (
                  <div key={key}>{`${key}: ${value}`}</div>
                ))}
            </Row>
          </div>
        </Col>
        <Col span={5} className='pl-5'>
          {gmv ? (
            <OscillatingChart baseValue={parseInt(gmv, 10)} />
          ) : (
            <Col span={24} className='h-full w-full flex flex-1 flex-col'>
              <Row
                justify='center'
                align='middle'
                className='text-xs text-gray-500 h-full'
              >
                추정 매출이 없습니다.
              </Row>
            </Col>
          )}
        </Col>
        <Col span={4}>
          <Row justify='center' align='middle' className='flex flex-1 h-full'>
            <ApplicationButton />
          </Row>
        </Col>
        <Col span={4}>
          <Row justify='center' align='middle' className='flex flex-1 h-full'>
            <BiddingButton applied={biddingApplied} />
          </Row>
        </Col>
      </div>
    </div>
  )
}

export default BiddingOptionCard
