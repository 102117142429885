import moment from 'moment'
import React, { useState, useEffect, useMemo } from 'react'
import {
  FcIdea as BulbIcon,
  FcOk as CheckIcon,
  FcHighPriority as WarnIcon,
} from 'react-icons/fc'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useForm } from 'react-hook-form'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import backendApis from '../../utils/backendApis'
import Table from '../../components/ADS/molecules/table'
import Pagination from '../../components/molecules/pagination'
import RankIconSetter from '../../components/molecules/rankIconSetter'
import resize from '../../utils/resize'
import Button from '../../components/atoms/button'
import commaNumber from 'comma-number'
import ApplyTabHandler from '../../components/molecules/applyTabHandler'
import EggAdStatusBar from '../../components/molecules/eggAdStatusBar'
import TrendQuizAdStatusBar from '../../components/molecules/trendQuizAdStatusBar'

const AlfarmTrendQuizScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  //   const onSubmit = (data) => setAdInfo(data)
  const [startDate, setStartDate] = useState(
    new Date(moment(new Date()).startOf('day').add(3, 'days')),
  )
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemId, setCheckedItemId] = useState('')
  const [checkedItemTitle, setCheckedItemTitle] = useState('')
  const [checkedItemImage, setCheckedItemImage] = useState('')
  const [adStatusTab, setAdStatusTab] = useState('candidate')
  const [messageOne, setMessageOne] = useState('')
  const [messageTwo, setMessageTwo] = useState('')

  const [sellerSettlementAmount, setSellerSettlementAmount] = useState(0)
  const [messageThree, setMessageThree] = useState('')
  const cleanUp = () => {
    setStartDate(new Date(moment(new Date()).startOf('day').add(3, 'days')))
    setPage(1)
    setLimit(5)
    setItemsInfo([])
    setCheckedItemId('')
    setCheckedItemTitle('')
    setMessageOne('')
    setMessageTwo('')
    setMessageThree('')
  }

  const columns = useMemo(
    () => [
      { Header: '선택', accessor: 'status' },
      { Header: '상품정보', accessor: 'itemInfo' },
    ],
    [],
  )
  const data = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item, idx) => {
          return {
            status: (
              <div className='flex items-center justify-center flex-1 select-none'>
                <input
                  type='checkbox'
                  checked={checkedItemId === item?._id}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setCheckedItemId(item?._id)
                      setCheckedItemTitle(item?.itemTitle)
                      setCheckedItemImage(item?.mainImageUris[0])
                    } else {
                      setCheckedItemId('')
                      setCheckedItemTitle('')
                      setCheckedItemImage('')
                    }
                  }}
                />
              </div>
            ),
            itemInfo: (
              <div key={item?._id}>
                <div className='flex flex-row justify-between flex-1 w-full'>
                  <div className='flex flex-row flex-1 w-max'>
                    <img
                      alt='mainImage'
                      src={
                        item?.mainImageUris
                          ? resize(item?.mainImageUris[0])
                          : ''
                      }
                      className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                    />
                    <div className='mr-3'>
                      <div className='mb-1 text-lg font-bold whitespace-normal'>
                        {item?.itemTitle}
                      </div>

                      <div className='flex flex-row items-center mb-1'>
                        {item?.rankInfo && (
                          <div className='flex items-center px-2 py-0.5 bg-gray-100 rounded-md mr-2'>
                            <RankIconSetter
                              itemRank={item?.rankInfo?.itemRank}
                              className='w-4 h-4'
                            />
                            등급
                          </div>
                        )}
                        <div>
                          {item?.teamPurchasePrice?.toLocaleString() || 0}원
                        </div>
                      </div>

                      <div className='text-gray-400 '>{item?._id}</div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          }
        }),
    [itemsInfo, checkedItemId],
  )

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    itemCondition.soldOut = false
    itemCondition.outOfStock = { $ne: true }
    itemCondition['categoryInfo.largeCategoryId'] = { $in: [3] }
    itemCondition.$or = [
      { itemFilters: { $eq: null } },
      {
        itemFilters: {
          $not: {
            $elemMatch: {
              $or: [{ key: 'ad', value: adSettingByTab?.[5].type }],
            },
          },
        },
      },
    ]

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }

    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [adStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(() => {
    const fetchData = async () => {
      await SellerStore.loadSettlements()
    }
    fetchData()

    const filteredData = SellerStore?.settlements?.filter((item) => {
      const threeWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 21) // Corrected the threeWeeksAgo calculation
      return !item?.errorType && new Date(item?.createdAt) > threeWeeksAgo // Ensured both are Date objects for comparison
    })
    const settlementSum = filteredData?.reduce((acc, cur) => {
      return acc + cur?.totalSettlementAmount
    }, 0)

    setSellerSettlementAmount(settlementSum)
  }, [])

  return (
    <div className='w-[80vw]'>
      <div className='w-2/3 '>
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center mb-5 text-xl font-bold'>
            <div className='mr-3 text-3xl text-amber-500'>All</div> 모든 등급의
            상품 신청 가능합니다.
          </div>
          <div>하루 온종일 노출! 120만명에게 보여지는 광고</div>

          <div>5월 31일 7,000만원</div>
          <div>6월 11일 1억원</div>
          <div>6월 14일 7,000만원</div>

          <div className='flex justify-end mt-6'>
            <button
              type='button'
              className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100'
              onClick={() =>
                window.open(
                  'https://drive.google.com/file/d/1-LCCRJHvZ4yWf6jXsZilDag5oKPJ8noP/view',
                  '_blank',
                )
              }
            >
              광고 소개서 ↗
            </button>
          </div>
        </div>
        {/* <div>
          <EggBreakAdRevenueGraph />
        </div> */}
        <div className='mt-12 mb-10'>
          <ApplyTabHandler
            applyStatusTab={adStatusTab}
            setApplyStatusTab={setAdStatusTab}
            setPage={setPage}
            btnText1='광고 신청'
            btnText2='신청 완료'
          />
        </div>
      </div>
      {adStatusTab === 'candidate' ? (
        <div className='flex flex-row flex-1'>
          <div className='flex w-2/3 p-10 bg-white shadow-lg rounded-xl'>
            <div className='flex flex-col flex-1'>
              <div className='mb-12'>
                <div className='mb-5 text-2xl font-bold'>
                  올팜 우주 최저가 광고 신청
                </div>
                <div className='flex flex-col flex-1 p-5 bg-gray-100 rounded-xl'>
                  <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                    <BulbIcon className='mr-2' />
                    우주 최저가 광고란?
                  </span>
                  <ul className='ml-5 list-disc'>
                    <li>
                      우주 최저가 광고는 하루 온종일 올팜에 노출을 보장하는
                      광고입니다.
                    </li>
                    <li>
                      <span className='font-bold text-blue-500'>
                        올팜은 매일 120만명 이상이 방문하는 가장 핵심적인 노출
                        구좌입니다.
                      </span>
                    </li>
                    <li>
                      압도적으로 많은 노출을 통해 상품 판매를 촉진시킬 수
                      있습니다.
                    </li>
                    <li>
                      우주 최저가 광고는{' '}
                      <span className='font-bold text-blue-500'>일단위로 </span>
                      구입 가능합니다.
                    </li>
                    <li>
                      상품의 마진을 최대한으로 빼서 가격을 형성하는 판매자님을
                      위한 광고로 광고비 대비 노출이 정말 좋습니다.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>1. 진행 일자</div>
                <div className='flex flex-row flex-1'>
                  <Calendar
                    onChange={setStartDate}
                    value={startDate}
                    maxDate={
                      new Date(moment(new Date()).startOf('day').add(9, 'days'))
                    }
                    minDate={
                      new Date(moment(new Date()).startOf('day').add(3, 'days'))
                    }
                  />
                  <div className='flex flex-col items-end justify-between flex-1 ml-5'>
                    <div className='flex flex-col p-5 bg-gray-100 rounded-xl'>
                      <div className='flex flex-row items-center flex-1 mb-1'>
                        <CheckIcon className='mr-2' />
                        <span className='font-bold'>신청 후 검수 안내</span>
                      </div>
                      <div>
                        우주 최저가 광고는{' '}
                        <span className='font-bold text-blue-500'>
                          신청 후 검수
                        </span>
                        를 통해 진행됩니다. 검수 과정에는 가격을 최우선으로
                        검토합니다.
                      </div>
                      <div>
                        날짜별로 구좌 제한이 있습니다. 광고 신청 후 검수가
                        완료되면 본 페이지를 통해 알려드립니다.
                      </div>
                    </div>
                    <div className='flex flex-col items-end'>
                      <div>광고 시작 일자</div>
                      <div className='flex flex-row items-end justify-end flex-1 my-3'>
                        <div className='mr-2 text-gray-500'>
                          {moment(new Date(startDate)).format('YYYY년')}
                        </div>
                        <div className='text-4xl font-bold'>
                          {moment(new Date(startDate)).format('MM월 DD일')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>2. 상품 선정</div>
                <div>
                  <div>우주 최저가 광고를 진행할 상품을 골라주세요.</div>
                  <div>우주 최저가 광고는 식품 카테고리만 가능해요.</div>
                  <Table columns={columns} data={data} />
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>3. 상품 설명</div>
                <div>
                  <div>
                    해당 상품의 가격, 배경, 매력 등을 구체적으로 작성해주세요.
                  </div>
                  <div>
                    - 이 상품은 타사 또는 타제품 대비 어느정도 저렴한가요?
                  </div>
                  <textarea
                    style={{
                      width: '30%',
                      height: '130px',
                    }}
                    value={messageOne}
                    onChange={(e) => {
                      setMessageOne(e.target.value)
                    }}
                  />
                  <div>
                    - 해당 상품이 저렴한 이유와 배경을 간략히 소개해주세요.
                  </div>
                  <textarea
                    style={{
                      width: '30%',
                      height: '130px',
                    }}
                    value={messageTwo}
                    onChange={(e) => {
                      setMessageTwo(e.target.value)
                    }}
                  />
                  <div>
                    - 기타 해당 상품의 매력을 소개해주세요.(광고 성과, 타사 사례
                    등)
                  </div>
                  <textarea
                    style={{
                      width: '30%',
                      height: '130px',
                    }}
                    value={messageThree}
                    onChange={(e) => {
                      setMessageThree(e.target.value)
                    }}
                  />
                </div>
              </div>
              <div>
                <div className='mt-5 mb-3 text-xl font-bold'>
                  4. 광고 수량 선택
                </div>
                <div>광고는 일단위로 진행됩니다.</div>
                <div>
                  해당 광고는 입찰가 관계 없이 상품의 가격과 많은 판매가 가능한
                  상품만 선정됩니다.
                </div>
                <br />
                <div className='flex flex-row flex-1'>
                  <div className='mr-5'>
                    <div className='font-bold'>광고 수량</div>
                    <select
                      onChange={(e) => {
                        setEggCount(e.target.value)
                      }}
                    >
                      <option value={1}>1일</option>
                    </select>
                  </div>
                  <div className='mr-5'>
                    <div className='font-bold'>광고당 비용(₩)</div>
                    <input type='number' value={5000000} />
                  </div>
                  <div className='flex flex-col justify-start flex-1 mr-5'>
                    <div className='mb-2 font-bold'>결제 방식</div>
                    <div className='flex items-center flex-1'>
                      <input type='radio' checked className='mr-2' readOnly />{' '}
                      정산액 차감
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='fixed right-0 z-50 flex flex-col items-start flex-1 w-1/4 p-10 mr-20 bg-white shadow-xl rounded-xl bottom-10'>
            <div className='mb-5'>
              <div className='mb-3 text-lg font-bold'>광고 신청서</div>
              <div className='mb-2'>
                신청일자:{' '}
                <span className='font-bold text-blue-500'>
                  {moment(new Date(startDate)).format('MM월 DD일')}
                </span>
              </div>
              <div className='mb-2'>
                상품명:{' '}
                <span className='font-bold text-blue-500'>
                  {checkedItemTitle}
                </span>
              </div>

              <div className='mb-2'>
                신청 광고 수:{' '}
                <span className='font-bold text-blue-500'>1일</span>
              </div>
              <div className='mb-2'>
                총 가격:{' '}
                <span className='font-bold text-blue-500'>
                  ￦{commaNumber(5000000)}(정산액 차감)
                </span>
              </div>
            </div>
            <Button
              onClick={async () => {
                const adUnit = {
                  itemId: checkedItemId,
                  messageOne,
                  messageTwo,
                  messageThree,
                  adStartedAt: new Date(
                    moment(new Date(startDate)).startOf('day'),
                  ),
                  price: 5000000,
                  adEndedAt: new Date(
                    moment(new Date(startDate)).startOf('day').add(1, 'days'),
                  ),
                }
                if (sellerSettlementAmount < 0) {
                  alert('최근 3주 정산액이 없는 경우 광고 신청이 불가능합니다.')
                  return
                }
                if (!adUnit.itemId) {
                  alert('상품을 선택해주세요.')
                  return
                }

                if (!adUnit.adStartedAt) {
                  alert('광고 시작일을 입력해주세요.')
                  return
                }
                if (SellerStore.isLoading) return
                SellerStore.setIsLoading(true)
                const result = await backendApis.registerTrendQuizAd(adUnit)
                if (result?.status === 200) {
                  alert(
                    '광고 신청이 완료되었습니다. 입력해주신 광고 시작일자 전일에 광고 진행이 확정되며 승인된 건에 한하여 광고가 시작됩니다.',
                  )
                  cleanUp()

                  setAdStatusTab('complete')
                } else {
                  alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
                }
                SellerStore.setIsLoading(false)
              }}
              appearance='positive'
              size='flexlg'
            >
              신청하기
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <TrendQuizAdStatusBar />
        </div>
      )}
    </div>
  )
}
export default AlfarmTrendQuizScreen
