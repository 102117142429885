import React from 'react'
import { Col, Divider, Row, Tooltip } from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'

const HeaderColumn = ({ span, text, tooltip }) => (
  <Col span={span} className='text-center font-bold'>
    <Row align='middle' justify='center'>
      <div>{text}</div>
      {tooltip && (
        <Tooltip className='mx-1' title={tooltip}>
          <QuestionCircleTwoTone />
        </Tooltip>
      )}
    </Row>
  </Col>
)

const StickyHeader = ({ columns }) => {
  return (
    <div
      style={{
        position: 'sticky',
        top: 80,
        zIndex: 1000,
      }}
      className='flex flex-1 flex-row items-center p-4 bg-slate-600 text-white rounded-md mb-4 max-w-7xl opacity-80'
    >
      {columns?.map((column, index) => (
        <React.Fragment key={column?.text + index?.toString()}>
          <HeaderColumn
            span={column.span}
            text={column.text}
            tooltip={column.tooltip}
          />
          {index < columns.length - 1 && (
            <Divider type='vertical' className='bg-white mx-0' />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default StickyHeader
