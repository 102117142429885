import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import backendApis from '../utils/backendApis'
import AuthStore from '../stores/AuthStore'
import ItemStore from '../stores/ItemStore'
import ImageUploading from 'react-images-uploading'
import OrderStore from '../stores/OrderStore'
import SelectOptionButton from '../components/ItemComponents/SelectOptionButton'
import commaNumber from 'comma-number'
import SearchPriceButton from '../components/ItemComponents/SearchPriceButton'
import SmallCategoryItems from '../components/ItemComponents/SmallCategoryItems'
import YouTube from 'react-youtube'
import Tag from '../components/atoms/tag'
import Log from '../utils/log'
import Card from '../components/atoms/card'
import SubTitle from '../components/atoms/subTitle'
import Description from '../components/atoms/description'
import Input from '../components/atoms/input'
import Button from '../components/atoms/button'
import { BsQuestionSquare as AlwayzQuestion } from 'react-icons/bs'
import TutorialModal from '../components/atoms/tutorialModal'
import Tutorial1 from '../videos/tutorial1.mp4'
import Tutorial2 from '../videos/tutorial2.mp4'
import Tutorial3 from '../videos/tutorial3.mp4'
import Tutorial4 from '../videos/tutorial4.mp4'
import Tutorial5 from '../videos/tutorial5.mp4'
import Tutorial6 from '../videos/tutorial6.mp4'
import Tutorial7 from '../videos/tutorial7.mp4'
import Tutorial8 from '../videos/tutorial8.mp4'
import {
  FcOpenedFolder,
  FcFolder,
  FcPackage as PackageIcon,
} from 'react-icons/fc'
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy'
import fashionGoodEx from '../images/fashionGoodEx.png'
import fashionBadEx from '../images/fashionBadEx.png'
import fashionDetail from '../images/fashionDetail.png'
import Format from '../utils/format'
import Page from '../components/atoms/page'
import DetailImageUpdateComponent from '../components/ItemComponents/DetailImageUpdateComponent'
import ToggleInput from '../components/atoms/toggleInput'
import Modal from '../components/atoms/modal'
import RegistrationStore from '../stores/RegistrationStore'
import {
  IMAGES_DB_INIT_VERSION,
  TREND_ITEMS_REGISTRATION_ID,
} from '../data/indexedDBData'
import RegisterItemGuideCard from '../components/molecules/ItemInfoCards/RegisterItemGuideCard'

const shippingInfoDefault = {
  freeShipping: true,
  shippingMethod: '순차배송',
  shippingFee: 0,
  shippingFeeInfo:
    '무료배송, 도서산간 추가 배송비 -제주 지역: 3,000원 -도서산간 지역: 5,000원',
  returnFee: 5000,
  returnFeeInfo:
    '5,000원 -단, 고객 단순 변심의 경우에만 발생 -도서산간 및 일부 지역 추가비용 발생',
}

const optionsInfoDefault = {
  optionNames: [''],
  totalOptions: [
    [
      {
        name: '',
        img: null,
      },
    ],
  ],
  optionPrices: [
    {
      individualPurchasePrice: 0,
      teamPurchasePrice: 0,
      aiPrice: 0,
      stockNumber: 100,
      sold_out: false,
      hurry_up: false,
      optionMetaDataInfoList: [],
    },
  ],
}

const RegisterTrendFashionItemScreen = observer(() => {
  const history = useHistory()
  useEffect(() => {
    if (!isTempStoredDataLoaded) {
      ItemStore.setItemInfoOnEdit({})
    } else {
      setIsTempStoredDataLoaded(false)
    }
  }, [])
  const [itemTitle, setItemTitle] = useState('') //
  const [largeCategoryInfo, setLargeCategoryInfo] = useState({}) //
  const [mediumCategoryInfo, setMediumCategoryInfo] = useState({}) //
  const [smallCategoryInfo, setSmallCategoryInfo] = useState({}) //
  const [shippingInfo, setShippingInfo] = useState(shippingInfoDefault) //
  const [optionsInfo, setOptionsInfo] = useState(optionsInfoDefault) //
  const [keyWords, setKeyWords] = useState([]) //
  const [mainImagesData, setMainImagesData] = useState([]) //
  const [detailImagesData, setDetailImagesData] = useState([]) //
  const [commonMetaDataList, setCommonMetaDataList] = useState([]) //
  const [optionMetaDataList, setOptionMetaDataList] = useState([]) //
  const [productMetaDataList, setProductMetaDataList] = useState([]) //
  const [croppedMainImagesData, setCroppedMainImagesData] = useState([]) //
  const [optionIndices, setOptionIndices] = useState({})
  const [displayedPrice, setDisplayedPrice] = useState(0)
  const [searchedPrice, setSearchedPrice] = useState(false)
  const [buttonAvailable, setButtonAvailable] = useState(false)
  const [categorySearch, setCategorySearch] = useState('')
  const [nonJejuFee, setNonJejuFee] = useState(5000) //
  const [jejuFee, setJejuFee] = useState(3000) //
  const [alfarmPhotoReviewMode, setAlfarmPhotoReviewMode] = useState(true) //
  const [trendSellerInfo, setTrendFashionSellerInfo] = useState({}) //
  const [recommendItemCode, setRecommendItemCode] = useState('') //
  const [isNecessaryDataFulfilled, setIsNecessaryDataFulfilled] = useState(true)
  const [hasTempStoredData, setHasTempStoredData] = useState(false)
  const [isTempStoredDataLoaded, setIsTempStoredDataLoaded] = useState(false)

  const saveTempData = () => {
    const data = {
      trendItemTitle: itemTitle,
      trendLargeCategoryInfo: JSON.stringify(largeCategoryInfo),
      trendMediumCategoryInfo: JSON.stringify(mediumCategoryInfo),
      trendSmallCategoryInfo: JSON.stringify(smallCategoryInfo),
      trendShippingInfo: JSON.stringify(shippingInfo),
      trendOptionsInfo: JSON.stringify(optionsInfo),
      trendKeyWords: JSON.stringify(keyWords),
      trendCommonMetaDataList: JSON.stringify(commonMetaDataList),
      trendOptionMetaDataList: JSON.stringify(optionMetaDataList),
      trendProductMetaDataList: JSON.stringify(productMetaDataList),
      trendNonJejuFee: nonJejuFee,
      trendJejuFee: jejuFee,
      trendAlfarmPhotoReviewMode: alfarmPhotoReviewMode,
      trendRecommendItemCode: recommendItemCode,
      trendItemInfoOnEdit: JSON.stringify(ItemStore.itemInfoOnEdit),
    }
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        localStorage.setItem(key, data[key])
      }
    }
    localStorage.setItem('trendHasStoredDataForRegistration', true)
    setHasTempStoredData(true)
    if (!window.indexedDB) {
      alert(
        '해당 브라우저에서는 이미지 파일은 저장이 지원되지 않습니다. 이미지를 제외한 나머지는 임시저장 되었습니다.',
      )
    } else {
      saveTempImagesData(
        croppedMainImagesData,
        mainImagesData,
        detailImagesData,
      )
      alert('임시 저장되었습니다!')
    }
  }

  const saveTempImagesData = (
    croppedMainImagesData,
    mainImagesData,
    detailImagesData,
  ) => {
    const openRequest = window.indexedDB.open(
      'AlwayzImagesDB',
      IMAGES_DB_INIT_VERSION,
    )
    let db
    openRequest.onerror = () => {
      alert('이미지를 데이터베이스에 저장하는데 실패했습니다.')
    }
    openRequest.onupgradeneeded = (event) => {
      db = event.target.result
      const objectStore = db.createObjectStore('alwayzImagesStorage', {
        keyPath: 'id',
      })
      objectStore.createIndex('id', 'id', { unique: true })
    }
    openRequest.onsuccess = (event) => {
      db = event.target.result
      const transaction = db.transaction(['alwayzImagesStorage'], 'readwrite')
      const objectStore = transaction.objectStore('alwayzImagesStorage')
      const putRequest = objectStore.put({
        id: TREND_ITEMS_REGISTRATION_ID,
        trendCroppedMainImagesData: croppedMainImagesData,
        trendMainImagesData: mainImagesData,
        trendDetailImagesData: detailImagesData,
      })
      putRequest.onerror = () => {
        alert('이미지를 데이터베이스에 저장하는데 실패했습니다.')
      }
      putRequest.onsuccess = () => {
        console.log('이미지를 데이터베이스에 저장했습니다.')
      }
    }
  }

  const loadTempStoredData = () => {
    if (localStorage.getItem('trendHasStoredDataForRegistration') !== 'true') {
      alert('저장된 데이터가 없습니다!')
      return false
    }
    const keys = [
      'trendItemTitle',
      'trendLargeCategoryInfo',
      'trendMediumCategoryInfo',
      'trendSmallCategoryInfo',
      'trendShippingInfo',
      'trendOptionsInfo',
      'trendKeyWords',
      'trendCommonMetaDataList',
      'trendOptionMetaDataList',
      'trendProductMetaDataList',
      'trendNonJejuFee',
      'trendJejuFee',
      'trendAlfarmPhotoReviewMode',
      'trendRecommendItemCode',
      'trendItemInfoOnEdit',
    ]
    const result = {}
    keys.forEach((key) => {
      const value = localStorage.getItem(key)
      if (
        key === 'trendItemTitle' ||
        key === 'trendNonJejuFee' ||
        key === 'trendJejuFee' ||
        key === 'trendAlfarmPhotoReviewMode' ||
        key === 'trendRecommendItemCode'
      )
        result[key] = value
      else result[key] = JSON.parse(value)
    })
    setItemTitle(result.trendItemTitle)
    setLargeCategoryInfo(result.trendLargeCategoryInfo)
    setMediumCategoryInfo(result.trendMediumCategoryInfo)
    setSmallCategoryInfo(result.trendSmallCategoryInfo)
    setShippingInfo(result.trendShippingInfo)
    setOptionsInfo(result.trendOptionsInfo)
    setKeyWords(result.trendKeyWords)
    setCommonMetaDataList(result.trendCommonMetaDataList)
    setOptionMetaDataList(result.trendOptionMetaDataList)
    setProductMetaDataList(result.trendProductMetaDataList)
    setNonJejuFee(result.trendNonJejuFee)
    setJejuFee(result.trendJejuFee)
    setAlfarmPhotoReviewMode(result.trendmPhotoReviewMode === 'true')
    setRecommendItemCode(result.trendRecommendItemCode)
    ItemStore.setItemInfoOnEdit(result.trendItemInfoOnEdit)
    keys.forEach((key) => {
      localStorage.removeItem(key)
    })
    loadTempImagesData()
    localStorage.setItem('trendHasStoredDataForRegistration', false)
    setHasTempStoredData(false)
    return true
  }

  const loadTempImagesData = () => {
    const openRequest = window.indexedDB.open('AlwayzImagesDB', 1)
    let db
    openRequest.onerror = () => {
      alert('이미지를 데이터베이스에서 불러오는데 실패했습니다.')
    }
    openRequest.onsuccess = (event) => {
      db = event.target.result
      const transaction = db.transaction(['alwayzImagesStorage'], 'readonly')
      const objectStore = transaction.objectStore('alwayzImagesStorage')
      const getRequest = objectStore.get(TREND_ITEMS_REGISTRATION_ID)
      getRequest.onsuccess = (event) => {
        const result = event.target.result
        if (result) {
          setCroppedMainImagesData(result.trendCroppedMainImagesData)
          setMainImagesData(result.trendMainImagesData)
          setDetailImagesData(result.trendDetailImagesData)
        } else {
          alert('이미지가 데이터베이스에 저장되어 있지 않습니다.')
        }
      }
      getRequest.onerror = () => {
        alert('이미지를 데이터베이스에서 불러오는데 실패했습니다.')
      }
    }
  }

  const getIsNecessaryDataFulfilled = async () => {
    if (
      largeCategoryInfo._id === undefined ||
      mediumCategoryInfo._id === undefined ||
      smallCategoryInfo._id === undefined
    ) {
      return false
    }
    if (!ItemStore.itemInfoOnEdit.itemTitle) {
      return false
    }

    if (
      shippingInfo.returnFee === undefined ||
      !shippingInfo.returnFeeInfo ||
      !shippingInfo.shippingFeeInfo
    ) {
      return false
    }
    if (
      shippingInfo.shippingDays === undefined ||
      shippingInfo.shippingCompany === undefined
    ) {
      return false
    }
    if (shippingInfo?.shippingDays <= 0) {
      return false
    }
    if (shippingInfo?.shippingDays >= 14) {
      return false
    }
    if (
      optionsInfo.optionNames.length === 0 ||
      optionsInfo.optionNames.some((name) => !name) ||
      optionsInfo.totalOptions.some((optionsArray) =>
        optionsArray.some((option) => !option.name),
      )
    ) {
      return false
    }
    if (mainImagesData?.length === 0) {
      return false
    }
    if (detailImagesData.length === 0) {
      return false
    }
    let optionPrices = [...optionsInfo.optionPrices]
    let finalOptionPrices = [...optionsInfo.optionPrices]
    let testOption = optionPrices
    while (testOption.length) {
      finalOptionPrices = []
      optionPrices.forEach((option) => {
        finalOptionPrices = finalOptionPrices.concat(option)
      })
      optionPrices = [...finalOptionPrices]
      testOption = finalOptionPrices[0]
    }
    const soldOutResult = finalOptionPrices.every((option) => option.sold_out)
    const teamPurchasePriceResult = finalOptionPrices.some(
      (option) => !option.teamPurchasePrice,
    )
    const individualPurchasePriceResult = finalOptionPrices.some(
      (option) => !option.individualPurchasePrice,
    )
    if (
      soldOutResult ||
      teamPurchasePriceResult ||
      individualPurchasePriceResult
    ) {
      return false
    }
    if (
      finalOptionPrices.some((optionTemp) => {
        return (
          optionTemp?.teamPurchasePrice >=
          0.9 * optionTemp?.individualPurchasePrice
        )
      })
    ) {
      return false
    }
    const teamPurchasePrice = Math.min(
      ...finalOptionPrices
        .filter((option) => !option.sold_out)
        .map((option) => option.teamPurchasePrice),
    )
    const individualPurchasePrice = Math.min(
      ...finalOptionPrices
        .filter((option) => !option.sold_out)
        .map((option) => option.individualPurchasePrice),
    )
    const discountRate = 1 - teamPurchasePrice / individualPurchasePrice
    if (discountRate < 0.1) {
      return false
    }
    if (mainImagesData.some((imageData) => !imageData?.sourceUri)) {
      return false
    }
    if (
      Math.min(
        ...finalOptionPrices.map((option) => option.teamPurchasePrice),
      ) !==
      Math.min(
        ...finalOptionPrices
          .filter((option) => !option.sold_out)
          .map((option) => option.teamPurchasePrice),
      )
    ) {
      return false
    }
    if (
      !trendSellerInfo?.brandName &&
      trendSellerInfo?.sellerType === 'brandFashion'
    ) {
      return false
    }
    if (
      !shippingInfo.ruralAreaShippingFeeInfo ||
      Object.keys(shippingInfo.ruralAreaShippingFeeInfo).length === 0
    ) {
      return false
    }
    return true
  }

  const loadShippingFeesTemplate = async () => {
    const result = await ItemStore.loadShippingFeesTemplate()
    if (result.status === 200) {
      setShippingInfo({
        ...shippingInfo,
        ruralAreaShippingFeeInfo: result.data,
      })
    }
  }

  const loadCommonMetaDataList = async () => {
    const result = await backendApis.loadCommonMetaDataList()
    console.log(`commonMetaDataList: ${JSON.stringify(commonMetaDataList)}`)
    if (result.status === 2000) {
      setCommonMetaDataList(result?.data?.commonMetaDataInfoList || [])
      console.log(`commonMetaDataList: ${JSON.stringify(commonMetaDataList)}`)
    }
  }

  const loadMediumCategoryMetaDataList = async (mediumCategoryId) => {
    const result = await backendApis.loadMediumCategoryMetaDataList(
      mediumCategoryId,
    )
    if (result?.status === 2000) {
      setOptionMetaDataList(result?.data?.optionMetaDataInfoList || [])
      console.log(
        `hereandnow: ${result?.data?.optionMetaDataInfoList?.map((e) => {
          return e.title
        })}`,
      )
      setProductMetaDataList(result?.data?.productMetaDataInfoList || [])
    }
  }

  const loadCategoryMetaDataList = async (
    smallCategoryId,
    mediumCategoryId,
  ) => {
    const result = await backendApis.loadSmallCategoryMetaDataList(
      smallCategoryId,
    )
    if (result?.status === 2000) {
      setOptionMetaDataList(result?.data?.optionMetaDataInfoList || [])
      setProductMetaDataList(result?.data?.productMetaDataInfoList || [])
    } else {
      loadMediumCategoryMetaDataList(mediumCategoryId)
    }
  }

  const handleRegister = async () => {
    if (SellerStore.loadSellerInfo())
      Log.event('RegisterItemScreen', 'RegisterItemScreen', {
        click: 'RegisterItemButton',
      })
    if (
      largeCategoryInfo._id === undefined ||
      mediumCategoryInfo._id === undefined ||
      smallCategoryInfo._id === undefined
    ) {
      alert(`카테고리 설정을 다시 확인해주세요!`)
      return
    }
    if (!ItemStore.itemInfoOnEdit.itemTitle) {
      alert(`상품명을 확인해주세요.`)
      return
    }
    if (
      shippingInfo.returnFee === undefined ||
      !shippingInfo.returnFeeInfo ||
      !shippingInfo.shippingFeeInfo
    ) {
      alert(
        `배송비용 안내 문구, 반품 비용, 혹은 반품 비용 안내 문구를 확인해주세요.`,
      )
      return
    }
    if (
      shippingInfo.shippingDays === undefined ||
      shippingInfo.shippingCompany === undefined
    ) {
      alert('배송일 혹은 택배사를 확인해주세요.')
      return
    }
    if (shippingInfo?.shippingDays <= 0) {
      alert('배송에 걸리는 시간은 하루보다 짧을 수 없습니다.')
      return
    }
    if (shippingInfo?.shippingDays >= 14) {
      alert('배송에 걸리는 시간은 최대 14일을 넘기지 않아야 합니다.')
      return
    }
    if (
      optionsInfo.optionNames.length === 0 ||
      optionsInfo.optionNames.some((name) => !name) ||
      optionsInfo.totalOptions.some((optionsArray) =>
        optionsArray.some((option) => !option.name),
      )
    ) {
      alert(`옵션 정보를 확인해주세요.`)
      return
    }
    if (mainImagesData?.length === 0) {
      alert('상품 메인 이미지를 업로드해주세요.')
      return
    }
    if (detailImagesData.length === 0) {
      alert('상품 상세페이지를 업로드해주세요.')
      return
    }
    let optionPrices = [...optionsInfo.optionPrices]
    let finalOptionPrices = [...optionsInfo.optionPrices]
    let testOption = optionPrices
    while (testOption.length) {
      finalOptionPrices = []
      optionPrices.forEach((option) => {
        finalOptionPrices = finalOptionPrices.concat(option)
      })
      optionPrices = [...finalOptionPrices]
      testOption = finalOptionPrices[0]
    }
    const soldOutResult = finalOptionPrices.every((option) => option.sold_out)
    const teamPurchasePriceResult = finalOptionPrices.some(
      (option) => !option.teamPurchasePrice,
    )
    const individualPurchasePriceResult = finalOptionPrices.some(
      (option) => !option.individualPurchasePrice,
    )
    if (
      soldOutResult ||
      teamPurchasePriceResult ||
      individualPurchasePriceResult
    ) {
      alert(`재고 혹은 판매가격을 다시 확인해주세요.`)
      return
    }
    if (
      finalOptionPrices.some((optionTemp) => {
        return (
          optionTemp?.teamPurchasePrice >=
          0.9 * optionTemp?.individualPurchasePrice
        )
      })
    ) {
      alert(
        `팀구매가는 개인구매가보다 저렴해야 합니다. 옵션 가격을 다시 확인해주세요.`,
      )
      return
    }
    const hurryUpResult = finalOptionPrices.some((option) => option.hurry_up)
    const teamPurchasePrice = Math.min(
      ...finalOptionPrices
        .filter((option) => !option.sold_out)
        .map((option) => option.teamPurchasePrice),
    )
    const individualPurchasePrice = Math.min(
      ...finalOptionPrices
        .filter((option) => !option.sold_out)
        .map((option) => option.individualPurchasePrice),
    )
    const teamPurchasePriceMax = Math.max(
      ...finalOptionPrices.map((option) => option.teamPurchasePrice),
    )
    const individualPurchasePriceMax = Math.max(
      ...finalOptionPrices.map((option) => option.individualPurchasePrice),
    )
    const itemRankTestingSku = true
    const discountRate = 1 - teamPurchasePrice / individualPurchasePrice
    if (discountRate < 0.1) {
      alert(
        `개인구매가 대비 팀구매가의 할인율을 다시 책정해주세요. 개인구매가에 비해 상대적으로 팀구매가가 저렴할 때 할인율이 높아져서 매력적인 상품으로 보일 수 있습니다.`,
      )
      return
    }
    if (teamPurchasePrice < 1000) {
      const confirmLowPrice = window.confirm(
        '입력하신 팀구매가가 1000원 미만입니다. 계속 진행하시겠습니까?',
      )
      if (!confirmLowPrice) {
        return
      }
    }
    if (teamPurchasePriceMax >= 1000000) {
      const confirmHighPrice = window.confirm(
        '입력하신 팀구매가가 1,000,000원 이상입니다. 계속 진행하시겠습니까?',
      )
      if (!confirmHighPrice) {
        return
      }
    }
    if (mainImagesData.some((imageData) => !imageData?.sourceUri)) {
      alert('상품 메인이미지 업로드를 완료해주세요.')
      return
    }
    if (
      Math.min(
        ...finalOptionPrices.map((option) => option.teamPurchasePrice),
      ) !==
      Math.min(
        ...finalOptionPrices
          .filter((option) => !option.sold_out)
          .map((option) => option.teamPurchasePrice),
      )
    ) {
      alert(
        `판매 가능한(품절 아닌) 옵션의 최저가가 판매가로 노출됩니다. 품절 옵션을 확인해주세요`,
      )
      return
    }
    if (
      !trendSellerInfo?.brandName &&
      trendSellerInfo?.sellerType === 'brandFashion'
    ) {
      alert(
        '브랜드를 산출하는 과정에 문제가 생겼습니다. 판매자 지원센터로 문의 부탁드립니다',
      )
      return
    }
    if (
      !shippingInfo.ruralAreaShippingFeeInfo ||
      Object.keys(shippingInfo.ruralAreaShippingFeeInfo).length === 0
    ) {
      alert(
        '서버 문제로 인해 도서 산간지역 옵션 처리 중 문제가 생겼습니다. 지원센터에 문의해주세요.',
      )
      return
    }
    const testingSkuGroup = 'apeiron'
    SellerStore.setIsLoading(true)
    const itemInfo = {
      ...ItemStore.itemInfoOnEdit,
      optionsInfo,
      categoryInfo: {
        largeCategoryId: largeCategoryInfo._id,
        mediumCategoryId: mediumCategoryInfo._id,
        smallCategoryId: smallCategoryInfo._id,
      },
      mainImageUris: [],
      detailImageUris: [],
      shippingInfo,
      textForQuery: [
        ItemStore.itemInfoOnEdit.itemTitle,
        largeCategoryInfo.largeCategoryNames.kr,
        mediumCategoryInfo.mediumCategoryNames.kr,
        smallCategoryInfo.smallCategoryNames.kr,
        ...keyWords,
      ].join(', '),
      commonMetaDataInfoList: commonMetaDataList,
      productMetaDataInfoList: productMetaDataList,
      soldOut: false,
      hurryUp: hurryUpResult,
      totalOrderQuantity: 0,
      totalRatingCount: 0,
      totalRatingScore: 0,
      teamPurchasePrice,
      individualPurchasePrice,
      discountRate,
      purchasingUsersInfo: [],
      hideSellerInfo: [
        '60eeeef9a833f92af03805e8',
        '61b89ca6c74c1e3242900151',
      ].includes(SellerStore.sellerInfo._id),
      vectorWeights: 1,
      sellerInfo: {
        sellerId: SellerStore.sellerInfo._id,
        address: SellerStore.sellerInfo.address,
        businessRegistrationNumber:
          SellerStore.sellerInfo.businessRegistrationNumber,
        contactNumber: SellerStore.sellerInfo.contactNumber,
        email: SellerStore.sellerInfo.email,
        mailOrderBusinessNumber: SellerStore.sellerInfo.mailOrderBusinessNumber,
        sellerDisplayName: SellerStore.sellerInfo.sellerDisplayName,
        isTrendFashionSeller: true,
      },
      teamDealThreshold: 2,
      purchaseLimits: 10,
      testingSkuGroup,
      testingSkuGroupLogs: [
        {
          optionsInfo,
          testingSkuGroup,
          teamPurchasePrice,
          individualPurchasePrice,
          soldOut: false,
        },
      ],
      sellerType: SellerStore.sellerInfo.sellerType,
      itemRankTestingSku,
      alfarmPhotoReviewMode,
      trendFashionInfo: {
        brandName: trendSellerInfo?.brandName,
      },
      recommendItemCode,
    }
    const result = await backendApis.uploadItemInfo(itemInfo)

    if (result?.status === 2000) {
      await Promise.all([
        await backendApis.uploadItemMainImages(result.data, mainImagesData),
        await backendApis.uploadItemDetailImages(result.data, detailImagesData),
      ])
      SellerStore.setIsLoading(false)
      setLargeCategoryInfo({})
      setMediumCategoryInfo({})
      setSmallCategoryInfo({})
      setShippingInfo({
        freeShipping: true,
        shippingMethod: '순차배송',
        shippingFee: 0,
        shippingFeeInfo:
          '무료배송· 도서산간 추가 배송비- 제주 지역: 3,000원- 도서산간 지역: 5,000원',
        returnFee: 5000,
        returnFeeInfo:
          '5,000원- 단, 고객 변심의 경우에만 발생- 도서산간 및 일부 지역 추가비용 발생',
        ruralAreaShippingFeeInfo: ItemStore.shippingFeesTemplate,
      })
      setOptionsInfo({
        optionNames: [''],
        totalOptions: [
          [
            {
              name: '',
              img: null,
            },
          ],
        ],
        optionPrices: [
          {
            individualPurchasePrice: 0,
            teamPurchasePrice: 0,
            aiPrice: 0,
            stockNumber: 100,
            sold_out: false,
            hurry_up: false,
            optionMetaDataInfoList: [],
          },
        ],
      })
      setKeyWords([])
      setMainImagesData([])
      setCroppedMainImagesData([])
      setDetailImagesData([])
      setCommonMetaDataList([])
      setProductMetaDataList([])
      setOptionMetaDataList([])
      ItemStore.setItemInfoOnEdit({})
      history.push('/items')
      alert(
        `상품 등록이 완료되었습니다. 판매가 시작됩니다. 아이템 아이디 : ${result.data}`,
      )
    } else {
      alert(`문제가 발생했습니다. 판매가 지원센터에 문의해주세요.`)
    }
  }

  const handleSave = () => {
    try {
      saveTempData()
      RegistrationStore.setIsTempStoredDataChanged(true)
    } catch (error) {
      alert('임시 저장에 실패했습니다.\n', error)
    }
  }

  const handleLoad = () => {
    const result = loadTempStoredData()
    if (result) {
      setIsTempStoredDataLoaded(true)
      RegistrationStore.setIsTempStoredDataChanged(true)
      alert(
        '데이터를 불러온 뒤 다시 저장하고 싶다면 반드시 임시 저장하기 버튼을 눌러주세요. 그렇지 않으면 저장된 값이 사라집니다.',
      )
    }
  }

  useEffect(() => {
    if (!isTempStoredDataLoaded) {
      setOptionsInfo({
        ...optionsInfo,
        optionPrices: optionsInfo?.optionPrices?.map(
          (firstOption, firstOptionIndex) => {
            if (Array.isArray(firstOption)) {
              return firstOption?.map((firstOptionTemp) => {
                return {
                  ...firstOptionTemp,
                  optionMetaDataInfoList: optionMetaDataList,
                }
              })
            }
            return {
              ...firstOption,
              optionMetaDataInfoList: optionMetaDataList,
            }
          },
        ),
      })
    } else {
      setIsTempStoredDataLoaded(false)
    }
  }, [optionMetaDataList])

  useEffect(() => {
    if (!isTempStoredDataLoaded) {
      if (ItemStore.categories.length === 0) {
        ItemStore.loadCategories()
      }
      if (ItemStore.shippingFeesTemplate.length === 0) {
        loadShippingFeesTemplate()
      } else {
        setShippingInfo({
          ...shippingInfo,
          ruralAreaShippingFeeInfo: ItemStore.shippingFeesTemplate,
        })
      }
    } else {
      setIsTempStoredDataLoaded(false)
    }
  }, [])

  useEffect(() => {
    const hasStoredData =
      localStorage.getItem('trendHasStoredDataForRegistration') === 'true'
    setHasTempStoredData(hasStoredData)
    if (
      hasStoredData &&
      window.confirm(
        '이전에 작성하던 임시 저장된 데이터가 있습니다. 불러오시겠습니까?\n데이터를 불러온 뒤 다시 저장하고 싶다면 반드시 임시 저장하기 버튼을 눌러주세요.',
      )
    ) {
      handleLoad()
    }
  }, [])

  useEffect(() => {
    if (RegistrationStore.isClickedTempLoadBtnOnHeader) {
      handleLoad()
      RegistrationStore.setIsClickedTempLoadBtnOnHeader(false)
    }
    if (RegistrationStore.isClickedTempSaveBtnOnHeader) {
      handleSave()
      RegistrationStore.setIsClickedTempSaveBtnOnHeader(false)
    }
  }, [
    RegistrationStore.isClickedTempLoadBtnOnHeader,
    RegistrationStore.isClickedTempSaveBtnOnHeader,
  ])

  // 해야할것
  // 2. DOM
  // 맨 마지막에 매직넘버 없애기, setHasTempStoredData(false) 밖으로 빼기

  useEffect(() => {
    if (Object.keys(optionIndices).length === optionsInfo.totalOptions.length) {
      let selectedPriceOption = JSON.parse(
        JSON.stringify(optionsInfo?.optionPrices),
      )
      Object.values(optionIndices).forEach((optionIndex, indexTemp) => {
        if (selectedPriceOption[optionIndex]) {
          selectedPriceOption = selectedPriceOption[optionIndex]
        } else {
          selectedPriceOption = selectedPriceOption[0]
          const optionIndicesTemp = { ...optionIndices }
          optionIndicesTemp[indexTemp] = 0
          setOptionIndices(optionIndicesTemp)
        }
      })
      if (selectedPriceOption?.sold_out) {
        setDisplayedPrice(0)
        setOptionIndices({})
      }
      setDisplayedPrice(selectedPriceOption.teamPurchasePrice)
    }
  }, [optionIndices, optionsInfo])

  useEffect(async () => {
    const result = await backendApis.getTrendFashionSellerInfo()
    console.log(`invitation items info: `, result?.status, result?.data)

    if (result?.status === 2000) {
      setTrendFashionSellerInfo(result?.data[0].trendFashionInfo)
    }
  }, [])

  useEffect(async () => {
    const isFulfilled = await getIsNecessaryDataFulfilled()
    setIsNecessaryDataFulfilled(isFulfilled)
  }, [
    itemTitle,
    largeCategoryInfo,
    mediumCategoryInfo,
    smallCategoryInfo,
    optionsInfo,
    shippingInfo,
    commonMetaDataList,
    mainImagesData,
    detailImagesData,
  ])

  return (
    <div className='max-w-7xl'>
      <Page title='상품 등록' Icon={PackageIcon}>
        <div className='flex flex-col flex-1 w-full'>
          {/* 상품 등록 가이드 */}
          <RegisterItemGuideCard />
          <Card title='상품 카테고리'>
            <div className='flex-col flex-1'>
              <div className='flex-row flex-1 w-full'>
                <div className='flex-row'>
                  <div>
                    <div className='flex items-center flex-1 mb-5 '>
                      <SubTitle>카테고리를 추가해 주세요</SubTitle>
                      <TutorialModal
                        modalTitle='카테고리 가이드'
                        videoSource={Tutorial1}
                      >
                        <AlwayzQuestion className='w-4 h-4' />
                      </TutorialModal>
                    </div>
                    <select
                      id='large_category'
                      name='large_category'
                      value={
                        typeof largeCategoryInfo._id === 'number'
                          ? largeCategoryInfo._id
                          : ''
                      }
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setLargeCategoryInfo({})
                          setMediumCategoryInfo({})
                          setSmallCategoryInfo({})
                          ItemStore.setItemInfoOnEdit({
                            ...ItemStore.itemInfoOnEdit,
                            categoryInfo: {
                              largeCategoryId: parseInt(0, 10),
                            },
                          })
                          return
                        }
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            largeCategoryId: parseInt(0, 10),
                          },
                        })
                        const largeCategoryInfoTemp = ItemStore.categories.find(
                          (category) => category._id === parseInt(0, 10),
                        )
                        if (largeCategoryInfoTemp) {
                          setLargeCategoryInfo(largeCategoryInfoTemp)
                          setMediumCategoryInfo({})
                          setSmallCategoryInfo({})
                        }
                      }}
                    >
                      <option value=''>===</option>

                      {ItemStore.categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.largeCategoryNames.kr}
                        </option>
                      ))}
                    </select>
                    <select
                      id='medium_category'
                      name='medium_category'
                      value={mediumCategoryInfo._id}
                      onChange={(e) => {
                        console.log(`category: ${ItemStore.categories}`)
                        if (e.target.value === '') {
                          setMediumCategoryInfo({})
                          setSmallCategoryInfo({})
                          return
                        }
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            ...ItemStore.itemInfoOnEdit.categoryInfo,
                            mediumCategoryId: parseInt(e.target.value, 10),
                          },
                        })
                        const mediumCategoryInfoTemp =
                          largeCategoryInfo.mediumCategories.find(
                            (category) =>
                              category._id === parseInt(e.target.value, 10),
                          )
                        console.log(JSON.stringify(mediumCategoryInfoTemp))
                        if (mediumCategoryInfoTemp) {
                          setMediumCategoryInfo(mediumCategoryInfoTemp)
                          setSmallCategoryInfo({})
                        }
                      }}
                    >
                      <option value=''>===</option>
                      {largeCategoryInfo.mediumCategories?.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.mediumCategoryNames.kr}
                        </option>
                      ))}
                    </select>
                    <select
                      id='small_category'
                      name='small_category'
                      value={smallCategoryInfo._id}
                      onChange={(e) => {
                        if (e.target.value === '') {
                          setSmallCategoryInfo({})
                          return
                        }
                        ItemStore.setItemInfoOnEdit({
                          ...ItemStore.itemInfoOnEdit,
                          categoryInfo: {
                            ...ItemStore.itemInfoOnEdit.categoryInfo,
                            smallCategoryId: parseInt(e.target.value, 10),
                          },
                        })
                        const smallCategoryInfoTemp =
                          mediumCategoryInfo.smallCategories.find(
                            (category) =>
                              category._id === parseInt(e.target.value, 10),
                          )
                        if (smallCategoryInfoTemp) {
                          setSmallCategoryInfo(smallCategoryInfoTemp)
                          loadCommonMetaDataList()
                          loadCategoryMetaDataList(
                            parseInt(e.target.value, 10),
                            mediumCategoryInfo?._id,
                          )
                        }
                      }}
                    >
                      <option value=''>===</option>
                      {mediumCategoryInfo.smallCategories?.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.smallCategoryNames.kr}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='flex flex-col items-start'>
                  <Description className='mt-5'>카테고리 검색</Description>
                  <div className='flex flex-row mb-10'>
                    <Input
                      id='register-categorySearch'
                      className='mr-2'
                      type='text'
                      value={categorySearch}
                      onChange={(e) => {
                        console.log(`text: ${e.target.value}`)
                        setCategorySearch(e.target.value)
                      }}
                    />
                    <Button
                      type='button'
                      appearance='neutralSub'
                      onClick={async () => {
                        if (!categorySearch) {
                          ItemStore.setSearchedCategories([])
                          return
                        }

                        SellerStore.setIsLoading(true)
                        const result1 = ItemStore.categories.filter(
                          (largeCategory) => {
                            return largeCategory.largeCategoryNames.kr.includes(
                              categorySearch,
                            )
                          },
                        )
                        const result2 = ItemStore.categories
                          .map((largeCategory) => {
                            const validMediumCategories =
                              largeCategory.mediumCategories.filter(
                                (mediumCategory) => {
                                  return mediumCategory.mediumCategoryNames.kr.includes(
                                    categorySearch,
                                  )
                                },
                              )
                            return {
                              ...largeCategory,
                              mediumCategories: validMediumCategories,
                            }
                          })
                          .filter((largeCategory) => {
                            return largeCategory.mediumCategories.length > 0
                          })

                        const result3 = ItemStore.categories
                          .map((largeCategory) => {
                            const validMediumCategories =
                              largeCategory.mediumCategories
                                .map((mediumCategories) => {
                                  const validSmallCategories =
                                    mediumCategories.smallCategories.filter(
                                      (smallCategory) => {
                                        return smallCategory.smallCategoryNames.kr.includes(
                                          categorySearch,
                                        )
                                      },
                                    )
                                  return {
                                    ...mediumCategories,
                                    smallCategories: validSmallCategories,
                                  }
                                })
                                .filter((mediumCategories) => {
                                  return (
                                    mediumCategories.smallCategories.length > 0
                                  )
                                })
                            return {
                              ...largeCategory,
                              mediumCategories: validMediumCategories,
                            }
                          })
                          .filter((largeCategory) => {
                            return largeCategory.mediumCategories.length > 0
                          })

                        console.log(
                          `item search result: ${JSON.stringify(result2)}`,
                        )
                        SellerStore.setIsLoading(false)
                        ItemStore.setSearchedLargeCategories(result1)
                        ItemStore.setSearchedMediumCategories(result2)
                        ItemStore.setSearchedSmallCategories(result3)
                      }}
                    >
                      검색
                    </Button>
                  </div>
                </div>
              </div>
              <table>
                <thead style={{ fontSize: 15 }}>
                  <tr>
                    <th>카테고리</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: 15 }}>
                  {ItemStore.searchedLargeCategories.map(
                    (categoryResult, index) => (
                      <tr key={categoryResult?._id + index.toString()}>
                        <td>{categoryResult?.largeCategoryNames.kr} ></td>
                      </tr>
                    ),
                  )}

                  {ItemStore.searchedMediumCategories.map(
                    (categoryResult, index) => {
                      return categoryResult?.mediumCategories.map(
                        (mediumCategories) => {
                          return (
                            <tr key={mediumCategories?._id + index.toString()}>
                              <td>
                                {categoryResult?.largeCategoryNames.kr} >{' '}
                                {mediumCategories?.mediumCategoryNames.kr}
                              </td>
                            </tr>
                          )
                        },
                      )
                    },
                  )}
                  {ItemStore.searchedSmallCategories.map(
                    (categoryResult, index) => {
                      return categoryResult.mediumCategories.map(
                        (mediumCategories) => {
                          return mediumCategories.smallCategories.map(
                            (smallCategories) => {
                              return (
                                <tr
                                  key={smallCategories?._id + index.toString()}
                                >
                                  <td>
                                    {categoryResult?.largeCategoryNames.kr} >{' '}
                                    {mediumCategories?.mediumCategoryNames.kr} >{' '}
                                    {smallCategories?.smallCategoryNames.kr}
                                  </td>
                                </tr>
                              )
                            },
                          )
                        },
                      )
                    },
                  )}
                </tbody>
              </table>
            </div>
            <SmallCategoryItems smallCategoryId={smallCategoryInfo?._id} />
          </Card>

          <Card title='상품명' necessary>
            <div>
              <div className='flex items-center mb-5 '>
                <SubTitle>상품명을 입력해 주세요</SubTitle>

                <TutorialModal
                  modalTitle='상품명 가이드'
                  videoSource={Tutorial2}
                >
                  <AlwayzQuestion />
                </TutorialModal>
              </div>

              <Description>입력해주신 상품명은 앱에 노출됩니다.</Description>
            </div>
            <Tooltip
              arrow='true'
              theme='dark'
              title='💡 상품명을 정확하게 작성할 수록 노출도가 상승해요!'
              animation='fade'
              position='right'
            >
              <Input
                id='register-productName'
                className='w-1/2'
                type='text'
                placeholder='예시)브랜드명 + 제품명'
                value={ItemStore.itemInfoOnEdit.itemTitle || itemTitle}
                onChange={(e) => {
                  ItemStore.setItemInfoOnEdit({
                    ...ItemStore.itemInfoOnEdit,
                    itemTitle: Format.Title(e.target.value.normalize('NFC')),
                  })
                  setItemTitle(e.target.value.normalize('NFC'))
                }}
              />
            </Tooltip>
          </Card>
          <Card title='상품 옵션' necessary>
            <div className='flex flex-col justify-start flex-1 items-between'>
              <div className='flex flex-1'>
                <div className='w-2/5'>
                  <SubTitle className='mb-5'>
                    상품 옵션을 추가해 주세요
                  </SubTitle>
                  <Button
                    className='mb-3'
                    appearance='neutralSub'
                    type='button'
                    onClick={() => {
                      if (optionsInfo.optionNames.length === 2) {
                        return
                      }
                      const optionNames = [...optionsInfo.optionNames]
                      const totalOptions = [...optionsInfo.totalOptions]
                      optionNames.push('')
                      totalOptions.push([
                        {
                          name: '',
                          img: null,
                        },
                      ])
                      let optionPrices = [
                        {
                          individualPurchasePrice: 0,
                          teamPurchasePrice: 0,
                          aiPrice: 0,
                          stockNumber: 100,
                          sold_out: false,
                          hurry_up: false,
                          optionMetaDataInfoList: optionMetaDataList,
                        },
                      ]
                      if (optionNames.length === 2) {
                        optionPrices = optionsInfo.optionPrices.map(
                          (optionPriceInfo) => [optionPriceInfo],
                        )
                      }
                      setOptionsInfo({
                        ...optionsInfo,
                        optionNames,
                        totalOptions,
                        optionPrices,
                      })
                    }}
                  >
                    옵션 추가하기
                  </Button>
                  {optionsInfo.optionNames.map((optionName, index) => (
                    <div
                      className='flex flex-col flex-1 px-5 py-5 mb-10 border-2'
                      key={index.toString()}
                    >
                      <div className='flex items-center '>
                        <div>옵션 명 (ex: 중량, 색상, 구성 등)</div>
                        <TutorialModal
                          modalTitle='상품 옵션'
                          videoSource={Tutorial3}
                        >
                          <AlwayzQuestion />
                        </TutorialModal>
                      </div>
                      <div className='flex'>
                        <Input
                          className='flex w-full my-2 '
                          type='text'
                          placeholder={`${
                            index + 1
                          }번째 옵션명을 입력해주세요.`}
                          value={optionName}
                          onChange={(e) => {
                            setOptionsInfo({
                              ...optionsInfo,
                              optionNames: optionsInfo.optionNames.map(
                                (optionNameTemp, indexTemp) => {
                                  if (index === indexTemp) {
                                    return Format.Title(
                                      e.target.value.normalize('NFC'),
                                    )
                                  }
                                  return optionNameTemp
                                },
                              ),
                            })
                          }}
                        />
                      </div>
                      <div style={{ height: 10 }} />
                      <div className='flex flex-col flex-1'>
                        세부옵션 (ex: 3kg)
                        {optionsInfo.totalOptions[index].map(
                          (option, detailIndex) => (
                            <div key={detailIndex.toString()}>
                              <Input
                                className='flex w-full mt-2 mb-5'
                                type='text'
                                placeholder={`${
                                  detailIndex + 1
                                }번째 세부옵션을 입력해주세요.`}
                                value={option.name}
                                onChange={(e) => {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    totalOptions: optionsInfo.totalOptions.map(
                                      (totalOption, indexTemp) => {
                                        if (index === indexTemp) {
                                          return totalOption.map(
                                            (
                                              detailedOption,
                                              detailIndexTemp,
                                            ) => {
                                              if (
                                                detailIndex === detailIndexTemp
                                              ) {
                                                return {
                                                  ...detailedOption,
                                                  name: Format.Title(
                                                    e.target.value.normalize(
                                                      'NFC',
                                                    ),
                                                  ),
                                                }
                                              }
                                              return detailedOption
                                            },
                                          )
                                        }
                                        return totalOption
                                      },
                                    ),
                                  })
                                }}
                              />
                            </div>
                          ),
                        )}
                        <Button
                          type='button'
                          appearance='neutralSub'
                          onClick={() => {
                            const optionPrices = JSON.parse(
                              JSON.stringify(optionsInfo.optionPrices),
                            )
                            if (index === 0) {
                              if (Array.isArray(optionPrices[0])) {
                                optionPrices.push(
                                  optionPrices[0]?.map((optionPricesTemp) => {
                                    return {
                                      ...optionPricesTemp,
                                      optionMetaDataInfoList:
                                        optionMetaDataList,
                                    }
                                  }),
                                )
                              } else
                                optionPrices.push({
                                  ...optionPrices[0],
                                  optionMetaDataInfoList: optionMetaDataList,
                                })
                            } else if (index === 1) {
                              optionPrices.forEach((firstOption) => {
                                firstOption.push({
                                  ...firstOption[0],
                                  optionMetaDataInfoList: optionMetaDataList,
                                })
                              })
                            }
                            const detailedOptions = [
                              ...optionsInfo.totalOptions[index],
                            ]
                            detailedOptions.push({
                              name: '',
                              img: null,
                            })
                            setOptionsInfo({
                              ...optionsInfo,
                              totalOptions: optionsInfo.totalOptions.map(
                                (totalOption, indexTemp) => {
                                  if (index === indexTemp) {
                                    return detailedOptions
                                  }
                                  return totalOption
                                },
                              ),
                              optionPrices,
                            })
                          }}
                        >
                          세부옵션 추가하기
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='flex flex-col items-center flex-1 pt-20 pb-16'>
                  <div className='mb-5 text-lg font-bold'>
                    팀구매 결제 옵션 미리보기
                  </div>
                  <div
                    style={{
                      width: '50%',
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      border: '10px solid #999999',
                      paddingTop: 10,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingBottom: 30,
                    }}
                  >
                    <div
                      style={{
                        height: 56,

                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div style={{ fontSize: 20, color: '#EA3B49' }}>
                        {commaNumber(displayedPrice)}원
                        <div
                          style={{
                            fontSize: 14,
                            color: '#999999',
                            marginTop: 4,
                          }}
                        >
                          팀구매 인원이 모이면 배송이 시작됩니다.
                        </div>
                      </div>
                    </div>
                    <div style={{ height: 0.5, backgroundColor: '#E0E0E0' }} />
                    <div className='relative'>
                      {optionsInfo.optionNames?.map(
                        (value, optionLargeIndex) => {
                          return (
                            <div
                              key={
                                value.toString() + optionLargeIndex.toString()
                              }
                              style={{ paddingTop: 12, paddingBottom: 12 }}
                            >
                              <div
                                style={{
                                  padding: 10,
                                  fontSize: 14,
                                  color: value ? 'black' : 'red',
                                }}
                              >
                                {value ||
                                  `${
                                    optionLargeIndex + 1
                                  }번째 옵션명을 입력해주세요`}
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {optionsInfo?.totalOptions[
                                  optionLargeIndex
                                ].map((value, index) => {
                                  return (
                                    <SelectOptionButton
                                      key={value.toString() + index.toString()}
                                      item={value}
                                      checked={
                                        optionIndices[optionLargeIndex] ===
                                        index
                                      }
                                      optionLargeIndex={optionLargeIndex}
                                      index={index}
                                      correspondingOptionsLength={
                                        optionsInfo?.totalOptions[
                                          optionLargeIndex
                                        ]?.length
                                      }
                                      optionsInfo={optionsInfo}
                                      optionIndices={optionIndices}
                                      setOptionIndices={setOptionIndices}
                                    />
                                  )
                                })}
                              </div>
                            </div>
                          )
                        },
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col flex-1 mb-5'>
                <div className='flex flex-row mr-2'>
                  <SubTitle className='mr-1'>사진 후기 증진 신청</SubTitle>
                  <div className='flex flex-row items-center flex-1'>
                    <Modal
                      modalTitle='사진 후기 신청이란?'
                      imageSource={alfarmPhotoReviewMode}
                      className='mr-2 text-gray-500 opacity-100'
                      modalContent={
                        <>
                          <ol className='font-normal whitespace-normal list-decimal'>
                            <li>
                              상품의 빠른 판매량 증진을 위해서는 사진후기의 양이
                              많은 것이 중요하다는 사실을 파악했습니다.
                              <span className='font-bold text-emerald-500'>
                                {' '}
                                이를 위해 올웨이즈가 도와드립니다.
                              </span>
                            </li>
                            <li>
                              이벤트는 올팜 내에서 진행되며 고객님께서{' '}
                              <span className='font-bold text-emerald-500'>
                                상품 후기가 40개 이하인 제품
                              </span>{' '}
                              에 대해 사진 후기를 남기면 일정양의 재화를
                              받습니다.
                            </li>
                            <li>
                              사진후기 1개 당 정산에서 300원이 차감되며{' '}
                              <span className='font-bold text-emerald-500'>
                                그 외 금액은 올웨이즈가 부담합니다
                              </span>
                            </li>
                            <li>
                              <span className='font-bold text-emerald-500'>
                                상품 후기가 40개를 넘어가면{' '}
                              </span>
                              신청이 되어 있더라도 자동으로 이벤트 대상에서
                              제외됩니다.{' '}
                            </li>
                          </ol>
                        </>
                      }
                    >
                      <AlwayzQuestion />
                    </Modal>
                    <ToggleInput
                      state={alfarmPhotoReviewMode}
                      setState={setAlfarmPhotoReviewMode}
                      className='mr-2'
                      turnOffMessage='사진 후기를 취소하시는 경우 노출과 더불어 판매량이 줄어들 수 있습니다. 사진 후기를 취소하시겠습니까?'
                    />
                    <span className='text-gray-900 select-none'>
                      {alfarmPhotoReviewMode ? '켜짐' : '꺼짐'}
                    </span>
                    <div />
                  </div>
                </div>
              </div>
              <div className='flex items-center mb-5 '>
                <SubTitle>상세 옵션별 가격 및 재고량 설정</SubTitle>

                <TutorialModal
                  modalTitle='개인 /팀구매가'
                  videoSource={Tutorial4}
                >
                  <AlwayzQuestion />
                </TutorialModal>
              </div>

              {optionsInfo.optionNames.length > 0 && (
                <div className='flex overflow-x-scroll'>
                  <table>
                    <thead>
                      <tr>
                        <th className='text-base'>
                          {optionsInfo.optionNames.length === 1
                            ? optionsInfo.optionNames[0] || '옵션 1'
                            : `${optionsInfo.optionNames[1] || '옵션 2'} / ${
                                optionsInfo.optionNames[0] || '옵션 1'
                              }`}
                        </th>
                        {optionsInfo.totalOptions[0].map((option, index) => (
                          <th
                            className='text-base'
                            key={option.toString() + index.toString()}
                          >
                            {option.name || `옵션 1_세부옵션 ${index + 1}`}
                            {index !== 0 && (
                              <Button
                                appearance='gray'
                                size='sm'
                                className='ml-2'
                                type='button'
                                onClick={() => {
                                  setOptionIndices({})
                                  if (
                                    optionsInfo.totalOptions[0].length !== 1
                                  ) {
                                    setOptionsInfo({
                                      ...optionsInfo,
                                      totalOptions:
                                        optionsInfo.totalOptions.map(
                                          (totalOption, indexTemp) => {
                                            if (indexTemp === 0) {
                                              return totalOption.filter(
                                                (detailedOption, indexTemp2) =>
                                                  indexTemp2 !== index,
                                              )
                                            }
                                            return totalOption
                                          },
                                        ),
                                      optionPrices:
                                        optionsInfo.optionPrices.filter(
                                          (optionPrice, indexTemp2) =>
                                            indexTemp2 !== index,
                                        ),
                                    })
                                  }
                                }}
                              >
                                삭제
                              </Button>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='text-base'>
                      {optionsInfo.optionNames.length === 1 ? (
                        <tr>
                          <td>세부정보</td>
                          {optionsInfo.totalOptions[0].map(
                            (firstOption, firstOptionIndex) => (
                              <td
                                className='items-center py-5'
                                key={
                                  firstOption?.name +
                                  firstOptionIndex.toString()
                                }
                              >
                                <div>
                                  <div>
                                    개인 구매가
                                    <Tooltip
                                      arrow='true'
                                      theme='dark'
                                      title='💡 개인 구매가는 오픈마켓 판매가 기준으로 입력하시는 것을 추천드립니다. 개인 구매가는 노출도에 영향을 주지 않아요!'
                                      animation='fade'
                                      position='right'
                                    >
                                      <Input
                                        id='register-individualPrice'
                                        className='mb-2 ml-3'
                                        type='number'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ].individualPurchasePrice
                                        }
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return {
                                                      ...firstOptionTemp,
                                                      individualPurchasePrice:
                                                        parseInt(
                                                          e.target.value,
                                                          10,
                                                        ) || 0,
                                                    }
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div>
                                    팀 구매가
                                    <Tooltip
                                      arrow='true'
                                      theme='dark'
                                      title='💡 팀 구매가는 온라인 최저가 -10% 이상 할인가를 권장합니다. 팀 구매가가 권장가 보다 높을 경우 상품 노출에 불이익을 받으실 수 있어요!'
                                      animation='fade'
                                      position='right'
                                    >
                                      <Input
                                        id='register-teamPrice'
                                        className='mb-2 ml-3'
                                        type='number'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ].teamPurchasePrice
                                        }
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return {
                                                      ...firstOptionTemp,
                                                      teamPurchasePrice:
                                                        parseInt(
                                                          e.target.value,
                                                          10,
                                                        ) || 0,
                                                      aiPrice:
                                                        parseInt(
                                                          e.target.value,
                                                          10,
                                                        ) || 0,
                                                    }
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                  {AuthStore.isMasterAccount && (
                                    <div>
                                      지원금
                                      <Input
                                        className='mb-2 ml-3'
                                        type='number'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ].platformSupportPrice
                                        }
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return {
                                                      ...firstOptionTemp,
                                                      platformSupportPrice:
                                                        parseInt(
                                                          e.target.value,
                                                          10,
                                                        ) || 0,
                                                    }
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </div>
                                  )}
                                  <div>
                                    재고량
                                    <Input
                                      className='mb-2 ml-3'
                                      type='number'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ].stockNumber
                                      }
                                      onChange={(e) => {
                                        const stockNumber =
                                          parseInt(e.target.value, 10) || 0
                                        if (stockNumber === 0) {
                                          setOptionIndices({})
                                        }
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    stockNumber,
                                                    sold_out: stockNumber === 0,
                                                    hurry_up: stockNumber < 10,
                                                  }
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                  <div>
                                    판매자 상품코드
                                    <input
                                      className='mb-2 ml-3'
                                      type='text'
                                      value={
                                        optionsInfo.optionPrices[
                                          firstOptionIndex
                                        ].optionCode
                                      }
                                      onChange={(e) => {
                                        setOptionsInfo({
                                          ...optionsInfo,
                                          optionPrices:
                                            optionsInfo.optionPrices.map(
                                              (
                                                firstOptionTemp,
                                                firstOptionIndexTemp,
                                              ) => {
                                                if (
                                                  firstOptionIndex ===
                                                  firstOptionIndexTemp
                                                ) {
                                                  return {
                                                    ...firstOptionTemp,
                                                    optionCode:
                                                      e.target.value || '',
                                                  }
                                                }
                                                return firstOptionTemp
                                              },
                                            ),
                                        })
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            ),
                          )}
                        </tr>
                      ) : (
                        optionsInfo.totalOptions[1].map(
                          (secondOption, secondOptionIndex) => (
                            <tr key={secondOptionIndex.toString()}>
                              <td>
                                {secondOption.name ||
                                  `옵션 2_세부옵션 ${secondOptionIndex + 1}`}
                                <Button
                                  appearance='gray'
                                  size='sm'
                                  className=''
                                  type='button'
                                  onClick={() => {
                                    setOptionIndices({})
                                    if (
                                      optionsInfo.totalOptions[1].length !== 1
                                    ) {
                                      setOptionsInfo({
                                        ...optionsInfo,
                                        totalOptions:
                                          optionsInfo.totalOptions.map(
                                            (totalOption, indexTemp) => {
                                              if (indexTemp === 1) {
                                                return totalOption.filter(
                                                  (
                                                    detailedOption,
                                                    indexTemp2,
                                                  ) =>
                                                    indexTemp2 !==
                                                    secondOptionIndex,
                                                )
                                              }
                                              return totalOption
                                            },
                                          ),
                                        optionPrices:
                                          optionsInfo.optionPrices.map(
                                            (optionPrice) =>
                                              optionPrice.filter(
                                                (
                                                  detailOptionPrice,
                                                  indexTemp2,
                                                ) =>
                                                  indexTemp2 !==
                                                  secondOptionIndex,
                                              ),
                                          ),
                                      })
                                    } else {
                                      setOptionsInfo({
                                        ...optionsInfo,
                                        optionNames: [
                                          optionsInfo.optionNames[0],
                                        ],
                                        totalOptions: [
                                          optionsInfo.totalOptions[0],
                                        ],
                                        optionPrices:
                                          optionsInfo.optionPrices.map(
                                            (optionPrice) => optionPrice[0],
                                          ),
                                      })
                                    }
                                  }}
                                >
                                  삭제
                                </Button>
                              </td>
                              {optionsInfo.totalOptions[0].map(
                                (firstOption, firstOptionIndex) => (
                                  <td
                                    key={
                                      firstOption?.name +
                                      firstOptionIndex.toString()
                                    }
                                    className='items-center py-5 '
                                  >
                                    <div>
                                      <div className='mr-0'>개인 구매가</div>
                                      <Input
                                        className='mb-2 ml-3'
                                        type='number'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ][secondOptionIndex]
                                            .individualPurchasePrice
                                        }
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return firstOptionTemp.map(
                                                      (
                                                        secondOptionTemp,
                                                        secondOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          secondOptionIndex ===
                                                          secondOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...secondOptionTemp,
                                                            individualPurchasePrice:
                                                              parseInt(
                                                                e.target.value,
                                                                10,
                                                              ) || 0,
                                                          }
                                                        }
                                                        return secondOptionTemp
                                                      },
                                                    )
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <div className='mr-0'>팀 구매가</div>
                                      <Input
                                        className='mb-2 ml-3'
                                        type='number'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ][secondOptionIndex].teamPurchasePrice
                                        }
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return firstOptionTemp.map(
                                                      (
                                                        secondOptionTemp,
                                                        secondOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          secondOptionIndex ===
                                                          secondOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...secondOptionTemp,
                                                            teamPurchasePrice:
                                                              parseInt(
                                                                e.target.value,
                                                                10,
                                                              ) || 0,
                                                            aiPrice:
                                                              parseInt(
                                                                e.target.value,
                                                                10,
                                                              ) || 0,
                                                          }
                                                        }
                                                        return secondOptionTemp
                                                      },
                                                    )
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </div>
                                    {AuthStore.isMasterAccount && (
                                      <div>
                                        지원금
                                        <Input
                                          className='mb-2 ml-3'
                                          type='number'
                                          value={
                                            optionsInfo.optionPrices[
                                              firstOptionIndex
                                            ][secondOptionIndex]
                                              .platformSupportPrice
                                          }
                                          onChange={(e) => {
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo.optionPrices.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return firstOptionTemp.map(
                                                        (
                                                          secondOptionTemp,
                                                          secondOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            secondOptionIndex ===
                                                            secondOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...secondOptionTemp,
                                                              platformSupportPrice:
                                                                parseInt(
                                                                  e.target
                                                                    .value,
                                                                  10,
                                                                ) || 0,
                                                            }
                                                          }
                                                          return secondOptionTemp
                                                        },
                                                      )
                                                    }
                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                          }}
                                        />
                                      </div>
                                    )}
                                    <div>
                                      재고량
                                      <Input
                                        className='mb-2 ml-3'
                                        type='number'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ][secondOptionIndex].stockNumber
                                        }
                                        onChange={(e) => {
                                          const stockNumber =
                                            parseInt(e.target.value, 10) || 0
                                          if (stockNumber === 0) {
                                            setOptionIndices({})
                                          }
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return firstOptionTemp.map(
                                                      (
                                                        secondOptionTemp,
                                                        secondOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          secondOptionIndex ===
                                                          secondOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...secondOptionTemp,
                                                            stockNumber,
                                                            sold_out:
                                                              stockNumber === 0,
                                                            hurry_up:
                                                              stockNumber < 10,
                                                          }
                                                        }
                                                        return secondOptionTemp
                                                      },
                                                    )
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </div>
                                    <div>
                                      판매자 상품코드
                                      <input
                                        className='mb-2 ml-3'
                                        type='text'
                                        value={
                                          optionsInfo.optionPrices[
                                            firstOptionIndex
                                          ][secondOptionIndex].optionCode
                                        }
                                        onChange={(e) => {
                                          setOptionsInfo({
                                            ...optionsInfo,
                                            optionPrices:
                                              optionsInfo.optionPrices.map(
                                                (
                                                  firstOptionTemp,
                                                  firstOptionIndexTemp,
                                                ) => {
                                                  if (
                                                    firstOptionIndex ===
                                                    firstOptionIndexTemp
                                                  ) {
                                                    return firstOptionTemp.map(
                                                      (
                                                        secondOptionTemp,
                                                        secondOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          secondOptionIndex ===
                                                          secondOptionIndexTemp
                                                        ) {
                                                          return {
                                                            ...secondOptionTemp,
                                                            optionCode:
                                                              e.target.value ||
                                                              '',
                                                          }
                                                        }
                                                        return secondOptionTemp
                                                      },
                                                    )
                                                  }
                                                  return firstOptionTemp
                                                },
                                              ),
                                          })
                                        }}
                                      />
                                    </div>
                                  </td>
                                ),
                              )}
                            </tr>
                          ),
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <SearchPriceButton
                optionsInfo={optionsInfo}
                itemTitle={ItemStore.itemInfoOnEdit.itemTitle}
                searchedPrice={searchedPrice}
                setSearchedPrice={setSearchedPrice}
                buttonAvailable={buttonAvailable}
                setButtonAvailable={setButtonAvailable}
                shippingCompany={shippingInfo.shippingCompany}
              />
            </div>
          </Card>
          <Card title='상품 주요정보' necessary>
            <div>
              <div className='flex items-center mb-5 '>
                <SubTitle>상품 주요정보를 입력해 주세요</SubTitle>
                <TutorialModal
                  modalTitle='상품 주요정보'
                  videoSource={Tutorial5}
                >
                  <AlwayzQuestion />
                </TutorialModal>
              </div>
              <Description>
                올웨이즈는 추천 알고리즘에 따라 고객 맞춤형으로 상품이
                노출됩니다. <br />
                상품 세부정보를 정확히 기재해주셔야 고객 매칭 데이터를 바탕으로
                상품이 노출될 수 있습니다.
              </Description>
              <div className='overflow-x-scroll'>
                <table className='mb-10'>
                  <thead>
                    <tr>
                      <th className='text-base'>
                        {optionsInfo.optionNames.length === 1
                          ? optionsInfo.optionNames[0] || '옵션 1'
                          : `${optionsInfo.optionNames[1] || '옵션 2'} / ${
                              optionsInfo.optionNames[0] || '옵션 1'
                            }`}
                      </th>
                      {optionsInfo.totalOptions[0].map((option, index) => (
                        <th
                          key={option.name + index.toString()}
                          className='text-base'
                        >
                          {option.name || `옵션 1_세부옵션 ${index + 1}`}
                          {index !== 0 && (
                            <Button
                              className='ml-3'
                              appearance='gray'
                              size='sm'
                              type='button'
                              onClick={() => {
                                setOptionIndices({})
                                if (optionsInfo.totalOptions[0].length !== 1) {
                                  setOptionsInfo({
                                    ...optionsInfo,
                                    totalOptions: optionsInfo.totalOptions.map(
                                      (totalOption, indexTemp) => {
                                        if (indexTemp === 0) {
                                          return totalOption.filter(
                                            (detailedOption, indexTemp2) =>
                                              indexTemp2 !== index,
                                          )
                                        }
                                        return totalOption
                                      },
                                    ),
                                    optionPrices:
                                      optionsInfo.optionPrices.filter(
                                        (optionPrice, indexTemp2) =>
                                          indexTemp2 !== index,
                                      ),
                                  })
                                }
                              }}
                            >
                              삭제
                            </Button>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {optionsInfo.optionNames.length === 1 ? (
                      <tr>
                        <td className='text-base'>세부정보</td>
                        {optionsInfo.totalOptions[0].map(
                          (firstOption, firstOptionIndex) => (
                            <td
                              key={
                                firstOption?.name + firstOptionIndex.toString()
                              }
                            >
                              {optionsInfo?.optionPrices[
                                firstOptionIndex
                              ]?.optionMetaDataInfoList?.map(
                                (metaData, metaDataIndex) => (
                                  <tr
                                    key={
                                      metaData.title + metaDataIndex.toString()
                                    }
                                  >
                                    <td>
                                      <div className='text-sm'>
                                        {metaData.title}
                                      </div>
                                    </td>

                                    <td>
                                      {(!metaData?.basicOptions ||
                                        metaData?.basicOptions?.length ===
                                          0) && (
                                        <Input
                                          type='text'
                                          placeholder={metaData.placeholder}
                                          value={metaData.contents}
                                          onChange={(e) => {
                                            console.log(e.target.value)
                                            setOptionsInfo({
                                              ...optionsInfo,
                                              optionPrices:
                                                optionsInfo?.optionPrices?.map(
                                                  (
                                                    firstOptionTemp,
                                                    firstOptionIndexTemp,
                                                  ) => {
                                                    if (
                                                      firstOptionIndex ===
                                                      firstOptionIndexTemp
                                                    ) {
                                                      return {
                                                        ...firstOptionTemp,
                                                        optionMetaDataInfoList:
                                                          firstOptionTemp?.optionMetaDataInfoList?.map(
                                                            (
                                                              metaDataTemp,
                                                              metaDataIndexTemp,
                                                            ) => {
                                                              if (
                                                                metaDataIndex ===
                                                                  metaDataIndexTemp &&
                                                                metaData
                                                                  ?.contentsUnits
                                                                  ?.length > 0
                                                              ) {
                                                                return {
                                                                  ...metaDataTemp,
                                                                  contents:
                                                                    Number(
                                                                      e.target
                                                                        .value,
                                                                    ),
                                                                }
                                                              }
                                                              if (
                                                                metaDataIndex ===
                                                                  metaDataIndexTemp &&
                                                                (metaData
                                                                  ?.contentsUnits
                                                                  ?.length ===
                                                                  0 ||
                                                                  !metaData?.contentsUnits)
                                                              ) {
                                                                return {
                                                                  ...metaDataTemp,
                                                                  contents:
                                                                    e.target
                                                                      .value,
                                                                }
                                                              }
                                                              return metaDataTemp
                                                            },
                                                          ),
                                                      }
                                                    }

                                                    return firstOptionTemp
                                                  },
                                                ),
                                            })
                                            const invalidWeightRegex =
                                              /(\*|x|X|\(|\))/
                                            if (
                                              invalidWeightRegex?.test(
                                                e.target.value,
                                              )
                                            ) {
                                              alert(
                                                '무게/중량/용량만 입력해주세요',
                                              )
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...firstOptionTemp,
                                                          optionMetaDataInfoList:
                                                            firstOptionTemp?.optionMetaDataInfoList?.map(
                                                              (
                                                                metaDataTemp,
                                                                metaDataIndexTemp,
                                                              ) => {
                                                                if (
                                                                  metaDataIndex ===
                                                                  metaDataIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...metaDataTemp,
                                                                    contents:
                                                                      e.target.value?.replace(
                                                                        invalidWeightRegex,
                                                                        '',
                                                                      ),
                                                                  }
                                                                }
                                                                return metaDataTemp
                                                              },
                                                            ),
                                                        }
                                                      }

                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }
                                            const invalidMiddleRegex = /(~|-)/
                                            if (
                                              metaData?.title !== '모델명' &&
                                              invalidMiddleRegex?.test(
                                                e.target.value,
                                              )
                                            ) {
                                              alert('평균값만 입력해주세요')
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...firstOptionTemp,
                                                          optionMetaDataInfoList:
                                                            firstOptionTemp?.optionMetaDataInfoList?.map(
                                                              (
                                                                metaDataTemp,
                                                                metaDataIndexTemp,
                                                              ) => {
                                                                if (
                                                                  metaDataIndex ===
                                                                  metaDataIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...metaDataTemp,
                                                                    contents:
                                                                      e.target.value?.replace(
                                                                        invalidMiddleRegex,
                                                                        '',
                                                                      ),
                                                                  }
                                                                }
                                                                return metaDataTemp
                                                              },
                                                            ),
                                                        }
                                                      }
                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }
                                            const invalidDetailRegex =
                                              /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                                            if (
                                              invalidDetailRegex?.test(
                                                e.target.value,
                                              )
                                            ) {
                                              alert(
                                                '세부적인 내용을 입력해주세요',
                                              )
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...firstOptionTemp,
                                                          optionMetaDataInfoList:
                                                            firstOptionTemp?.optionMetaDataInfoList?.map(
                                                              (
                                                                metaDataTemp,
                                                                metaDataIndexTemp,
                                                              ) => {
                                                                if (
                                                                  metaDataIndex ===
                                                                  metaDataIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...metaDataTemp,
                                                                    contents:
                                                                      e.target.value?.replace(
                                                                        invalidDetailRegex,
                                                                        '',
                                                                      ),
                                                                  }
                                                                }
                                                                return metaDataTemp
                                                              },
                                                            ),
                                                        }
                                                      }

                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }
                                            const invalidUnitRegex = /[^\d]+/
                                            if (
                                              invalidUnitRegex?.test(
                                                e.target.value,
                                              ) &&
                                              metaData?.contentsUnits?.length >
                                                0
                                            ) {
                                              alert('숫자만 입력해주세요')
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...firstOptionTemp,
                                                          optionMetaDataInfoList:
                                                            firstOptionTemp?.optionMetaDataInfoList?.map(
                                                              (
                                                                metaDataTemp,
                                                                metaDataIndexTemp,
                                                              ) => {
                                                                if (
                                                                  metaDataIndex ===
                                                                  metaDataIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...metaDataTemp,
                                                                    contents:
                                                                      e.target.value?.replace(
                                                                        invalidUnitRegex,
                                                                        '',
                                                                      ),
                                                                  }
                                                                }
                                                                return metaDataTemp
                                                              },
                                                            ),
                                                        }
                                                      }

                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }
                                          }}
                                        />
                                      )}
                                      <div className='flex flex-row'>
                                        {metaData?.basicOptions?.map(
                                          (basicOption) => {
                                            return (
                                              <div key={basicOption}>
                                                <input
                                                  checked={
                                                    optionsInfo?.optionPrices?.[
                                                      firstOptionIndex
                                                    ]?.optionMetaDataInfoList?.[
                                                      metaDataIndex
                                                    ]?.basicOption ===
                                                    basicOption
                                                  }
                                                  type='checkbox'
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setOptionsInfo({
                                                        ...optionsInfo,
                                                        optionPrices:
                                                          optionsInfo?.optionPrices?.map(
                                                            (
                                                              firstOptionTemp,
                                                              firstOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                firstOptionIndex ===
                                                                firstOptionIndexTemp
                                                              ) {
                                                                return {
                                                                  ...firstOptionTemp,
                                                                  optionMetaDataInfoList:
                                                                    firstOptionTemp?.optionMetaDataInfoList?.map(
                                                                      (
                                                                        metaDataTemp,
                                                                        metaDataIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          metaDataIndex ===
                                                                          metaDataIndexTemp
                                                                        ) {
                                                                          delete metaDataTemp?.basicOptionExtra
                                                                          return {
                                                                            ...metaDataTemp,
                                                                            basicOption,
                                                                          }
                                                                        }
                                                                        return metaDataTemp
                                                                      },
                                                                    ),
                                                                }
                                                              }

                                                              return firstOptionTemp
                                                            },
                                                          ),
                                                      })
                                                    }
                                                  }}
                                                />{' '}
                                                <span className='mr-2 text-base'>
                                                  {basicOption}{' '}
                                                </span>{' '}
                                              </div>
                                            )
                                          },
                                        )}
                                        {metaData?.basicOptions?.length > 0 && (
                                          <div key='기타'>
                                            <input
                                              checked={
                                                optionsInfo?.optionPrices?.[
                                                  firstOptionIndex
                                                ]?.optionMetaDataInfoList?.[
                                                  metaDataIndex
                                                ]?.basicOptionExtra === '기타'
                                              }
                                              type='checkbox'
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...firstOptionTemp,
                                                              optionMetaDataInfoList:
                                                                firstOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        basicOptionExtra:
                                                                          '기타',
                                                                        basicOption:
                                                                          '',
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }

                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }
                                              }}
                                            />{' '}
                                            <span className='text-base'>
                                              기타{' '}
                                            </span>{' '}
                                          </div>
                                        )}
                                        {metaData?.basicOptionExtra ===
                                          '기타' && (
                                          <input
                                            type='text'
                                            onChange={(e) => {
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...firstOptionTemp,
                                                          optionMetaDataInfoList:
                                                            firstOptionTemp?.optionMetaDataInfoList?.map(
                                                              (
                                                                metaDataTemp,
                                                                metaDataIndexTemp,
                                                              ) => {
                                                                if (
                                                                  metaDataIndex ===
                                                                  metaDataIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...metaDataTemp,
                                                                    basicOption:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                }
                                                                return metaDataTemp
                                                              },
                                                            ),
                                                        }
                                                      }
                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }}
                                          />
                                        )}
                                      </div>{' '}
                                    </td>
                                    <td>
                                      <div className='flex flex-row'>
                                        {metaData?.contentsUnits?.map(
                                          (contentsUnit) => {
                                            return (
                                              <div key={contentsUnit}>
                                                <input
                                                  checked={
                                                    optionsInfo?.optionPrices?.[
                                                      firstOptionIndex
                                                    ]?.optionMetaDataInfoList?.[
                                                      metaDataIndex
                                                    ]?.contentsUnit ===
                                                    contentsUnit
                                                  }
                                                  type='checkbox'
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      setOptionsInfo({
                                                        ...optionsInfo,
                                                        optionPrices:
                                                          optionsInfo?.optionPrices?.map(
                                                            (
                                                              firstOptionTemp,
                                                              firstOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                firstOptionIndex ===
                                                                firstOptionIndexTemp
                                                              ) {
                                                                return {
                                                                  ...firstOptionTemp,
                                                                  optionMetaDataInfoList:
                                                                    firstOptionTemp?.optionMetaDataInfoList?.map(
                                                                      (
                                                                        metaDataTemp,
                                                                        metaDataIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          metaDataIndex ===
                                                                          metaDataIndexTemp
                                                                        ) {
                                                                          delete metaDataTemp?.contentsUnitExtra
                                                                          return {
                                                                            ...metaDataTemp,
                                                                            contentsUnit,
                                                                          }
                                                                        }
                                                                        return metaDataTemp
                                                                      },
                                                                    ),
                                                                }
                                                              }

                                                              return firstOptionTemp
                                                            },
                                                          ),
                                                      })
                                                    }
                                                  }}
                                                />{' '}
                                                <span className='mr-2 text-base'>
                                                  {contentsUnit}{' '}
                                                </span>{' '}
                                              </div>
                                            )
                                          },
                                        )}
                                        {metaData?.contentsUnits?.length >
                                          0 && (
                                          <div key='기타'>
                                            <input
                                              checked={
                                                optionsInfo?.optionPrices?.[
                                                  firstOptionIndex
                                                ]?.optionMetaDataInfoList?.[
                                                  metaDataIndex
                                                ]?.contentsUnitExtra === '기타'
                                              }
                                              type='checkbox'
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return {
                                                              ...firstOptionTemp,
                                                              optionMetaDataInfoList:
                                                                firstOptionTemp?.optionMetaDataInfoList?.map(
                                                                  (
                                                                    metaDataTemp,
                                                                    metaDataIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      metaDataIndex ===
                                                                      metaDataIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...metaDataTemp,
                                                                        contentsUnitExtra:
                                                                          '기타',
                                                                        contentsUnit:
                                                                          '',
                                                                      }
                                                                    }
                                                                    return metaDataTemp
                                                                  },
                                                                ),
                                                            }
                                                          }

                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }
                                              }}
                                            />{' '}
                                            <span className='text-base'>
                                              기타{' '}
                                            </span>{' '}
                                          </div>
                                        )}
                                        {metaData?.contentsUnitExtra ===
                                          '기타' && (
                                          <input
                                            type='text'
                                            onChange={(e) => {
                                              setOptionsInfo({
                                                ...optionsInfo,
                                                optionPrices:
                                                  optionsInfo?.optionPrices?.map(
                                                    (
                                                      firstOptionTemp,
                                                      firstOptionIndexTemp,
                                                    ) => {
                                                      if (
                                                        firstOptionIndex ===
                                                        firstOptionIndexTemp
                                                      ) {
                                                        return {
                                                          ...firstOptionTemp,
                                                          optionMetaDataInfoList:
                                                            firstOptionTemp?.optionMetaDataInfoList?.map(
                                                              (
                                                                metaDataTemp,
                                                                metaDataIndexTemp,
                                                              ) => {
                                                                if (
                                                                  metaDataIndex ===
                                                                  metaDataIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...metaDataTemp,
                                                                    contentsUnit:
                                                                      e.target
                                                                        .value,
                                                                  }
                                                                }
                                                                return metaDataTemp
                                                              },
                                                            ),
                                                        }
                                                      }

                                                      return firstOptionTemp
                                                    },
                                                  ),
                                              })
                                            }}
                                          />
                                        )}
                                      </div>{' '}
                                    </td>
                                  </tr>
                                ),
                              )}
                            </td>
                          ),
                        )}
                      </tr>
                    ) : (
                      optionsInfo.totalOptions[1].map(
                        (secondOption, secondOptionIndex) => (
                          <tr
                            key={
                              secondOption?.name + secondOptionIndex.toString()
                            }
                          >
                            <td className='text-base'>
                              {secondOption.name ||
                                `옵션 2_세부옵션 ${secondOptionIndex + 1}`}
                              <Button
                                size='sm'
                                appearance='gray'
                                type='button'
                                onClick={() => {
                                  setOptionIndices({})
                                  if (
                                    optionsInfo.totalOptions[1].length !== 1
                                  ) {
                                    setOptionsInfo({
                                      ...optionsInfo,
                                      totalOptions:
                                        optionsInfo.totalOptions.map(
                                          (totalOption, indexTemp) => {
                                            if (indexTemp === 1) {
                                              return totalOption.filter(
                                                (detailedOption, indexTemp2) =>
                                                  indexTemp2 !==
                                                  secondOptionIndex,
                                              )
                                            }
                                            return totalOption
                                          },
                                        ),
                                      optionPrices:
                                        optionsInfo.optionPrices.map(
                                          (optionPrice) =>
                                            optionPrice.filter(
                                              (detailOptionPrice, indexTemp2) =>
                                                indexTemp2 !==
                                                secondOptionIndex,
                                            ),
                                        ),
                                    })
                                  } else {
                                    setOptionsInfo({
                                      ...optionsInfo,
                                      optionNames: [optionsInfo.optionNames[0]],
                                      totalOptions: [
                                        optionsInfo.totalOptions[0],
                                      ],
                                      optionPrices:
                                        optionsInfo.optionPrices.map(
                                          (optionPrice) => optionPrice[0],
                                        ),
                                    })
                                  }
                                }}
                              >
                                삭제
                              </Button>
                            </td>
                            {optionsInfo.totalOptions[0].map(
                              (firstOption, firstOptionIndex) => (
                                <td
                                  key={
                                    firstOption?.name +
                                    firstOptionIndex.toString()
                                  }
                                >
                                  {optionsInfo?.optionPrices[firstOptionIndex][
                                    secondOptionIndex
                                  ]?.optionMetaDataInfoList.map(
                                    (metaData, metaDataIndex) => (
                                      <tr
                                        className='text-sm'
                                        key={
                                          metaData.title +
                                          metaDataIndex.toString()
                                        }
                                      >
                                        <td>
                                          <div className='text-sm'>
                                            {' '}
                                            {metaData.title}{' '}
                                          </div>
                                        </td>

                                        <td>
                                          {(!metaData?.basicOptions ||
                                            metaData?.basicOptions?.length ===
                                              0) && (
                                            <Input
                                              type='text'
                                              placeholder={metaData.placeholder}
                                              value={metaData.contents}
                                              onChange={(e) => {
                                                setOptionsInfo({
                                                  ...optionsInfo,
                                                  optionPrices:
                                                    optionsInfo?.optionPrices?.map(
                                                      (
                                                        firstOptionTemp,
                                                        firstOptionIndexTemp,
                                                      ) => {
                                                        if (
                                                          firstOptionIndex ===
                                                          firstOptionIndexTemp
                                                        ) {
                                                          return firstOptionTemp.map(
                                                            (
                                                              secondOptionTemp,
                                                              secondOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                secondOptionIndex ===
                                                                secondOptionIndexTemp
                                                              ) {
                                                                return {
                                                                  ...secondOptionTemp,
                                                                  optionMetaDataInfoList:
                                                                    secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                      (
                                                                        metaDataTemp,
                                                                        metaDataIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          metaDataIndex ===
                                                                            metaDataIndexTemp &&
                                                                          metaData
                                                                            ?.contentsUnits
                                                                            ?.length >
                                                                            0
                                                                        ) {
                                                                          return {
                                                                            ...metaDataTemp,
                                                                            contents:
                                                                              Number(
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                              ),
                                                                          }
                                                                        }
                                                                        if (
                                                                          metaDataIndex ===
                                                                            metaDataIndexTemp &&
                                                                          (metaData
                                                                            ?.contentsUnits
                                                                            ?.length ===
                                                                            0 ||
                                                                            !metaData?.contentsUnits)
                                                                        ) {
                                                                          return {
                                                                            ...metaDataTemp,
                                                                            contents:
                                                                              e
                                                                                .target
                                                                                .value,
                                                                          }
                                                                        }
                                                                        return metaDataTemp
                                                                      },
                                                                    ),
                                                                }
                                                              }
                                                              return secondOptionTemp
                                                            },
                                                          )
                                                        }
                                                        return firstOptionTemp
                                                      },
                                                    ),
                                                })
                                                const invalidWeightRegex =
                                                  /(\*|x|X|\(|\))/
                                                if (
                                                  invalidWeightRegex?.test(
                                                    e.target.value,
                                                  )
                                                ) {
                                                  alert(
                                                    '무게/중량/용량만 입력해주세요',
                                                  )
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return firstOptionTemp.map(
                                                              (
                                                                secondOptionTemp,
                                                                secondOptionIndexTemp,
                                                              ) => {
                                                                if (
                                                                  secondOptionIndex ===
                                                                  secondOptionIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...secondOptionTemp,
                                                                    optionMetaDataInfoList:
                                                                      secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                        (
                                                                          metaDataTemp,
                                                                          metaDataIndexTemp,
                                                                        ) => {
                                                                          if (
                                                                            metaDataIndex ===
                                                                            metaDataIndexTemp
                                                                          ) {
                                                                            return {
                                                                              ...metaDataTemp,
                                                                              contents:
                                                                                e.target.value?.replace(
                                                                                  invalidWeightRegex,
                                                                                  '',
                                                                                ),
                                                                            }
                                                                          }
                                                                          return metaDataTemp
                                                                        },
                                                                      ),
                                                                  }
                                                                }
                                                                return secondOptionTemp
                                                              },
                                                            )
                                                          }
                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }
                                                const invalidMiddleRegex =
                                                  /(~|-)/
                                                if (
                                                  metaData?.title !==
                                                    '모델명' &&
                                                  invalidMiddleRegex?.test(
                                                    e.target.value,
                                                  )
                                                ) {
                                                  alert('평균값만 입력해주세요')
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return firstOptionTemp.map(
                                                              (
                                                                secondOptionTemp,
                                                                secondOptionIndexTemp,
                                                              ) => {
                                                                if (
                                                                  secondOptionIndex ===
                                                                  secondOptionIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...secondOptionTemp,
                                                                    optionMetaDataInfoList:
                                                                      secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                        (
                                                                          metaDataTemp,
                                                                          metaDataIndexTemp,
                                                                        ) => {
                                                                          if (
                                                                            metaDataIndex ===
                                                                            metaDataIndexTemp
                                                                          ) {
                                                                            return {
                                                                              ...metaDataTemp,
                                                                              contents:
                                                                                e.target.value?.replace(
                                                                                  invalidMiddleRegex,
                                                                                  '',
                                                                                ),
                                                                            }
                                                                          }
                                                                          return metaDataTemp
                                                                        },
                                                                      ),
                                                                  }
                                                                }
                                                                return secondOptionTemp
                                                              },
                                                            )
                                                          }
                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }
                                                const invalidDetailRegex =
                                                  /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                                                if (
                                                  invalidDetailRegex?.test(
                                                    e.target.value,
                                                  )
                                                ) {
                                                  alert(
                                                    '세부적인 내용을 입력해주세요',
                                                  )
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return firstOptionTemp.map(
                                                              (
                                                                secondOptionTemp,
                                                                secondOptionIndexTemp,
                                                              ) => {
                                                                if (
                                                                  secondOptionIndex ===
                                                                  secondOptionIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...secondOptionTemp,
                                                                    optionMetaDataInfoList:
                                                                      secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                        (
                                                                          metaDataTemp,
                                                                          metaDataIndexTemp,
                                                                        ) => {
                                                                          if (
                                                                            metaDataIndex ===
                                                                            metaDataIndexTemp
                                                                          ) {
                                                                            return {
                                                                              ...metaDataTemp,
                                                                              contents:
                                                                                e.target.value?.replace(
                                                                                  invalidDetailRegex,
                                                                                  '',
                                                                                ),
                                                                            }
                                                                          }
                                                                          return metaDataTemp
                                                                        },
                                                                      ),
                                                                  }
                                                                }
                                                                return secondOptionTemp
                                                              },
                                                            )
                                                          }
                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }
                                                const invalidUnitRegex =
                                                  /[^\d]+/
                                                if (
                                                  invalidUnitRegex?.test(
                                                    e.target.value,
                                                  ) &&
                                                  metaData?.contentsUnits
                                                    ?.length > 0
                                                ) {
                                                  alert('숫자만 입력해주세요')
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return firstOptionTemp.map(
                                                              (
                                                                secondOptionTemp,
                                                                secondOptionIndexTemp,
                                                              ) => {
                                                                if (
                                                                  secondOptionIndex ===
                                                                  secondOptionIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...secondOptionTemp,
                                                                    optionMetaDataInfoList:
                                                                      secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                        (
                                                                          metaDataTemp,
                                                                          metaDataIndexTemp,
                                                                        ) => {
                                                                          if (
                                                                            metaDataIndex ===
                                                                            metaDataIndexTemp
                                                                          ) {
                                                                            return {
                                                                              ...metaDataTemp,
                                                                              contents:
                                                                                e.target.value?.replace(
                                                                                  invalidUnitRegex,
                                                                                  '',
                                                                                ),
                                                                            }
                                                                          }
                                                                          return metaDataTemp
                                                                        },
                                                                      ),
                                                                  }
                                                                }
                                                                return secondOptionTemp
                                                              },
                                                            )
                                                          }
                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }
                                              }}
                                            />
                                          )}
                                          <div className='flex flex-row'>
                                            {metaData?.basicOptions?.map(
                                              (basicOption) => {
                                                return (
                                                  <div key={basicOption}>
                                                    <input
                                                      checked={
                                                        optionsInfo
                                                          ?.optionPrices?.[
                                                          firstOptionIndex
                                                        ][secondOptionIndex]
                                                          ?.optionMetaDataInfoList?.[
                                                          metaDataIndex
                                                        ]?.basicOption ===
                                                        basicOption
                                                      }
                                                      type='checkbox'
                                                      onChange={(e) => {
                                                        console.log(
                                                          `e.target.checked: ${e.target.checked}, ${basicOption}`,
                                                        )
                                                        if (e.target.checked) {
                                                          setOptionsInfo({
                                                            ...optionsInfo,
                                                            optionPrices:
                                                              optionsInfo?.optionPrices?.map(
                                                                (
                                                                  firstOptionTemp,
                                                                  firstOptionIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    firstOptionIndex ===
                                                                    firstOptionIndexTemp
                                                                  ) {
                                                                    return firstOptionTemp.map(
                                                                      (
                                                                        secondOptionTemp,
                                                                        secondOptionIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          secondOptionIndex ===
                                                                          secondOptionIndexTemp
                                                                        ) {
                                                                          return {
                                                                            ...secondOptionTemp,
                                                                            optionMetaDataInfoList:
                                                                              secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                                (
                                                                                  metaDataTemp,
                                                                                  metaDataIndexTemp,
                                                                                ) => {
                                                                                  if (
                                                                                    metaDataIndex ===
                                                                                    metaDataIndexTemp
                                                                                  ) {
                                                                                    delete metaDataTemp?.basicOptionExtra
                                                                                    return {
                                                                                      ...metaDataTemp,
                                                                                      basicOption,
                                                                                    }
                                                                                  }
                                                                                  return metaDataTemp
                                                                                },
                                                                              ),
                                                                          }
                                                                        }
                                                                        return secondOptionTemp
                                                                      },
                                                                    )
                                                                  }
                                                                  return firstOptionTemp
                                                                },
                                                              ),
                                                          })
                                                        }
                                                      }}
                                                    />{' '}
                                                    <span className='mr-2 text-base'>
                                                      {basicOption}{' '}
                                                    </span>{' '}
                                                  </div>
                                                )
                                              },
                                            )}
                                            {metaData?.basicOptions?.length >
                                              0 && (
                                              <div key='기타'>
                                                <input
                                                  checked={
                                                    optionsInfo?.optionPrices?.[
                                                      firstOptionIndex
                                                    ][secondOptionIndex]
                                                      ?.optionMetaDataInfoList?.[
                                                      metaDataIndex
                                                    ]?.basicOptionExtra ===
                                                    '기타'
                                                  }
                                                  type='checkbox'
                                                  onChange={(e) => {
                                                    console.log(
                                                      `e.target.checked: ${e.target.checked}, `,
                                                    )
                                                    if (e.target.checked) {
                                                      setOptionsInfo({
                                                        ...optionsInfo,
                                                        optionPrices:
                                                          optionsInfo?.optionPrices?.map(
                                                            (
                                                              firstOptionTemp,
                                                              firstOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                firstOptionIndex ===
                                                                firstOptionIndexTemp
                                                              ) {
                                                                return firstOptionTemp.map(
                                                                  (
                                                                    secondOptionTemp,
                                                                    secondOptionIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      secondOptionIndex ===
                                                                      secondOptionIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...secondOptionTemp,
                                                                        optionMetaDataInfoList:
                                                                          secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                            (
                                                                              metaDataTemp,
                                                                              metaDataIndexTemp,
                                                                            ) => {
                                                                              if (
                                                                                metaDataIndex ===
                                                                                metaDataIndexTemp
                                                                              ) {
                                                                                return {
                                                                                  ...metaDataTemp,
                                                                                  basicOptionExtra:
                                                                                    '기타',
                                                                                  basicOption:
                                                                                    '',
                                                                                }
                                                                              }
                                                                              return metaDataTemp
                                                                            },
                                                                          ),
                                                                      }
                                                                    }
                                                                    return secondOptionTemp
                                                                  },
                                                                )
                                                              }
                                                              return firstOptionTemp
                                                            },
                                                          ),
                                                      })
                                                    }
                                                  }}
                                                />{' '}
                                                <span className='text-base'>
                                                  기타{' '}
                                                </span>{' '}
                                              </div>
                                            )}
                                            {metaData?.basicOptionExtra ===
                                              '기타' && (
                                              <input
                                                type='text'
                                                onChange={(e) => {
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return firstOptionTemp.map(
                                                              (
                                                                secondOptionTemp,
                                                                secondOptionIndexTemp,
                                                              ) => {
                                                                if (
                                                                  secondOptionIndex ===
                                                                  secondOptionIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...secondOptionTemp,
                                                                    optionMetaDataInfoList:
                                                                      secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                        (
                                                                          metaDataTemp,
                                                                          metaDataIndexTemp,
                                                                        ) => {
                                                                          if (
                                                                            metaDataIndex ===
                                                                            metaDataIndexTemp
                                                                          ) {
                                                                            return {
                                                                              ...metaDataTemp,
                                                                              basicOption:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          }
                                                                          return metaDataTemp
                                                                        },
                                                                      ),
                                                                  }
                                                                }
                                                                return secondOptionTemp
                                                              },
                                                            )
                                                          }
                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }}
                                              />
                                            )}
                                          </div>{' '}
                                        </td>
                                        <td>
                                          <div className='flex flex-row'>
                                            {metaData?.contentsUnits?.map(
                                              (contentsUnit) => {
                                                return (
                                                  <div key={contentsUnit}>
                                                    <input
                                                      checked={
                                                        optionsInfo
                                                          ?.optionPrices?.[
                                                          firstOptionIndex
                                                        ][secondOptionIndex]
                                                          ?.optionMetaDataInfoList?.[
                                                          metaDataIndex
                                                        ]?.contentsUnit ===
                                                        contentsUnit
                                                      }
                                                      type='checkbox'
                                                      onChange={(e) => {
                                                        console.log(
                                                          `e.target.checked: ${e.target.checked}, ${contentsUnit}`,
                                                        )
                                                        if (e.target.checked) {
                                                          setOptionsInfo({
                                                            ...optionsInfo,
                                                            optionPrices:
                                                              optionsInfo?.optionPrices?.map(
                                                                (
                                                                  firstOptionTemp,
                                                                  firstOptionIndexTemp,
                                                                ) => {
                                                                  if (
                                                                    firstOptionIndex ===
                                                                    firstOptionIndexTemp
                                                                  ) {
                                                                    return firstOptionTemp.map(
                                                                      (
                                                                        secondOptionTemp,
                                                                        secondOptionIndexTemp,
                                                                      ) => {
                                                                        if (
                                                                          secondOptionIndex ===
                                                                          secondOptionIndexTemp
                                                                        ) {
                                                                          return {
                                                                            ...secondOptionTemp,
                                                                            optionMetaDataInfoList:
                                                                              secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                                (
                                                                                  metaDataTemp,
                                                                                  metaDataIndexTemp,
                                                                                ) => {
                                                                                  if (
                                                                                    metaDataIndex ===
                                                                                    metaDataIndexTemp
                                                                                  ) {
                                                                                    delete metaDataTemp?.contentsUnitExtra
                                                                                    return {
                                                                                      ...metaDataTemp,
                                                                                      contentsUnit,
                                                                                    }
                                                                                  }
                                                                                  return metaDataTemp
                                                                                },
                                                                              ),
                                                                          }
                                                                        }
                                                                        return secondOptionTemp
                                                                      },
                                                                    )
                                                                  }
                                                                  return firstOptionTemp
                                                                },
                                                              ),
                                                          })
                                                        }
                                                      }}
                                                    />{' '}
                                                    <span className='mr-2 text-base'>
                                                      {contentsUnit}{' '}
                                                    </span>{' '}
                                                  </div>
                                                )
                                              },
                                            )}
                                            {metaData?.contentsUnits?.length >
                                              0 && (
                                              <div key='기타'>
                                                <input
                                                  checked={
                                                    optionsInfo?.optionPrices?.[
                                                      firstOptionIndex
                                                    ][secondOptionIndex]
                                                      ?.optionMetaDataInfoList?.[
                                                      metaDataIndex
                                                    ]?.contentsUnitExtra ===
                                                    '기타'
                                                  }
                                                  type='checkbox'
                                                  onChange={(e) => {
                                                    console.log(
                                                      `e.target.checked: ${e.target.checked}, `,
                                                    )
                                                    if (e.target.checked) {
                                                      setOptionsInfo({
                                                        ...optionsInfo,
                                                        optionPrices:
                                                          optionsInfo?.optionPrices?.map(
                                                            (
                                                              firstOptionTemp,
                                                              firstOptionIndexTemp,
                                                            ) => {
                                                              if (
                                                                firstOptionIndex ===
                                                                firstOptionIndexTemp
                                                              ) {
                                                                return firstOptionTemp.map(
                                                                  (
                                                                    secondOptionTemp,
                                                                    secondOptionIndexTemp,
                                                                  ) => {
                                                                    if (
                                                                      secondOptionIndex ===
                                                                      secondOptionIndexTemp
                                                                    ) {
                                                                      return {
                                                                        ...secondOptionTemp,
                                                                        optionMetaDataInfoList:
                                                                          secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                            (
                                                                              metaDataTemp,
                                                                              metaDataIndexTemp,
                                                                            ) => {
                                                                              if (
                                                                                metaDataIndex ===
                                                                                metaDataIndexTemp
                                                                              ) {
                                                                                return {
                                                                                  ...metaDataTemp,
                                                                                  contentsUnitExtra:
                                                                                    '기타',
                                                                                  contentsUnit:
                                                                                    '',
                                                                                }
                                                                              }
                                                                              return metaDataTemp
                                                                            },
                                                                          ),
                                                                      }
                                                                    }
                                                                    return secondOptionTemp
                                                                  },
                                                                )
                                                              }
                                                              return firstOptionTemp
                                                            },
                                                          ),
                                                      })
                                                    }
                                                  }}
                                                />{' '}
                                                <span className='text-base'>
                                                  기타{' '}
                                                </span>{' '}
                                              </div>
                                            )}
                                            {metaData?.contentsUnitExtra ===
                                              '기타' && (
                                              <input
                                                type='text'
                                                onChange={(e) => {
                                                  setOptionsInfo({
                                                    ...optionsInfo,
                                                    optionPrices:
                                                      optionsInfo?.optionPrices?.map(
                                                        (
                                                          firstOptionTemp,
                                                          firstOptionIndexTemp,
                                                        ) => {
                                                          if (
                                                            firstOptionIndex ===
                                                            firstOptionIndexTemp
                                                          ) {
                                                            return firstOptionTemp.map(
                                                              (
                                                                secondOptionTemp,
                                                                secondOptionIndexTemp,
                                                              ) => {
                                                                if (
                                                                  secondOptionIndex ===
                                                                  secondOptionIndexTemp
                                                                ) {
                                                                  return {
                                                                    ...secondOptionTemp,
                                                                    optionMetaDataInfoList:
                                                                      secondOptionTemp?.optionMetaDataInfoList?.map(
                                                                        (
                                                                          metaDataTemp,
                                                                          metaDataIndexTemp,
                                                                        ) => {
                                                                          if (
                                                                            metaDataIndex ===
                                                                            metaDataIndexTemp
                                                                          ) {
                                                                            return {
                                                                              ...metaDataTemp,
                                                                              contentsUnit:
                                                                                e
                                                                                  .target
                                                                                  .value,
                                                                            }
                                                                          }
                                                                          return metaDataTemp
                                                                        },
                                                                      ),
                                                                  }
                                                                }
                                                                return secondOptionTemp
                                                              },
                                                            )
                                                          }
                                                          return firstOptionTemp
                                                        },
                                                      ),
                                                  })
                                                }}
                                              />
                                            )}
                                          </div>{' '}
                                        </td>
                                      </tr>
                                    ),
                                  )}
                                </td>
                              ),
                            )}
                          </tr>
                        ),
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <table className='mb-10'>
                <thead className='text-lg font-bold'>
                  <tr>
                    <th>항목</th>
                    <th>상품 기본 정보</th>
                  </tr>
                </thead>
                <tbody>
                  {productMetaDataList?.map((metaData, index) => (
                    <tr key={metaData.title + index.toString()}>
                      <td style={{ fontSize: 15 }}>
                        <div> {metaData.title} </div>
                      </td>
                      <td className='py-3'>
                        {(!metaData?.basicOptions ||
                          metaData?.basicOptions?.length === 0) && (
                          <Input
                            className='w-4/5'
                            type='text'
                            placeholder={metaData.placeholder}
                            value={metaData.contents}
                            onChange={(e) => {
                              setProductMetaDataList(
                                productMetaDataList?.map((el) => {
                                  if (metaData?.title === el?.title) {
                                    return { ...el, contents: e.target.value }
                                  }
                                  return { ...el }
                                }),
                              )
                              const invalidDetailRegex =
                                /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                              if (invalidDetailRegex?.test(e.target.value)) {
                                alert('세부적인 내용을 입력해주세요')
                                setProductMetaDataList(
                                  productMetaDataList?.map((el) => {
                                    if (metaData?.title === el?.title) {
                                      return {
                                        ...el,
                                        contents: e.target.value?.replace(
                                          invalidDetailRegex,
                                          '',
                                        ),
                                      }
                                    }
                                    return { ...el }
                                  }),
                                )
                              }
                            }}
                          />
                        )}
                        <div className='flex flex-row'>
                          {metaData?.basicOptions?.map((basicOption) => {
                            return (
                              <div key={basicOption}>
                                <input
                                  checked={
                                    metaData?.basicOption === basicOption
                                  }
                                  type='checkbox'
                                  onChange={(e) => {
                                    console.log(
                                      `상품정보요기요기: ${e.target.checked}`,
                                    )
                                    if (e.target.checked) {
                                      setProductMetaDataList(
                                        productMetaDataList?.map((el) => {
                                          if (metaData?.title === el?.title) {
                                            delete metaData?.basicOptionExtra
                                            return {
                                              ...el,
                                              basicOption,
                                            }
                                          }
                                          return { ...el }
                                        }),
                                      )
                                    }
                                  }}
                                />{' '}
                                <span className='mr-2 text-base'>
                                  {basicOption}{' '}
                                </span>{' '}
                              </div>
                            )
                          })}
                          {metaData?.basicOptions?.length > 0 && (
                            <div key='기타'>
                              <input
                                checked={metaData?.basicOptionExtra === '기타'}
                                type='checkbox'
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setProductMetaDataList(
                                      productMetaDataList?.map((el) => {
                                        if (metaData?.title === el?.title) {
                                          return {
                                            ...el,
                                            basicOptionExtra: '기타',
                                            basicOption: '',
                                          }
                                        }
                                        return { ...el }
                                      }),
                                    )
                                  }
                                }}
                              />{' '}
                              <span className='text-base'>기타 </span>{' '}
                            </div>
                          )}
                          {metaData?.basicOptionExtra === '기타' && (
                            <input
                              type='text'
                              onChange={(e) => {
                                setProductMetaDataList(
                                  productMetaDataList?.map((el) => {
                                    if (metaData?.title === el?.title) {
                                      return {
                                        ...el,
                                        basicOption: e.target.value,
                                      }
                                    }
                                    return { ...el }
                                  }),
                                )
                              }}
                            />
                          )}
                        </div>{' '}
                      </td>
                    </tr>
                  ))}
                  {commonMetaDataList?.map((metaData, index) => (
                    <tr key={metaData.title + index.toString()}>
                      <td style={{ fontSize: 15 }}>
                        <div> {metaData.title} </div>
                      </td>

                      <td className='py-3'>
                        <Input
                          className='w-4/5'
                          type='text'
                          placeholder={metaData.placeholder}
                          value={metaData.contents}
                          onChange={(e) => {
                            setCommonMetaDataList(
                              commonMetaDataList?.map((el) => {
                                if (metaData?.title === el?.title) {
                                  return { ...el, contents: e.target.value }
                                }
                                return { ...el }
                              }),
                            )
                            const invalidDetailRegex =
                              /(상세|페이지|참고|참조|상세페이지참고|상세페이지참조|별도|표기|기재|기입|이미지)/
                            if (invalidDetailRegex?.test(e.target.value)) {
                              alert('세부적인 내용을 입력해주세요')
                              setCommonMetaDataList(
                                commonMetaDataList?.map((el) => {
                                  if (metaData?.title === el?.title) {
                                    return {
                                      ...el,
                                      contents: e.target.value?.replace(
                                        invalidDetailRegex,
                                        '',
                                      ),
                                    }
                                  }
                                  return { ...el }
                                }),
                              )
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
          <Card title='택배사 정보' necessary>
            <div className='flex flex-col items-start flex-1 w-full '>
              <SubTitle className='mb-5'>택배사 정보를 입력해 주세요</SubTitle>
              <div className='flex flex-col flex-1'>
                <Description className='flex-col mb-3 mr-3'>
                  <div>택배사</div>
                  <select
                    className='py-1 border-2 border-sub-300 w-80'
                    id='shipping_company_item'
                    name='shipping_company_item'
                    onChange={(e) => {
                      const correspondingShippingCompanyInfo =
                        OrderStore.shippingCompaniesInfo.find(
                          (shippingCompanyInfo) =>
                            shippingCompanyInfo.shippingCompanyName ===
                            e.target.value,
                        )
                      if (correspondingShippingCompanyInfo) {
                        setShippingInfo({
                          ...shippingInfo,
                          shippingCompany:
                            correspondingShippingCompanyInfo.shippingCompanyName,
                        })

                        if (
                          ItemStore.itemInfoOnEdit.itemTitle &&
                          optionsInfo.optionNames.every((name) => name) &&
                          optionsInfo.totalOptions.every((optionsArray) =>
                            optionsArray.every((option) => option.name),
                          )
                        ) {
                          let optionPrices = [...optionsInfo.optionPrices]
                          let finalOptionPrices = [...optionsInfo.optionPrices]
                          let testOption = optionPrices

                          while (testOption.length) {
                            finalOptionPrices = []
                            optionPrices.forEach((option) => {
                              finalOptionPrices =
                                finalOptionPrices.concat(option)
                            })
                            optionPrices = [...finalOptionPrices]
                            testOption = finalOptionPrices[0]
                          }
                          const soldOutResult = finalOptionPrices.every(
                            (option) => option.sold_out,
                          )
                          const teamPurchasePriceResult =
                            finalOptionPrices.some(
                              (option) => !option.teamPurchasePrice,
                            )
                          const individualPurchasePriceResult =
                            finalOptionPrices.some(
                              (option) => !option.individualPurchasePrice,
                            )
                          if (
                            soldOutResult ||
                            teamPurchasePriceResult ||
                            individualPurchasePriceResult
                          ) {
                            setButtonAvailable(false)
                            return
                          }
                          setButtonAvailable(true)
                        }
                      }
                    }}
                    value={shippingInfo.shippingCompany || ''}
                  >
                    <option value=''>===</option>
                    {OrderStore.shippingCompaniesInfo.map(
                      (shippingCompanyInfo) => (
                        <option
                          key={shippingCompanyInfo.shippingCompanyCode}
                          value={shippingCompanyInfo.shippingCompanyName}
                        >
                          {shippingCompanyInfo.shippingCompanyName}
                        </option>
                      ),
                    )}
                  </select>
                </Description>
                <Description className='flex-col mb-3 mr-3'>
                  <div className='mt-5'>배송 소요 시일</div>

                  <select
                    className='py-1 border-2 border-sub-300 w-80'
                    type='number'
                    value={shippingInfo.shippingDays?.toString() || ''}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setShippingInfo({
                          ...shippingInfo,
                          shippingDays: parseInt(e.target.value, 10),
                        })
                      } else {
                        setShippingInfo({
                          ...shippingInfo,
                          shippingDays: undefined,
                        })
                      }
                    }}
                  >
                    <option value=''>===</option>
                    <option value='1'>1일</option>
                    <option value='2'>2일</option>
                    <option value='3'>3일</option>
                    <option value='4'>4일</option>
                    <option value='5'>5일</option>
                    <option value='6'>6일</option>
                    <option value='7'>7일</option>
                    <option value='8'>8일</option>
                    <option value='9'>9일</option>
                    <option value='10'>10일</option>
                    <option value='11'>11일</option>
                    <option value='12'>12일</option>
                    <option value='13'>13일</option>
                    <option value='14'>14일</option>
                    <option value='15'>15일</option>
                  </select>
                </Description>
                <div className='flex-col w-full mt-5 mb-10'>
                  <div>배송비용 안내 문구</div>
                  <textarea
                    className='py-1 border-2 w-80 border-sub-300'
                    type='text'
                    placeholder='무료배송, 도서산간 추가 배송비 -제주 지역: 3,000원 -도서산간 지역: 5,000원'
                    value={shippingInfo.shippingFeeInfo}
                    onChange={(e) => {
                      if (e.target.value !== '') {
                        setShippingInfo({
                          ...shippingInfo,
                          shippingFeeInfo: Format.Title(
                            e.target.value.normalize('NFC'),
                          ),
                        })
                      }
                    }}
                  />
                </div>
              </div>
              <div>
                <div className='mb-5'>
                  <SubTitle className='mb-5 '>배송비를 입력해주세요</SubTitle>
                  <span>
                    올웨이즈의 모든 제품은{' '}
                    <b className='mb-5 text-red-500'>기본 배송비 무료</b>로
                    판매됩니다.
                    <br />
                    <b className='mb-5 text-red-500'>
                      도서산간 지역별 추가 배송비
                    </b>
                    를 설정해주세요.
                  </span>
                </div>
                <div>
                  <div className='mb-5'>
                    <div>도서산간 배송비(제주 외)</div>
                    <input
                      className='py-1 border-2 w-80 border-sub-300'
                      type='number'
                      value={nonJejuFee}
                      onChange={(e) => {
                        setNonJejuFee(e.target.value)
                        setShippingInfo({
                          ...shippingInfo,
                          ruralAreaShippingFeeInfo:
                            shippingInfo.ruralAreaShippingFeeInfo?.map(
                              (shippingInfoTemp, index) => {
                                if (index < 34) {
                                  return {
                                    ...shippingInfoTemp,
                                    shippingFee: parseInt(e.target.value, 10),
                                  }
                                }
                                return shippingInfoTemp
                              },
                            ),
                        })
                      }}
                    />
                  </div>
                  <div className='mb-5'>
                    <div>도서산간 배송비(제주)</div>
                    <input
                      className='py-1 border-2 w-80 border-sub-300'
                      type='number'
                      value={jejuFee}
                      onChange={(e) => {
                        setJejuFee(e.target.value)
                        setShippingInfo({
                          ...shippingInfo,
                          ruralAreaShippingFeeInfo:
                            shippingInfo.ruralAreaShippingFeeInfo?.map(
                              (shippingInfoTemp, index) => {
                                if (index >= 34) {
                                  return {
                                    ...shippingInfoTemp,
                                    shippingFee: parseInt(e.target.value, 10),
                                  }
                                }
                                return shippingInfoTemp
                              },
                            ),
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='mb-5 '>
                <div>단순 변심시 반품 비용(일괄 적용)</div>
                <Input
                  className='w-80'
                  type='number'
                  placeholder={5000}
                  value={shippingInfo.returnFee}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setShippingInfo({
                        ...shippingInfo,
                        returnFee: parseInt(e.target.value, 10) || undefined,
                      })
                    }
                  }}
                />
              </div>
              <Description className='w-1/2 mb-5'>
                <div>반품 비용 안내 문구</div>
                <textarea
                  className='py-1 border-2 w-80 border-sub-300'
                  type='text'
                  placeholder={`${
                    shippingInfo.returnFee || 5000
                  } -단, 고객 단순 변심의 경우에만 발생 -도서산간 및 일부 지역 추가비용 발생`}
                  value={shippingInfo.returnFeeInfo}
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setShippingInfo({
                        ...shippingInfo,
                        returnFeeInfo: Format.Title(
                          e.target.value.normalize('NFC'),
                        ),
                      })
                    }
                  }}
                />
              </Description>
            </div>
          </Card>
          <Card title='대표이미지' necessary>
            <div>
              <div className='flex items-center mb-5 '>
                <SubTitle>대표 이미지를 등록해 주세요</SubTitle>
                <TutorialModal
                  modalTitle='대표이미지'
                  videoSource={Tutorial6}
                  modalLog='Tutorial6'
                >
                  <AlwayzQuestion />
                </TutorialModal>
              </div>
              <div className='mt-5 text-base'>
                <span style={{ color: 'red' }} className='font-bold'>
                  대표이미지
                </span>
                는 매출에 많은 영향을 미칩니다. 아래 예시를 잘 확인하시어,
                매출을 향상해 보세요!
              </div>
              <div className='mt-2 ml-3'>
                <span style={{ color: 'red' }} className='font-bold'>
                  1. 나쁜 이미지 예시로 업로드될 시 품절 처리되니 꼭 유의
                  바랍니다
                </span>
                <div className='mt-2'>
                  2. 800px*800px 이상, 1200px*1200px 권장
                </div>
                <div className='mt-2'>
                  3. 단일 이미지 최대 5MB, jpg/ jpeg/ png 허용
                </div>
                <div className='mt-2'>
                  4. 상품이 대표이미지의 85% 이상 차지 권장
                </div>
                <div className='mt-2'>
                  5. 묶음상품일 경우, 개별 단품 이미지와 전체 수량 (박스, 팩 등)
                  표현
                </div>
                <div className='mt-2'>5. 흰색(RGB 255,255,255) 배경 권장</div>
                <div className='mt-2'>
                  6. 과도한 글귀, 도형, 워터마크, 배경 권장하지 않음
                </div>
              </div>
              <div className='ml-2'>
                <h3 className='mt-5 mb-1 text-lg'>〈좋은 이미지 예시〉</h3>
                <img
                  alt='fashionGood'
                  src={fashionGoodEx}
                  className='flex w-4/5 mx-5 '
                />
              </div>
              <div className='ml-2'>
                <h3 className='mt-2 mb-1 text-lg'>〈나쁜 이미지 예시〉</h3>
                <img
                  alt='fashionBad'
                  src={fashionBadEx}
                  className='flex w-4/5 mx-5 '
                />
              </div>
            </div>
            <div className='flex flex-col flex-1 mt-10'>
              <div>
                <ImageUploading
                  multiple
                  value={mainImagesData}
                  onChange={(imageList, addUpdateIndex) => {
                    console.log(`addupdate index: ${addUpdateIndex}`)
                    setMainImagesData(imageList)
                    const croppedMainImagesDataTemp = JSON.parse(
                      JSON.stringify(croppedMainImagesData),
                    )
                    if (addUpdateIndex > croppedMainImagesData.length) {
                      croppedMainImagesDataTemp.push({ sourceUri: '' })
                      setCroppedMainImagesData(croppedMainImagesDataTemp)
                    } else {
                      croppedMainImagesDataTemp[addUpdateIndex] = {
                        sourceUri: '',
                      }
                      setCroppedMainImagesData(croppedMainImagesDataTemp)
                    }
                  }}
                  maxFileSize={5e6}
                  acceptType={['jpg', 'png', 'jpeg']}
                  resolutionType='more'
                  resolutionWidth={800}
                  resolutionHeight={800}
                  onError={(e) => {
                    if (e.maxFileSize) {
                      alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                    } else if (e.acceptType) {
                      alert(
                        '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                      )
                    } else if (e.resolution) {
                      alert(
                        '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                      )
                    }
                  }}
                  allowNonImageType='false'
                  maxNumber={10}
                  dataURLKey='sourceUri'
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <div {...dragProps}>
                      <Button
                        type='button'
                        onClick={onImageUpload}
                        appearance='neutralSub'
                      >
                        사진 추가하기
                      </Button>
                      <div>
                        <div className='flex items-center justify-center h-20 my-5 bg-gray-100 rounded-md shadow-md w-96'>
                          {isDragging ? (
                            <>
                              <FcOpenedFolder className='w-5 h-5 mr-1' />
                              <span>사진을 놓으시면 됩니다.</span>
                            </>
                          ) : (
                            <>
                              <FcFolder className='w-5 h-5 mr-1' />
                              <span>
                                전체컷과, 상세컷 총 2장 이상의 이미지를
                                올려주세요.
                              </span>
                            </>
                          )}
                        </div>
                        <div className='flex flex-row overflow-x-auto '>
                          {imageList.map((image, index) => (
                            <div key={index.toString()}>
                              <div className='flex mr-10 w-80 h-80'>
                                <img
                                  alt='img'
                                  src={image.sourceUri}
                                  className='object-cover object-center border-2 shadow-lg h-80 w-80 border-sub-300'
                                />
                              </div>
                              <div>
                                <button
                                  className='px-2 py-2 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                  type='button'
                                  onClick={() => onImageUpdate(index)}
                                >
                                  사진변경
                                </button>
                                <button
                                  className='px-2 py-2 mx-10 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                  type='button'
                                  onClick={() => {
                                    onImageRemove(index)
                                  }}
                                >
                                  삭제
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </Card>

          <Card title='상세페이지' necessary>
            <div>
              <div className='flex items-center mb-5'>
                <SubTitle>상세페이지 이미지를 등록해 주세요</SubTitle>
                <TutorialModal modalTitle='상세페이지' videoSource={Tutorial7}>
                  <AlwayzQuestion />
                </TutorialModal>
              </div>
              <div className='ml-3 text-base'>
                <div className='mb-2'>
                  1. 단일 이미지 최대 12MB, jpg/ jpeg/ png/ gif 허용
                </div>
                <div className='mb-2'>
                  2. (패션)시착,사이즈,소재,세탁방법 /품질인증서/성분표/특허사항
                  등 포함
                </div>
                <div className='mb-2'>
                  3. 실 상품과 다르거나, 정확하지 않은 정보로 구매자에게 혼란을
                  주는 서술은 금지함
                </div>
                <div className='mb-2'>
                  4. 본인에게 저작권이 없는 이미지 사용시 발생하는 모든 책임은
                  사용자에게 있음
                </div>
                <img
                  alt='Detail'
                  src={fashionDetail}
                  className='flex w-4/5 mb-2'
                />
              </div>
              <div style={{ width: 500 }}>
                <ImageUploading
                  multiple
                  value={detailImagesData}
                  onChange={(imageList, addUpdateIndex) => {
                    setDetailImagesData(imageList)
                  }}
                  maxFileSize={12e6}
                  acceptType={['jpg', 'png', 'jpeg', 'gif']}
                  maxNumber={200}
                  dataURLKey='sourceUri'
                  onError={(e) => {
                    if (e.maxFileSize) {
                      alert('이미지 파일 용량을 확인해주세요.(개당 최대 12MB)')
                    } else if (e.acceptType) {
                      alert(
                        '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg /jpeg /png /gif)',
                      )
                    }
                  }}
                >
                  {({
                    imageList,
                    onImageUpload,

                    isDragging,
                    dragProps,
                  }) => (
                    <div
                      className='upload__image-wrapper'
                      style={{ width: 500 }}
                      {...dragProps}
                    >
                      <Button onClick={onImageUpload} appearance='neutralSub'>
                        사진 추가하기
                      </Button>
                      <div>
                        <div className='flex items-center justify-center h-20 my-5 bg-gray-100 rounded-md shadow-md w-96'>
                          {isDragging ? (
                            <>
                              <FcOpenedFolder className='w-5 h-5 mr-1' />
                              <span>사진을 놓으시면 됩니다.</span>
                            </>
                          ) : (
                            <>
                              <FcFolder className='w-5 h-5 mr-1' />
                              <span>사진을 끌어다 놓으실 수도 있어요.</span>
                            </>
                          )}
                        </div>
                        {imageList.map((image, index) => (
                          <DetailImageUpdateComponent
                            image={image}
                            index={index}
                            detailImagesData={detailImagesData}
                            setDetailImagesData={setDetailImagesData}
                            key={image.toString() + index.toString()}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </Card>

          <Card title='상품 키워드' necessary>
            <div>
              <div className='flex items-center mb-5 '>
                <SubTitle>상품 키워드를 입력해주세요</SubTitle>
                <TutorialModal modalTitle='상품 키워드' videoSource={Tutorial8}>
                  <AlwayzQuestion />
                </TutorialModal>
              </div>

              <b className='text-red-500'>
                *검색 결과에 노출되도록 5개 이하 입력해주세요
              </b>
              <div>
                <Tag text={ItemStore?.itemInfoOnEdit?.itemTitle} />
                <Tag text={largeCategoryInfo?.largeCategoryNames?.kr} />
                <Tag text={mediumCategoryInfo?.mediumCategoryNames?.kr} />
                <Tag text={smallCategoryInfo?.smallCategoryNames?.kr} />
                <Tag text={trendSellerInfo?.brandName} />

                {keyWords.map((each, i) => (
                  <Tag
                    key={`keyword${encodeURI(each)}`}
                    text={each}
                    onClick={() => {
                      setKeyWords(keyWords.filter((v, idx) => i !== idx))
                    }}
                  />
                ))}
                {keyWords?.length < 5 && (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      const keyword = Format.Title(
                        e.target[0].value.normalize('NFC'),
                      )
                      if (!keyword) alert('키워드를 입력해주세요.')
                      if (keyWords.indexOf(keyword) >= 0)
                        alert('이미 추가한 키워드입니다.')
                      else if (keyword)
                        setKeyWords((prev) => [...prev, keyword])
                      e.target[0].value = ''
                    }}
                  >
                    <Tooltip
                      arrow='true'
                      theme='dark'
                      title='💡 연관도가 높은 검색어를 입력하실 경우 노출도 보너스를 받을 수 있어요!'
                      animation='fade'
                      position='bottom'
                    >
                      <input
                        className='w-1/2 py-1 border-2 border-sub-300'
                        name='keyword'
                        type='text'
                      />
                    </Tooltip>
                    <input
                      className='px-2 py-1 ml-2 text-base font-bold text-white border-b-2 border-r-2 rounded-lg shadow-md cursor-pointer border-neutral-700 bg-neutral-500 hover:bg-neutral-700'
                      type='submit'
                      value='추가'
                    />
                  </form>
                )}
              </div>
              <div className='mt-5 '>1인 최대 구매 제한 개수</div>
              <Input
                className='w-1/2'
                disabled={!AuthStore.isMasterAccount}
                type='number'
                placeholder='10'
                value={ItemStore.itemInfoOnEdit.purchaseLimits || '10'}
                onChange={(e) => {
                  if (AuthStore.isMasterAccount) {
                    ItemStore.setItemInfoOnEdit({
                      ...ItemStore.itemInfoOnEdit,
                      purchaseLimits: parseInt(e.target.value, 10) || 10,
                    })
                  }
                }}
              />

              <div className='mt-5 '>팀구매 인원</div>
              <Input
                className='w-1/2'
                disabled={!AuthStore.isMasterAccount}
                type='number'
                placeholder='2'
                value={ItemStore.itemInfoOnEdit.teamDealThreshold || '2'}
                onChange={(e) => {
                  if (AuthStore.isMasterAccount) {
                    ItemStore.setItemInfoOnEdit({
                      ...ItemStore.itemInfoOnEdit,
                      teamDealThreshold: parseInt(e.target.value, 2) || 2,
                    })
                  }
                }}
              />
            </div>
          </Card>
          <Card title='상품 추천 코드'>
            <div>
              <div className='flex items-center mb-5 '>
                <SubTitle>상품 추천 코드를 입력해주세요</SubTitle>
              </div>

              <div className='mt-5 '>상품 추천 코드</div>
              <input
                className='w-full px-2 py-2 my-3 bg-white border-2 rounded-md border-sub-500'
                placeholder='상품 추천 코드를 입력해주세요'
                value={recommendItemCode}
                onChange={(e) => {
                  setRecommendItemCode(e.target.value)
                }}
              />
            </div>
          </Card>
          <div className='flex flex-row justify-between flex-1 mt-4 mb-48'>
            {hasTempStoredData ? (
              <Button
                size='xxl'
                className='px-4 mr-4'
                type='button'
                appearance='positive'
                disabled={SellerStore?.isLoading}
                onClick={handleLoad}
              >
                불러 오기
              </Button>
            ) : (
              <div />
            )}
            <div>
              <Button
                size='xxl'
                className='px-4 mr-4'
                type='button'
                disabled={SellerStore?.isLoading}
                onClick={handleSave}
              >
                임시 저장
              </Button>
              {isNecessaryDataFulfilled ? (
                <Button
                  size='xxl'
                  className='px-4'
                  type='button'
                  onClick={handleRegister}
                >
                  상품 등록 완료
                </Button>
              ) : (
                <Button
                  size='xxl'
                  className='px-4'
                  type='button'
                  appearance='disabled'
                  onClick={handleRegister}
                >
                  상품 등록 완료
                </Button>
              )}
            </div>
          </div>
        </div>
      </Page>
    </div>
  )
})

export default RegisterTrendFashionItemScreen
