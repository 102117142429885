import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import Button from '../../components/atoms/button'
import { GrClose as CloseIcon } from 'react-icons/gr'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import backendApis from '../../utils/backendApis'
import Table from '../../components/ADS/molecules/table'
import Pagination from '../../components/molecules/pagination'
import RankIconSetter from '../../components/molecules/rankIconSetter'
import Tooltip from '../../components/ADS/atoms/tooltip'
import commaNumber from 'comma-number'
import ListLimitSelector from '../../components/atoms/listLimitSelector'
import SellerQuest from '../../utils/sellerQuest'

const CpcAdBatchApplyModal = observer(({ showModal, setShowModal }) => {
  const [creditInfo, setCreditInfo] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [itemsInfo, setItemsInfo] = useState([])
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const tab = 1
  const [rankStatusTab, setRankStatusTab] = useState('All')
  const cleanUp = () => {
    setPage(1)
    setLimit(20)
    setItemsInfo([])
    setItemsCount(0)
    setIsAllCheckBoxChecked(false)
    setCheckedItemIds([])
    setRankStatusTab('All')
  }

  const fetchSegmentedItemsInfo = async (isNewCondition, rankStatusTab) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)

    const itemCondition = {
      soldOut: false,
      outOfStock: { $ne: true },
      itemRankTestingSku: { $ne: true },
      rankInfo: { $ne: null },
      teamPurchasePrice: { $gt: 100 },
      $or: [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: { key: 'ad', value: adSettingByTab?.[tab].type },
            },
          },
        },
      ],
    }

    if (rankStatusTab !== 'All') {
      delete itemCondition.$or
      itemCondition.$and = [
        {
          itemFilters: {
            $elemMatch: { key: 'itemRank', value: rankStatusTab },
          },
        },
        {
          itemFilters: {
            $not: {
              $elemMatch: { key: 'ad', value: adSettingByTab?.[tab].type },
            },
          },
        },
      ]
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      setPage(1)
      setItemsInfo([])
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo?.filter((item) => item?.itemTitle))
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    if (!showModal) return
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false, rankStatusTab)
    } else {
      fetchSegmentedItemsInfo(true, rankStatusTab)
    }
  }, [showModal, limit, page, rankStatusTab])

  const columns = [
    {
      Header: (
        <div className='select-none'>
          <input
            type='checkbox'
            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 '
            checked={isAllCheckBoxChecked}
            onChange={(e) => {
              if (e.target.checked) {
                setCheckedItemIds(itemsInfo?.map((itemInfo) => itemInfo?._id))
                setIsAllCheckBoxChecked(true)
              } else {
                setCheckedItemIds([])
                setIsAllCheckBoxChecked(false)
              }
            }}
          />
        </div>
      ),
      accessor: 'status',
    },
    {
      Header: '상품명',
      accessor: 'itemTitle',
    },
    {
      Header: '등급',
      accessor: 'itemRank',
    },
    {
      Header: '팀 구매가',
      accessor: 'teamPurchasePrice',
    },
    {
      Header: (
        <>
          추천 기본 입찰가
          <Tooltip
            text='파워 퍼포먼스 광고(CPC)는 노출수와 상관없이 실제 광고를 클릭했을 때만 광고비가 차감됩니다. 
          기본 입찰가는 연관된 검색 결과 및 비검색 화면 노출 입찰에 활용됩니다.'
          />
        </>
      ),
      accessor: 'basicBiddingPrice',
    },
  ]

  const adData = useMemo(
    () =>
      itemsInfo.map((item) => {
        return {
          status: (
            <div className='select-none'>
              <input
                type='checkbox'
                className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 '
                checked={checkedItemIds?.includes(item?._id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckedItemIds([
                      ...checkedItemIds?.filter(
                        (itemId) => itemId !== item?._id,
                      ),
                      item?._id,
                    ])
                  } else {
                    setCheckedItemIds(
                      checkedItemIds?.filter((itemId) => itemId !== item?._id),
                    )
                  }
                }}
              />
            </div>
          ),
          itemTitle: (
            <>
              {item?.itemTitle.slice(0, 24)}
              {item?.itemTitle.length > 24 && '...'}
            </>
          ),
          itemRank: (
            <div className='flex items-center justify-start flex-1'>
              {item?.rankInfo && (
                <RankIconSetter
                  itemRank={item?.rankInfo?.itemRank}
                  className='w-4 h-4 mx-2'
                />
              )}
            </div>
          ),
          teamPurchasePrice: `${commaNumber(item?.teamPurchasePrice)}원`,
          basicBiddingPrice: `${90}원`,
        }
      }),
    [checkedItemIds, itemsInfo, isAllCheckBoxChecked],
  )

  useEffect(() => {
    const getAdCreditInfo = async () => {
      const result = await backendApis.getAdCreditInfo()
      if (result?.status === 2000) {
        setCreditInfo(result?.data)
      }
    }
    getAdCreditInfo()
  }, [])

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex flex-col w-full max-w-6xl max-h-screen mx-auto my-auto overflow-y-scroll bg-white border-0 rounded-lg shadow-lg outline-none h-fit'>
            {/* header */}
            <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
              <h3 className='text-xl font-semibold text-black'>
                파워 퍼포먼스 광고(CPC) 대량 등록
              </h3>
              <Button
                appearance='transparent'
                className='py-3 px-3 my-[-6px] rounded-md hover:bg-gray-100'
                onClick={() => {
                  cleanUp()
                  setShowModal(false)
                  SellerStore.setIsLoading(false)
                }}
              >
                <CloseIcon className='w-4 h-4 ' />
              </Button>
            </div>

            {/* body */}
            <div className='flex flex-col px-10 py-5'>
              <div className='flex flex-col items-start flex-1 p-5 mb-4 bg-slate-100 rounded-xl'>
                파워 퍼포먼스 광고(CPC) 신청이 가능한 상품들을 선택해 한꺼번에
                신청하세요.
                <div className='text-sm text-gray-500'>
                  신청한 상품은 추천 기본 입찰가, 추천 키워드 및 추천 입찰가가
                  자동 설정되며, 이후 수정이 가능합니다.
                </div>
                <div className='text-sm text-gray-500'>
                  등급 배정 중인 상품은 등급 배정 후 광고 등록 가능합니다.
                </div>
              </div>

              <div className='flex flex-row justify-between flex-1 mt-4'>
                <div className='flex items-center text-sm font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-lg w-min sm:flex'>
                  <button
                    type='button'
                    className={`inline-block py-3 px-6 rounded-lg whitespace-nowrap ${
                      rankStatusTab === 'All'
                        ? 'text-gray-900 active shadow-md bg-white'
                        : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                    }`}
                    onClick={() => setRankStatusTab('All')}
                  >
                    전체
                  </button>
                  <button
                    type='button'
                    className={`inline-block py-3 px-4 rounded-lg whitespace-nowrap ${
                      rankStatusTab === 'S'
                        ? 'text-gray-900 active shadow-md bg-white'
                        : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                    }`}
                    onClick={() => setRankStatusTab('S')}
                  >
                    S등급 상품
                  </button>
                  <button
                    type='button'
                    className={`inline-block py-3 px-4 rounded-lg whitespace-nowrap 
                    ${
                      rankStatusTab === 'A'
                        ? 'text-gray-900 active shadow-md bg-white'
                        : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                    }`}
                    onClick={() => setRankStatusTab('A')}
                  >
                    A등급 상품
                  </button>
                  <button
                    type='button'
                    className={`inline-block py-3 px-4 rounded-lg whitespace-nowrap 
                    ${
                      rankStatusTab === 'B'
                        ? 'text-gray-900 active shadow-md bg-white'
                        : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                    }`}
                    onClick={() => setRankStatusTab('B')}
                  >
                    B등급 상품
                  </button>
                  <button
                    type='button'
                    className={`inline-block py-3 px-4 rounded-lg whitespace-nowrap 
                    ${
                      rankStatusTab === 'C'
                        ? 'text-gray-900 active shadow-md bg-white'
                        : 'hover:text-gray-900 hover:bg-gray-200 text-gray-400'
                    }`}
                    onClick={() => setRankStatusTab('C')}
                  >
                    C등급 상품
                  </button>
                </div>
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div>

              <div>
                <div className='mt-2 p-0.5 overflow-y-scroll overflow-x-none max-h-[42rem]'>
                  <Table columns={columns} data={adData} />
                </div>
                <Pagination
                  total={itemsCount}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  size='lg'
                />
              </div>
            </div>

            {/* footer */}
            <div className='flex items-center justify-end p-5 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
              <Button
                appearance={
                  checkedItemIds?.length > 0 &&
                  (creditInfo?.adCreditInfo?.paidCredit || 0) +
                    (creditInfo?.adCreditInfo?.supportCredit || 0) >
                    0
                    ? 'positive'
                    : 'disabled'
                }
                disabled={
                  checkedItemIds?.length === 0 ||
                  (creditInfo?.adCreditInfo?.paidCredit || 0) +
                    (creditInfo?.adCreditInfo?.supportCredit || 0) <=
                    0
                }
                size='lg'
                onClick={async () => {
                  if (SellerStore.isLoading === true) return
                  SellerStore.setIsLoading(true)

                  const checkedItemInfo = itemsInfo.filter((item) => {
                    return checkedItemIds?.includes(item?._id)
                  })
                  const adItemsInfo = []
                  checkedItemInfo.forEach((item) =>
                    adItemsInfo.push({
                      adItemId: item?._id,
                      adItemTitle: item?.itemTitle,
                      adCategoryId: item?.categoryInfo?.smallCategoryId,
                      rankInfo: item?.rankInfo,
                      itemIndex: item?.itemIndex,
                      itemTotalRankScore: item?.rankInfo?.totalScore,
                    }),
                  )
                  const result = await backendApis.registerItemAdBatch(
                    adItemsInfo,
                    'cpcAd',
                  )
                  SellerStore.setIsLoading(false)
                  fetchSegmentedItemsInfo(true, rankStatusTab)
                  if (result?.status === 200) {
                    alert(
                      `${result?.data?.length}개 상품 대량 광고 신청이 완료되었습니다.`,
                    )
                    if (!SellerQuest.questChecker('adQuest', 'adBatchModal')) {
                      await SellerQuest.questClearer('adQuest', 'adBatchModal')
                    }
                  } else {
                    alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
                  }
                }}
              >
                {(creditInfo?.adCreditInfo?.paidCredit || 0) +
                  (creditInfo?.adCreditInfo?.supportCredit || 0) >
                0
                  ? '선택한 상품 광고 신청'
                  : '광고 신청 불가 (크레딧 부족)'}
              </Button>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
export default CpcAdBatchApplyModal
