const moment = require('moment-business-days')

moment.updateLocale('us', {
  workingWeekdays: [1, 2, 3, 4, 5],
  holidayFormat: 'YYYY-MM-DD',
  holidays: [
    '2023-01-01',
    '2023-01-21',
    '2023-01-22',
    '2023-01-23',
    '2023-01-24',
    '2023-03-01',
    '2023-05-05',
    '2023-05-27',
    '2023-05-29', // 석가탄신일 대체공휴일
    '2023-06-06',
    '2023-08-15',
    '2023-09-28',
    '2023-09-29',
    '2023-09-30',
    '2023-10-03',
    '2023-10-09',
    '2023-12-25',
    '2024-01-01',
    '2024-02-09',
    '2024-02-12',
    '2024-03-01',
    '2024-04-10',
    '2024-05-06',
    '2024-05-15',
    '2024-06-06',
    '2024-08-15',
    '2024-09-16',
    '2024-09-17',
    '2024-09-18',
    '2024-10-03',
    '2024-10-09',
    '2024-12-25',
  ],
})

class SellerScore {}

export default new SellerScore()
