import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Modal from 'react-modal'

import * as Sentry from '@sentry/react'
import configs from './utils/configs'

Sentry.init({
  dsn: configs.sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.001,
  enabled: process.env.NODE_ENV === 'production',

  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
})

Modal.setAppElement('#root')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
