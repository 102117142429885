import React, { useState, useEffect } from 'react'
import { Form, Select, Input, Button, Checkbox, Row, Col } from 'antd'
import SellerStore from '../../stores/SellerStore'
import backendApis from '../../utils/backendApis'
import ItemStore from '../../stores/ItemStore'

const { Option } = Select

const periodButtonList = [
  { name: '전체', id: 0 },
  { name: '1년', id: 365 },
  { name: '1달', id: 30 },
  { name: '1주', id: 7 },
  { name: '1일', id: 1 },
]

const searchFieldList = [
  { name: '상품 키워드', value: 'itemTitle' },
  { name: '상품 아이디', value: 'itemId' },
]

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const SearchFieldToKR = {
  itemTitle: '상품 키워드',
  itemId: '상품 아이디',
  optionCode: '판매자 상품 코드',
  itemIdList: '상품 아이디,상품 아이디..',
}

function ItemSearchBox({
  page = 1,
  setPage = () => {},
  setTotalCount = () => {},
  limit = 20,
  onSearch = () => {},
}) {
  const [itemQuery, setItemQuery] = useState('')
  const [registerItemWindow, setRegisterItemWindow] = useState(0)
  const [itemSellingStatus, setItemSellingStatus] = useState('all')
  const [selectedSearchField, setSelectedSearchField] = useState('itemTitle')
  const [itemsInfo, setItemsInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [largeCategoryInfo, setLargeCategoryInfo] = useState({})
  const [mediumCategoryInfo, setMediumCategoryInfo] = useState({})
  const [smallCategoryInfo, setSmallCategoryInfo] = useState({})
  const [dateTimeSortingType, setDateTimeSortingType] =
    useState('newestCreatedAt')

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (isLoading || !itemQuery) return

    setIsLoading(true)
    SellerStore.setIsLoading(true)

    const itemCondition = {}
    let query

    if (itemQuery !== '') {
      switch (selectedSearchField) {
        case 'itemTitle':
          query = escapeRegExp(itemQuery)
          break
        case 'itemId':
        case 'itemIdList':
          itemCondition._id = { $in: [itemQuery] }
          break
        case 'optionCode':
          itemCondition['optionsInfo.optionPrices.optionCode'] = itemQuery
          break
        default:
          break
      }
    }

    if (registerItemWindow) {
      itemCondition.createdAt = {
        $gt: new Date(
          new Date().getTime() - 1000 * 60 * 60 * 24 * registerItemWindow,
        ),
      }
    }

    if (itemSellingStatus !== 'all') {
      itemCondition.soldOut = itemSellingStatus === 'soldOut'
      itemCondition.$or =
        itemSellingStatus === 'onSale'
          ? [{ outOfStock: false }, { outOfStock: null }]
          : [{ soldOut: true }, { outOfStock: true }]
    }

    if (largeCategoryInfo._id)
      itemCondition['categoryInfo.largeCategoryId'] = largeCategoryInfo._id
    if (mediumCategoryInfo._id)
      itemCondition['categoryInfo.mediumCategoryId'] = mediumCategoryInfo._id
    if (smallCategoryInfo._id)
      itemCondition['categoryInfo.smallCategoryId'] = smallCategoryInfo._id

    const itemSortCondition = {
      [dateTimeSortingType.includes('CreatedAt') ? 'createdAt' : 'updatedAt']:
        dateTimeSortingType.includes('newest') ? -1 : 1,
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
          itemSortCondition,
          query,
        },
      )
      if (itemsCountResult?.status === 200) setTotalCount(itemsCountResult.data)
      if (itemsCountResult?.status !== 200) {
        setPage(1)
        setItemsInfo([])
        onSearch([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        setIsLoading(false)
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      { type: 'item', itemCondition, itemSortCondition, query },
      page,
      limit,
    )
    if (result?.status === 2000) {
      const filteredItemInfo = result.data.itemsInfo.filter(
        (itemInfo) => !itemInfo.characteristicItem?.isGroceryDealCoreItem,
      )
      setItemsInfo(filteredItemInfo)
      onSearch(filteredItemInfo)
    } else {
      window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
      setPage(1)
      setItemsInfo([])
      onSearch([])
    }

    setIsLoading(false)
    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
  }, [limit, dateTimeSortingType])

  useEffect(() => {
    if (ItemStore.categories.length === 0) {
      ItemStore.loadCategories()
    }
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  const resetValues = () => {
    setItemQuery('')
    setRegisterItemWindow(0)
    setItemSellingStatus('all')
    setSelectedSearchField('itemTitle')
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchSegmentedItemsInfo(true)
    }
  }

  return (
    <Form layout='vertical'>
      <Row
        style={{
          flex: 1,
          display: 'flex',
          width: '100%',
        }}
      >
        <Form.Item label='검색 필드' className='mr-4'>
          <Select value={selectedSearchField} onChange={setSelectedSearchField}>
            {searchFieldList.map((field) => (
              <Option key={field.value} value={field.value}>
                {field.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='검색어' className='w-full flex flex-1'>
          <Input
            className='h-8 border text-sm border-gray-300 rounded-md w-full flex flex-1'
            placeholder={`${SearchFieldToKR[selectedSearchField]}를 입력해 주세요.`}
            value={itemQuery}
            onChange={(e) => setItemQuery(e.target.value)}
            onKeyPress={handleKeyPress} // Add this line
          />
        </Form.Item>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label='카테고리'>
            <Select
              placeholder='대분류'
              value={largeCategoryInfo._id || ''}
              onChange={(value) => {
                setLargeCategoryInfo(
                  ItemStore.categories.find(
                    (category) => category._id === value,
                  ) || {},
                )
                setMediumCategoryInfo({})
                setSmallCategoryInfo({})
              }}
            >
              <Option value=''>==</Option>
              {ItemStore.categories.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.largeCategoryNames.kr}
                </Option>
              ))}
            </Select>
            <Select
              placeholder='중분류'
              value={mediumCategoryInfo._id || ''}
              onChange={(value) => {
                const selectedMediumCategory =
                  largeCategoryInfo.mediumCategories?.find(
                    (category) => category._id === value,
                  )
                setMediumCategoryInfo(selectedMediumCategory || {})
                setSmallCategoryInfo({})
              }}
              style={{ marginTop: '8px' }}
            >
              <Option value=''>==</Option>
              {largeCategoryInfo.mediumCategories?.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.mediumCategoryNames.kr}
                </Option>
              ))}
            </Select>
            <Select
              placeholder='소분류'
              value={smallCategoryInfo._id || ''}
              onChange={(value) => {
                const selectedSmallCategory =
                  mediumCategoryInfo.smallCategories?.find(
                    (category) => category._id === value,
                  )
                setSmallCategoryInfo(selectedSmallCategory || {})
              }}
              style={{ marginTop: '8px' }}
            >
              <Option value=''>==</Option>
              {mediumCategoryInfo.smallCategories?.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.smallCategoryNames.kr}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='판매 상태'>
            <Checkbox
              checked={itemSellingStatus === 'all'}
              onChange={() => setItemSellingStatus('all')}
            >
              전체
            </Checkbox>
            <Checkbox
              checked={itemSellingStatus === 'onSale'}
              onChange={() => setItemSellingStatus('onSale')}
              style={{ marginLeft: '8px' }}
            >
              판매중
            </Checkbox>
            <Checkbox
              checked={itemSellingStatus === 'soldOut'}
              onChange={() => setItemSellingStatus('soldOut')}
              style={{ marginLeft: '8px' }}
            >
              품절
            </Checkbox>
          </Form.Item>
        </Col>
        <div className='flex flex-1 w-full items-end'>
          <Col span={12}>
            <Form.Item label='기간'>
              <Row gutter={4}>
                {periodButtonList.map((button) => (
                  <Col key={button.id}>
                    <Button
                      className={
                        registerItemWindow === button.id ? 'bg-blue-500' : ''
                      }
                      type={
                        registerItemWindow === button.id ? 'primary' : 'default'
                      }
                      onClick={() => setRegisterItemWindow(button.id)}
                    >
                      {button.name}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='검색'>
              <Row gutter={8}>
                <Col span={12}>
                  <Button block onClick={resetValues}>
                    초기화
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    className='bg-blue-500'
                    type='primary'
                    block
                    onClick={() => fetchSegmentedItemsInfo(true)}
                  >
                    검색
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </div>
      </Row>
    </Form>
  )
}

export default ItemSearchBox
