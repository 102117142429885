import SellerStore from '../../stores/SellerStore'
import React from 'react'
import Button from '../atoms/button'
import { observer } from 'mobx-react-lite'
import DealOneClickContent from './dealOneClickContent'
import DealOneClickBanner from '../atoms/dealOneClickBanner'

const NudgeModal = observer(({ type = 'dealOneClickModal' }) => {
  const headerText = (type) => {
    if (type === 'dealOneClickModal') return '딜 원클릭 등록'
    return '제목이 없습니다'
  }

  const BodyContent = ({ type }) => {
    if (type === 'dealOneClickModal')
      return (
        <div className='flex flex-1 flex-col'>
          <div>
            딜 원클릭 등록 대상 셀러로 선정되셨습니다.
            <br /> 판매자 중이신 외부 플랫폼{' '}
            <span className='font-bold text-blue-500'> 딜/행사 </span>상품을
            <span className='font-bold text-blue-500'>
              {' '}
              올웨이즈 딜 후보상품
            </span>
            에 등록하실 수 있습니다.{' '}
          </div>
          <div>
            <div className='mb-5 font-bold '>
              {' '}
              한번의 클릭으로 <span className='text-blue-500'>1억 </span>
              이상의 폭발적인 매출을 만들어보세요
            </div>
            <div className='mb-8'>
              <DealOneClickBanner />
            </div>
          </div>
          <DealOneClickContent chunkSize={3} />
        </div>
      )
    return <>내용이 없습니다.</>
  }

  const storeInitializer = () => {
    const modalStatus = SellerStore?.nudgeModalStatus || {}
    Object?.keys(modalStatus)?.forEach((key) => {
      modalStatus[key] = false
    })
    SellerStore?.setNudgeModalStatus({ dealOneClickModal: false })
  }

  const Header = ({ type }) => {
    return (
      <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
        <h3 className='text-xl font-semibold text-black'>{headerText(type)}</h3>
      </div>
    )
  }

  const Body = ({ type }) => {
    return (
      <div className='flex flex-col items-center justify-start flex-1 px-4 pt-10 overflow-y-scroll h-4/5'>
        <BodyContent type={type} />
      </div>
    )
  }

  const Footer = ({ type }) => {
    const ButtonByType = ({ type }) => {
      return <></>
    }
    return (
      <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
        <ButtonByType type={type} />
        <button
          className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
          type='button'
          onClick={storeInitializer}
        >
          닫기
        </button>
      </div>
    )
  }
  return (
    <>
      {SellerStore?.nudgeModalStatus?.[type] && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 py-4 overflow-y-scroll outline-none'>
            <div className='relative w-full max-w-5xl mx-auto my-auto shadow-lg'>
              <div className='relative flex flex-col flex-1 w-full bg-white border-0 rounded-lg outline-none h-[90vh]'>
                <Header type={type} />
                <Body type={type} />
                <Footer type={type} />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
export default NudgeModal
