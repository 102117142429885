import React, { useState } from 'react'
import { FcAdvertising } from 'react-icons/fc'
import { useEffect } from 'react'
import AdCardList from '../../components/molecules/adCardList'
import SearchAdScreen from './SearchAdScreen'
import CpcAdScreen from './CpcAdScreen'
import AlfarmQuizAdScreen from './AlfarmQuizAdScreen'
import AlfarmStoreAdScreen from './AlfarmStoreAdScreen'
import { FcIdea } from 'react-icons/fc'
import AlfarmEggBreakersScreen from './AlfarmEggBreakersAdScreen'
import AlfarmTrendQuizScreen from './AlfarmTrendQuizScreen'
import AlfarmMinigameAdScreen from './AlfarmMinigameAdScreen'
import AlfarmLeavesAdScreen from './AlfarmLeavesAdScreen'
import AlfarmWinenrDealAdScreen from './AlfarmWinnerDealAdScreen'
import AlfarmEggBreakersScreenV2 from './AlfarmEggBreakersAdScreenV2'
import AlfarmQuizAdScreenV2 from './AlfarmQuizAdScreenV2'

const AdScreen = ({ type }) => {
  const [tab, setTab] = useState(0)

  // 메뉴바 이동했을 때 자동으로 첫 번째 탭으로 이동하도록 설정
  useEffect(() => {
    if (type === 'normal') {
      setTab(0)
    } else if (type === 'alfarm') {
      setTab(8)
    }
  }, [type])

  let selectedTab = null
  switch (tab) {
    case 0:
      selectedTab = <CpcAdScreen />
      break
    case 1:
      selectedTab = <SearchAdScreen />
      break
    case 2:
      selectedTab = <AlfarmLeavesAdScreen />
      break
    case 3:
      selectedTab = <AlfarmMinigameAdScreen />
      break
    case 4:
      selectedTab = <AlfarmQuizAdScreenV2 />
      break
    case 5:
      selectedTab = <AlfarmStoreAdScreen />
      break
    case 6:
      selectedTab = <AlfarmEggBreakersScreenV2 />
      break
    case 7:
      selectedTab = <AlfarmTrendQuizScreen />
      break
    case 8:
      selectedTab = <AlfarmWinenrDealAdScreen />
      break
    default:
      break
  }

  return (
    <>
      <div className='w-[85vw] pl-10 pr-20 py-10 mt-10 '>
        <div className='flex flex-col flex-1 mb-8'>
          <div className='flex flex-row items-center flex-1 mb-5'>
            <FcAdvertising className='w-8 h-8 mr-2' />
            <div className='text-2xl font-bold select-none'>광고 신청</div>
          </div>
          {type === 'normal' && (
            <div className='flex flex-row items-center flex-1 px-5 py-5 my-5 text-lg shadow-md bg-white rounded-xl w-max'>
              <FcIdea className='mr-2 text-lg' />
              <div>
                이제 누구든지 파워 퍼포먼스(CPC) 광고를 시작할 수 있어요. 클릭
                한 번으로 나의 상품을 최상단에 노출해보세요.
              </div>
            </div>
          )}
          <AdCardList tab={tab} setTab={setTab} type={type} />
        </div>
        {selectedTab}
      </div>
    </>
  )
}
export default AdScreen
