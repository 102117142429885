import React from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import SellerStore from '../stores/SellerStore'
import Button from '../components/atoms/button'
import CongratIcon from '../images/congrat.png'

const MainScreen = observer(() => {
  const history = useHistory()

  return (
    <div className='flex w-full flex-col items-center justify-center bg-white p-12 mt-8 rounded-lg'>
      <div className='flex flex-row items-center justify-center flex-1 mb-20 select-none'>
        <img alt='congraturations' src={CongratIcon} className='w-40 h-40' />
      </div>
      <div className='text-2xl font-bold'>
        {SellerStore.sellerInfo.sellerDisplayName}님,{' '}
        <span className='text-positive-500'>올웨이즈 판매자센터</span>에 오신
        것을 환영합니다!
      </div>
      <br />
      <div className='text-lg'>
        <div className='flex flex-col items-center justify-center flex-1'>
          <p>
            추가 정보를 입력한 이후 상품 등록 및 추가 기능을 이용할 수 있습니다.
          </p>
          <Button
            className='mt-10'
            type='button'
            appearance='positive'
            size='lg'
            onClick={() => {
              history.push('/register-basics')
            }}
          >
            추가 정보 입력하러가기
          </Button>
        </div>
      </div>
    </div>
  )
})

export default MainScreen
