import React, { useState } from 'react'
import { Radio, Tabs } from 'antd'

const TabComponent = ({ tabList = [], onTabChange }) => {
  const [size, setSize] = useState('large')
  const [activeTabKey, setActiveTabKey] = useState(tabList[0].label)

  const handleTabChange = (key) => {
    setActiveTabKey(key)
    if (onTabChange) {
      onTabChange(key)
    }
  }

  return (
    <div>
      <Tabs
        defaultActiveKey='1'
        activeTabKey={activeTabKey}
        onChange={handleTabChange}
        size={size}
        style={{
          marginBottom: 32,
        }}
        items={tabList.map((tab) => ({
          label: tab.label,
          key: tab.label,
          children: tab.component,
        }))}
      />
    </div>
  )
}
export default TabComponent
