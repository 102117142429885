import backendApis from '../../utils/backendApis'
import React, { useState, useEffect } from 'react'
import OptionTable from './optionTable'
import Button from '../atoms/button'
import moment from 'moment'
import { Divider } from 'antd'

const DealConfirmModal = ({ dealType = 'tenMinDeal' }) => {
  const [dealConfirmData, setDealConfirmData] = useState([])

  const dealStandChecker = async () => {
    const result = await backendApis.checkDealStandSeller(dealType)
    if (result?.status === 200 && result?.data) {
      setDealConfirmData(result?.data)
    }
  }

  const dealConfirmHandler = async (dealItemInfoArray = [], isConfirmed) => {
    const result = await backendApis.handleDealConfirm(
      dealItemInfoArray,
      isConfirmed,
    )
    if (result?.status === 200) {
      alert('성공적으로 처리되었습니다.')
    } else {
      alert('오류가 발생했습니다. 다시 시도해주세요.')
    }
  }

  useEffect(() => {
    if (dealConfirmData?.length === 0) {
      dealStandChecker()
    }
  }, [])

  const Header = ({ type }) => {
    if (type === 'tenMinDeal') {
      return (
        <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
          <h3 className='text-xl font-semibold text-black'>
            10분핫딜 진행동의
          </h3>
        </div>
      )
    }
  }
  const Body = ({ type, data }) => {
    if (type === 'tenMinDeal') {
      return (
        <div className='flex flex-col items-start justify-start flex-1 px-10 pt-10 overflow-y-scroll h-4/5'>
          <span className=''> 축하드립니다! </span>
          <div>
            다음 상품들이 올웨이즈 내{' '}
            <span className='font-bold text-blue-500'> 최상위 핵심 구좌</span>인
            <span className='font-bold text-blue-500'> 10분 핫딜 매대 </span>에
            배정됐습니다.
          </div>
          <div className='mb-10'>
            진행에 동의해주시면 배정일에 특가가 진행됩니다.
          </div>
          <div className='flex flex-col flex-1 w-full'>
            {data?.map((item) => {
              const [dealOptionsInfo, setDealOptionsInfo] = useState(
                item?.dealOptionsInfo,
              )
              const confirmedItemInfo = {
                dealStandId: item?.dealStandId,
                dealOptionsInfoId: item?.dealOptionsInfoId,
                itemId: item?.itemId,
                sellerId: item?.sellerId,
                dealOptionsInfo,
              }
              const optionHeader = (item?.totalOptions?.length >= 2 && [
                { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
                {
                  Header: '옵션2 / 상세옵션',
                  accessor: 'option2',
                  index: 2,
                },
              ]) || [
                { Header: '옵션1 / 상세옵션', accessor: 'option1', index: 1 },
              ]
              const columns = [
                ...optionHeader,
                {
                  Header: '딜 진행가',
                  accessor: 'teamPurchasePrice',
                  index: 3,
                },
                {
                  Header: '재고량',
                  accessor: 'stockNumber',
                  isEditableData: true,
                  index: 5,
                },
              ]
              return (
                <div key={item?.itemId} className='max-w-5xl'>
                  <div className='flex flex-row justify-between flex-1 w-full'>
                    <img
                      src={item?.mainImageUris?.[0]}
                      alt=''
                      className='w-40 h-40 mb-5 mr-10 rounded-lg shadow-lg'
                    />
                    <div>
                      <div className='flex flex-col flex-1 w-full mb-3'>
                        <div className='text-lg font-bold '>
                          {item?.itemTitle}
                        </div>
                        <div className='mt-5'>
                          <div className='flex flex-row justify-end flex-1'>
                            <div className='flex flex-col mr-10'>
                              <div>
                                {moment(item?.dealDate?.toString()).format(
                                  'YYYY년 MM월 DD일',
                                )}
                              </div>
                              <div className='font-bold text-blue-500'>
                                10분핫딜 배정
                              </div>
                            </div>
                            <Button
                              appearance='neutral'
                              className='mr-4'
                              onClick={async () => {
                                await dealConfirmHandler(
                                  [confirmedItemInfo],
                                  false,
                                )
                                await dealStandChecker()
                              }}
                            >
                              거절
                            </Button>
                            <Button
                              onClick={async () => {
                                await dealConfirmHandler(
                                  [confirmedItemInfo],
                                  true,
                                )
                                await dealStandChecker()
                              }}
                            >
                              진행동의
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <OptionTable
                      optionsInfo={dealOptionsInfo}
                      setOptionsInfo={setDealOptionsInfo}
                      columns={columns}
                    />
                  </div>
                  <Divider />
                </div>
              )
            })}
          </div>
        </div>
      )
    }
  }

  const Footer = ({ type }) => {
    if (type === 'tenMinDeal') {
      return (
        <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
          <Button
            size='xl'
            appearance='positive'
            onClick={async () => {
              await dealConfirmHandler(dealConfirmData, true)
              await dealStandChecker()
            }}
          >
            일괄 진행동의
          </Button>
          <button
            className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
            type='button'
            onClick={() => {
              setDealConfirmData([])
            }}
          >
            닫기
          </button>
        </div>
      )
    }
  }

  return (
    <>
      {dealConfirmData?.length > 0 && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 py-10 overflow-y-scroll outline-none'>
            <div className='relative w-full max-w-4xl mx-auto my-auto shadow-lg'>
              <div className='relative flex flex-col flex-1 w-full bg-white border-0 rounded-lg outline-none h-[80vh]'>
                <Header type={dealType} />
                <Body type={dealType} data={dealConfirmData} />
                <Footer type={dealType} />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}
export default DealConfirmModal
