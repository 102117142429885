import React, { useState } from 'react'
import { Col } from 'antd'
// import BiddingOverviewDashboard from './biddingOverviewDashboard'
import OptionBiddingDataPanelSection from './optionBiddingDataPanelSection'

const DataPanelSection = () => {
  const [optionBiddingCondition, setOptionBiddingCondition] = useState(() => ({
    tab: 'losingOption',
    keyword: null,
  }))

  // const { tab } = optionBiddingCondition

  // const handleTabChange = useCallback((tab) => {
  //   setOptionBiddingCondition((prevState) => ({
  //     ...prevState,
  //     tab,
  //     viewMode: 'all',
  //     keyword: null,
  //   }))
  // }, [])

  return (
    <Col span={24}>
      <OptionBiddingDataPanelSection condition={optionBiddingCondition} />
      {/* <div className='mb-5 flex flex-1 w-full flex-row'>
        <BiddingOverviewDashboard />
      </div>
      <Tabs activeKey={tab} onChange={handleTabChange}>
        <TabPane tab='패배중 옵션' key='losingOption'>
          {tab === 'losingOption' && (
            <OptionBiddingDataPanelSection condition={optionBiddingCondition} />
          )}
        </TabPane>
        <TabPane tab='위너 옵션' key='winningOption'>
          {tab === 'winningOption' && (
            <OptionBiddingDataPanelSection condition={optionBiddingCondition} />
          )}
        </TabPane>
        <TabPane tab='검수중 옵션' key='inspectingOption'>
          {tab === 'inspectingOption' && (
            <OptionBiddingDataPanelSection condition={optionBiddingCondition} />
          )}
        </TabPane>
      </Tabs> */}
    </Col>
  )
}

export default DataPanelSection
