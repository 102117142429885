import React, { useState, useEffect } from 'react'
import {
  Modal,
  Row,
  Form,
  Input,
  Radio,
  Tooltip,
  Divider,
  message,
  Col,
  Card,
  Button,
} from 'antd'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import backendApis from '../../../utils/backendApis'
import commaNumber from 'comma-number'
import moment from 'moment'
import LiveBiddingList from './liveBiddingList'

const help = {
  auto: (
    <div className='w-full'>
      <div>
        <ExclamationCircleTwoTone className='mr-2' />
        자동 입찰 기능은 최적의 가격으로 입찰을 진행합니다.
      </div>
      <div className='text-gray-500'>
        입력해주신 최저 입찰가를 기준으로 가격을 낮췄을 때 최상위 노출 확보가
        가능할 경우 자동으로 가격을 낮춥니다.
      </div>
    </div>
  ),
  fixed: (
    <div className='w-full'>
      <ExclamationCircleTwoTone className='mr-2' />
      고정가 입찰을 선택하면 입력해주신 금액으로 입찰을 진행합니다.
    </div>
  ),
  biddingPrice: '입찰하려는 금액을 입력하세요.',
  minBiddingPrice: '자동 입찰 시 사용할 최소 입찰가를 입력하세요.',
}

const biddingTypeKor = {
  auto: '자동 입찰',
  fixed: '고정가 입찰',
}

const dataMapping = {
  unitOption: (data) => ({
    id: data?._id,
    biddingOptionTitle: data?.parsedItemTitle,
    priceUpdatedAt: data?.priceUpdatedAt,
  }),
  generalOption: (data) => ({
    id: data?._id,
    iddingOptionTitle: data?.generalOptionTitle,
    priceUpdatedAt: data?.updatedAt,
  }),
}

const apiGroups = {
  unitOption: {
    getBiddingInfo: backendApis.getUnitOptionBiddingInfo,
    updateBiddingInfo: backendApis.updateUnitOptionBiddingInfo,
  },
  generalOption: {
    getBiddingInfo: backendApis.getGeneralOptionBiddingInfo,
    updateBiddingInfo: backendApis.updateGeneralOptionBiddingInfo,
  },
}

const confirm = Modal?.confirm

function OptionBiddingModal({
  type = 'unitOption',
  isVisible,
  onClose = () => {},
  onReload = () => {},
  biddingOptionId,
  itemId,
  optionId,
  liveBiddingPrice = null,
}) {
  const [form] = Form.useForm()
  const [biddingType, setBiddingType] = useState('auto')
  const [biddingOptionBiddingInfo, setBiddingOptionBiddingInfo] = useState({})
  const [itemInfo, setItemInfo] = useState({})
  const itemTitle = itemInfo?.itemTitle || ''
  const targetTotalOption = itemInfo?.optionsInfo?.totalOptions
    ?.flat()
    .find((e) => e?._id?.toString() === optionId)
  const targetOptionPrice = itemInfo?.optionsInfo?.optionPrices
    ?.flat()
    .find((e) => e?._id?.toString() === optionId)

  const biddingOptionInfo =
    type === 'unitOption'
      ? biddingOptionBiddingInfo.unitOptionInfo
      : biddingOptionBiddingInfo.generalOptionInfo
  const { biddingOptionTitle, priceUpdatedAt } =
    dataMapping[type](biddingOptionInfo) || {}
  const biddingInfo = biddingOptionBiddingInfo?.biddingInfo

  const optionTitle = targetTotalOption?.name || ''
  const currentOptionPrice = targetOptionPrice?.teamPurchasePrice

  const regBiddingType = biddingInfo?.biddingType
  const regBiddingPrice = biddingInfo?.biddingPrice
  const regMinBiddingPrice = biddingInfo?.minBiddingPrice
  const regUpdatedAt = biddingInfo?.updatedAt
    ? biddingInfo?.updatedAt
    : biddingInfo?.createdAt
  const range = 0.3

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          confirm({
            title: '입찰을 진행하시겠습니까?',
            content: (
              <div>
                <div>{itemTitle}</div>
                <div>{optionTitle}</div>
                <div>입찰 유형: {biddingTypeKor?.[values?.biddingType]}</div>
                <div>입찰가: {commaNumber(values.biddingPrice)}원</div>
                {biddingType === 'auto' && (
                  <div>
                    최저 입찰가: {commaNumber(values.minBiddingPrice)}원
                  </div>
                )}
              </div>
            ),
            okButtonProps: { type: 'primary', className: 'bg-blue-500' },
            okText: '입찰하기',
            cancelText: '취소',
            onOk: async () => {
              const response = await apiGroups[type].updateBiddingInfo({
                biddingOptionId,
                itemId,
                optionId,
                values,
              })
              if (response?.status === 200) {
                message.success('입찰이 성공적으로 완료되었습니다.')
                onClose()
                onReload()
              } else {
                message.error('입찰에 실패했습니다. 다시 시도해주세요.')
              }
            },
          })
        } catch (error) {
          message.error('입찰 중 오류가 발생했습니다.')
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info)
      })
  }
  const handleDelete = async (biddingOptionId, itemId, optionId) => {
    const values = {
      deletedAt: new Date(),
    }
    confirm({
      title: '입찰 정보를 삭제하시겠습니까?',
      content: '삭제된 정보는 복구할 수 없습니다.',
      okButtonProps: { type: 'primary', className: 'bg-blue-500' },
      okText: '삭제하기',
      cancelText: '취소',
      onOk: async () => {
        const response = await apiGroups[type].updateBiddingInfo({
          biddingOptionId,
          itemId,
          optionId,
          values,
        })
        if (response?.status === 200) {
          message.success('입찰 정보가 성공적으로 삭제되었습니다.')
          onClose()
          onReload()
        } else {
          message.error('입찰 정보 삭제에 실패했습니다. 다시 시도해주세요.')
        }
      },
    })
  }

  const handleCancel = () => {
    form.resetFields()
    setBiddingType('auto')
    onClose()
  }

  const handleBiddingTypeChange = (e) => {
    setBiddingType(e.target.value)
    form.setFieldsValue({ biddingType: e.target.value })
  }

  useEffect(() => {
    if (!isVisible) return
    const fetchUnitOptionBiddingInfo = async () => {
      const result = await apiGroups[type].getBiddingInfo(
        biddingOptionId,
        itemId,
        optionId,
      )
      if (result?.status === 200) setBiddingOptionBiddingInfo(result?.data)
    }
    const fetchSingeItemInfo = async () => {
      const result = await backendApis.getSingleItemInfo(itemId)
      if (result?.status === 200) {
        setItemInfo(result.data)
      }
    }
    fetchUnitOptionBiddingInfo(biddingOptionId)
    fetchSingeItemInfo(itemId)
  }, [isVisible])

  return (
    <Modal
      title='입찰 설정하기'
      width={1000}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='입찰하기'
      okButtonProps={{ type: 'primary', className: 'bg-blue-500' }}
      cancelText='닫기'
      cancelButtonProps={{
        type: 'text',
      }}
    >
      <Row gutter={16}>
        <Col span={15}>
          <Row>
            <div className='p-4 bg-slate-100 flex-1 flex rounded-md mb-4'>
              {help[biddingType]}
            </div>
          </Row>
          <Row>
            <div>{biddingOptionTitle}</div>
          </Row>
          <Row align='middle'>
            {liveBiddingPrice ? (
              <div className='mr-2'>
                실시간 최저가{' '}
                <span className='text-blue-500 font-bold'>{`${commaNumber(
                  liveBiddingPrice,
                )}원`}</span>
              </div>
            ) : (
              <div className='mr-2'>입찰 정보 없음</div>
            )}
            <div className='text-sm text-gray-400'>
              {moment(priceUpdatedAt).format('YYYY-MM-DD HH:mm')} 기준
            </div>
          </Row>
          <Divider />
          {biddingInfo && (
            <Row>
              <Col span={24}>
                <Row
                  className='mb-4 flex-1 flex w-full font-bold text-gray-600'
                  justify='space-between'
                  align='middle'
                >
                  <Col>기존 입찰 정보</Col>
                  <Col>
                    <Button
                      onClick={() =>
                        handleDelete(biddingOptionId, itemId, optionId)
                      }
                      type='text'
                      size='small'
                    >
                      삭제
                    </Button>
                  </Col>
                </Row>
                <div>{itemTitle}</div>
                <div>{optionTitle}</div>
                <div>입찰 유형: {biddingTypeKor?.[regBiddingType]}</div>
                <div>입찰가: {commaNumber(regBiddingPrice)}원</div>
                {regBiddingType === 'auto' && (
                  <div>최저 입찰가: {commaNumber(regMinBiddingPrice)}원</div>
                )}
                <div className='text-sm text-gray-400'>
                  {moment(regUpdatedAt).format('YYYY-MM-DD HH:mm')} 기준
                </div>
              </Col>

              <Divider />
            </Row>
          )}
          <div className='mb-4 font-bold text-gray-600'>입찰 설정</div>
          <Form form={form} layout='vertical'>
            <Form.Item
              name='biddingType'
              label='입찰 유형'
              required
              initialValue={biddingType}
            >
              <Radio.Group
                onChange={handleBiddingTypeChange}
                value={biddingType}
                defaultValue={biddingType}
              >
                <Radio value='auto'>자동 입찰</Radio>
                <Radio value='fixed'>고정가 입찰</Radio>
              </Radio.Group>
            </Form.Item>
            <Tooltip title={help.biddingPrice}>
              <Form.Item
                label={<span>입찰가</span>}
                name='biddingPrice'
                rules={[
                  { required: true, message: '입찰가를 입력해주세요.' },
                  { pattern: /^[0-9]+$/, message: '숫자만 입력해주세요.' },
                  {
                    validator: (_, value) => {
                      if (value % 10 !== 0) {
                        return Promise.reject(
                          new Error('판매가를 10원 단위로 설정해주세요.'),
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                  {
                    validator: (_, value) => {
                      const maxAllowedPrice = liveBiddingPrice * (1 + range)
                      if (value > maxAllowedPrice) {
                        return Promise.reject(
                          new Error(
                            `입찰가를 실시간 최저가 대비 최대 ${
                              (1 + range) * 100
                            }% 이내로 설정해주세요.`,
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                  {
                    validator: (_, value) => {
                      const maxAllowedPrice = currentOptionPrice
                      if (value > maxAllowedPrice) {
                        return Promise.reject(
                          new Error(
                            `입찰가가 현재 옵션 가격보다 비쌉니다. 현재 옵션 가격: ${commaNumber(
                              currentOptionPrice,
                            )}원`,
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                  {
                    validator: (_, value) => {
                      if (value <= 1000) {
                        return Promise.reject(
                          new Error(
                            '정상 판매가를 입력해주세요.(1,000원 이상)',
                          ),
                        )
                      }
                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <Input
                  type='number'
                  step={10}
                  className='h-8 text-sm rounded-md border-slate-400'
                  placeholder='입찰가를 입력해주세요.'
                  suffix='원'
                />
              </Form.Item>
            </Tooltip>
            {biddingType === 'auto' && (
              <Tooltip title={help.minBiddingPrice}>
                <Form.Item
                  label={<span>최저 입찰가</span>}
                  name='minBiddingPrice'
                  rules={[
                    {
                      required: biddingType === 'auto',
                      message: '최저 입찰가를 입력해주세요.',
                    },
                    { pattern: /^[0-9]+$/, message: '숫자만 입력해주세요.' },
                    {
                      validator: (_, value) => {
                        if (value % 10 !== 0) {
                          return Promise.reject(
                            new Error('판매가를 10원 단위로 설정해주세요.'),
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                    {
                      validator: (_, value) => {
                        const maxAllowedPrice =
                          form.getFieldValue('biddingPrice')
                        if (value > maxAllowedPrice) {
                          return Promise.reject(
                            new Error(
                              '최저 입찰가가 현재 입찰가보다 비쌉니다.',
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                    {
                      validator: (_, value) => {
                        if (value <= 1000) {
                          return Promise.reject(
                            new Error(
                              '정상 판매가를 입력해주세요.(1,000원 이상)',
                            ),
                          )
                        }
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <Input
                    type='number'
                    step={10}
                    className='h-8 text-sm rounded-md border-slate-400'
                    placeholder='하한가를 입력해주세요.'
                    disabled={biddingType === 'fixed'}
                    suffix='원'
                  />
                </Form.Item>
              </Tooltip>
            )}
          </Form>
        </Col>
        {/* 수직 구분선 */}
        <div className='border-l border-gray-100 mx-4' />
        <Col span={8}>
          <Row className='font-bold mb-5 rounded-lg text-gray-600'>
            실시간 순위
          </Row>
          <LiveBiddingList
            biddingOptionId={biddingOptionId}
            itemId={itemId}
            optionId={optionId}
            type={type}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default OptionBiddingModal
