import { useState } from 'react'
import backendApis from '../../utils/backendApis'
import { observer } from 'mobx-react-lite'
import {
  FcVip as VipIcon,
  FcViewDetails as DocIcon,
  FcAlarmClock as ClockIcon,
  FcRating as StarIcon,
} from 'react-icons/fc'

export default observer(function WelcomeModal({
  sellerInfo = {},
  type = '',
  isInnerSeller = false,
}) {
  const [showModal, setShowModal] = useState(true)
  const validTypeList = [
    'price_general',
    'deal_general',
    'deal_top',
    // 'brand_general',
    // 'brand_top',
    'best_general',
    // 'best_top',
  ]
  const Header = ({ type }) => {
    if (type === 'price_general') {
      return (
        <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
          <VipIcon className='w-8 h-8 mr-2' />
          <h3 className='text-xl font-semibold text-black'>
            최저가 셀러님 환영합니다
          </h3>
        </div>
      )
    }
    if (type === 'deal_general') {
      return (
        <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
          <ClockIcon className='w-6 h-6 mr-2' />
          <h3 className='text-xl font-semibold text-black'>
            타임특가 추천 셀러님 환영합니다
          </h3>
        </div>
      )
    }
    if (type === 'deal_top') {
      return (
        <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
          <StarIcon className='w-6 h-6 mr-2' />
          <h3 className='text-xl font-semibold text-black'>
            순삭특가 추천 셀러님 환영합니다
          </h3>
        </div>
      )
    }
    if (type === 'best_general') {
      return (
        <div className='flex items-center justify-start p-5 border-b border-solid border-slate-200'>
          <VipIcon className='w-8 h-8 mr-2' />
          <h3 className='text-xl font-semibold text-black'>
            베스트 셀러님 환영합니다
          </h3>
        </div>
      )
    }
    return <></>
  }
  const Body = ({ sellerInfo, type }) => {
    if (type === 'price_general') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 py-10'>
          <img
            alt='vip'
            src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/f1b681b2-2c0c-4f5e-9089-3d5643cb429b.png'
            className='w-40 mb-5'
          />
          <div>
            {sellerInfo?.sellerDisplayName}님은 현재{' '}
            <span className='font-bold text-blue-500'>최저가 셀러</span>로
            확인되었습니다.
          </div>
          <div>
            가지고 계신 상품들을{' '}
            <span className='font-bold text-blue-500'>최저가 이하 가격</span>
            으로 업로드해주시면
          </div>
          <div className='mb-5'>빠른 초기 판매가 가능합니다.</div>
          <div className='text-sm text-center text-blue-500'>
            * 최저가 셀러 분들께는 입점 초기 빠른 판매를 위해
          </div>
          <div className='mb-2 text-sm text-center text-blue-500'>
            등록하신 최저가 이하 상품에 대해 추가 노출 기회를 드리고 있습니다.
          </div>
        </div>
      )
    }
    if (type === 'deal_general') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 py-10'>
          <img
            alt='clock'
            src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/23853a1a-f66c-44a6-8a3c-cd623feaffe2.png'
            className='w-40 mb-10'
          />
          <div>
            {sellerInfo?.sellerDisplayName}님은 현재{' '}
            <span className='font-bold text-blue-500'>타임특가 추천 셀러</span>
            로 확인되었습니다.
          </div>
          <div>
            제안받으신 상품들을{' '}
            <span className='font-bold text-blue-500'>최저가 이하 가격</span>
            으로 업로드해주시고
          </div>
          <div>상품 등급 배정 후 타임특가에 신청해주시면</div>
          <div className='mb-10'>우선 노출 기회를 얻으실 수 있습니다.</div>
          {/* <div>
            <a
              href='https://alwayz-timedeal.oopy.io/'
              target='_blank'
              rel='noreferrer'
            >
              <div className='flex flex-row items-center flex-1'>
                <DocIcon className='mr-1' />
                <span className='font-bold text-blue-500'>
                  타임특가 가이드 확인하기
                </span>
              </div>
            </a>
          </div> */}
        </div>
      )
    }
    if (type === 'deal_top') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 py-10'>
          <img
            alt='best deal'
            src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/542b5bb7-346b-4c99-8096-2ca2618d6f49.png'
            className='w-40 mb-10'
          />
          <div>
            {sellerInfo?.sellerDisplayName}님은 현재{' '}
            <span className='font-bold text-blue-500'>순삭특가 추천 셀러</span>
            로 확인되었습니다.
          </div>
          <div>
            제안받으신 상품들을{' '}
            <span className='font-bold text-blue-500'>최저가 이하 가격</span>
            으로 업로드해주시고
          </div>
          <div>제안 메일 내 연락처로 연락해주시면</div>
          <div className='mb-10'>순삭특가 노출이 가능합니다.</div>
          <div>
            <a
              href='https://alwayz-sunsak.oopy.io/'
              target='_blank'
              rel='noreferrer'
            >
              <div className='flex flex-row items-center flex-1'>
                <DocIcon className='mr-1' />
                <span className='font-bold text-blue-500'>
                  순삭특가 가이드 확인하기
                </span>
              </div>
            </a>
          </div>
        </div>
      )
    }
    if (type === 'best_general' && isInnerSeller === true) {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 py-10'>
          <img
            alt='ticket'
            src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d7e8ce26-0df0-4284-8e60-78133438ab24.png'
            className='w-40 mb-5'
          />
          <div>
            {sellerInfo?.sellerDisplayName}님은 현재{' '}
            <span className='font-bold text-blue-500'>베스트 셀러</span>로
            확인되었습니다.
          </div>
          <div className='mb-5'>
            특별히 <span className='font-bold text-yellow-500'>보물특가</span>에
            신청하실 수 있는 권한을 드립니다.
          </div>
          <div className='text-sm text-center text-blue-500'>
            *셀러분께서는 온라인 최저가 보다 15% 이상 싼 가격으로 보물특가
            신청이 가능합니다.
          </div>
        </div>
      )
    }

    if (type === 'best_general') {
      return (
        <div className='relative flex flex-col items-center justify-center px-10 py-10'>
          <img
            alt='ticket'
            src='https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d7e8ce26-0df0-4284-8e60-78133438ab24.png'
            className='w-40 mb-5'
          />
          <div>
            {sellerInfo?.sellerDisplayName}님은 현재{' '}
            <span className='font-bold text-blue-500'>베스트 셀러</span>로
            확인되었습니다.
          </div>
          <div>
            상품들을{' '}
            <span className='font-bold text-blue-500'>최저가 이하 가격</span>
            으로 업로드해주시면 빠른 초기 판매가 가능하며,
          </div>
          <div className='mb-5'>
            특별히 <span className='font-bold text-yellow-500'>보물특가</span>에
            신청하실 수 있는 권한을 드립니다.
          </div>
          <div className='text-sm text-center text-blue-500'>
            *받으신 메일에 보물특가를 신청하실 수 있는 코드를 동봉해드렸습니다.
          </div>
        </div>
      )
    }
    return <></>
  }
  const Footer = ({ type }) => {
    return (
      <div className='flex items-center justify-between p-6 bg-gray-100 border-t border-solid rounded-b-xl border-slate-200'>
        <button
          className='px-6 py-2 text-base font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-400 background-transparent focus:outline-none'
          type='button'
          onClick={() => {
            backendApis.updateWelcomeModalOpenInfo(type)
            setShowModal(false)
          }}
        >
          다시 보지 않기
        </button>
        <button
          className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
          type='button'
          onClick={() => {
            setShowModal(false)
          }}
        >
          닫기
        </button>
      </div>
    )
  }

  return (
    <>
      {showModal && type && validTypeList.includes(type) && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none'>
            <div className='relative w-full max-w-xl mx-auto my-6 shadow-lg'>
              <div className='relative flex flex-col w-full bg-white border-0 rounded-lg outline-none'>
                <Header type={type} />
                <Body sellerInfo={sellerInfo} type={type} />
                <Footer type={type} />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
