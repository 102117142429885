import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import SalesInfo from '../components/molecules/salesInfo'
import SettlementInfo from '../components/molecules/settlementInfo'
import Notice from '../components/molecules/notice'
import CsInfo from '../components/molecules/csInfo'
import RevenueInfo from '../components/molecules/revenueInfo'
import WelcomeBanner from '../components/molecules/welcomeBanner'
import OneClickNudge from '../components/molecules/oneClickNudge'
import backendApis from '../utils/backendApis'
import AdCreditInfo from '../components/molecules/adCreditInfo'
import WelcomeModal from '../components/molecules/welcomeModal'
import SellerStore from '../stores/SellerStore'
import SellerPenaltyNotice from '../components/molecules/SellerPenaltyNotice'
import DealConfirmModal from '../components/molecules/dealConfirmModal'
import NudgeModal from '../components/molecules/nudgeModal'
import sellerInfoParser from '../utils/sellerInfoParser'
import ShippingDaysChangeToTwoDaysAlert from '../components/molecules/ShippingDaysChangeToTwoDaysAlert'
import ShippingSystemChangeAlert from '../components/molecules/ShippingSystemChangeAlert'
import TodayDepartureAlert from '../components/molecules/TodayDepartureAlert'
// import DealRerunModal from '../components/atoms/dealRerunModal'
// import NewYear24DealRerunDiscountNudgeModal from '../components/atoms/NewYear24DealRerunDiscountNudgeModal'
// import DealGroceryModal from '../components/atoms/dealGroceryModal'
import DealFamilyModal from '../components/atoms/dealFamilyModal'
import AlfarmFreeAdNoticeModal from '../components/atoms/alfarmFreeAdNoticeModal'
import TempNoticeModal from '../components/TempNoticeModal'
import BiddingOptionNudge from '../components/unitOption/bidding/biddingOptionNudge'

const WelcomeScreen = observer(() => {
  const [isDealOneClickSeller, setIsDealOneClickSeller] = useState(false)
  const [isTempModalOpen, setIsTempModalOpen] = useState(true)
  const sellerFilters = SellerStore.sellerInfo.sellerFilters
  const getValueFromFilters = (sellerFilters, key) => {
    if (!sellerFilters) return null
    for (const filter of sellerFilters) {
      if (filter.key === key) {
        return filter.value
      }
    }
    return null
  }

  const mdManagedBrandName = getValueFromFilters(
    sellerFilters,
    'mdManagedBrandName',
  )
  const hasChuseokDeal24Filter = getValueFromFilters(
    sellerFilters,
    'chuseokDeal24',
  )

  const isChuseokDeal24BrandSeller =
    mdManagedBrandName && hasChuseokDeal24Filter

  useEffect(() => {
    backendApis.recordEventLog('MainScreen', 'entered main screen')
    const fetchDealOneClickData = async () => {
      const bizNumber = sellerInfoParser.bizNumParser(
        sellerInfo?.businessRegistrationNumber,
      )
      const condition = {
        bizNumber,
        oneClickRegisteredAt: null,
        oneClickFailedAt: null,
      }
      const result = await backendApis.getSellerDealOneClickTotalLength(
        condition,
      )
      if (result?.status === 200 && result?.data > 0) {
        setIsDealOneClickSeller(true)
      }
    }
    fetchDealOneClickData()
  }, [])
  const sellerInfo = SellerStore?.sellerInfo
  const sellerTypeFilter = sellerInfo?.sellerFilters?.filter(
    (e) => e.key === 'sellerType',
  )?.[0]

  const RenderWelcomeModal = () => {
    if (sellerTypeFilter?.value && !sellerTypeFilter?.modalOpened) {
      return (
        <WelcomeModal
          sellerInfo={SellerStore?.sellerInfo}
          type={sellerTypeFilter?.value}
          isInnerSeller={sellerTypeFilter?.isInnerSeller || false}
        />
      )
    }
    if (isDealOneClickSeller) {
      return <NudgeModal type='dealOneClickModal' />
    }
    return null
  }

  return (
    <div className='grid w-full grid-cols-2 gap-5 my-10 min-w-7xl max-w-7xl'>
      <div className='col-start-1 col-end-3'>
        <AdCreditInfo />
      </div>
      <div className='col-start-1 col-end-3'>
        <WelcomeBanner />
      </div>
      <div className='col-start-1 col-end-2'>
        <SalesInfo />
      </div>
      <div className='col-start-2 col-end-3'>
        <SettlementInfo />
      </div>
      <div className='col-start-1 col-end-2'>
        <RevenueInfo />
      </div>
      <div className='col-start-2 col-end-3'>
        <BiddingOptionNudge />
      </div>
      <div className='col-start-1 col-end-3'>
        <Notice />
      </div>
      <div className='col-start-1 col-end-3'>
        <SellerPenaltyNotice />
      </div>
      <div className='col-start-1 col-end-3'>
        <CsInfo />
      </div>
      <div className='col-start-1 col-end-3'>
        <ShippingDaysChangeToTwoDaysAlert />
      </div>
      <div className='col-start-1 col-end-3'>
        <ShippingSystemChangeAlert />
      </div>
      <div className='col-start-1 col-end-3'>
        <TodayDepartureAlert />
      </div>
      <div className='col-start-1 col-end-3'>
        {/* <DealOneClickAdModal /> */}
        <DealConfirmModal />
      </div>
      <AlfarmFreeAdNoticeModal />
      {isChuseokDeal24BrandSeller && (
        <DealFamilyModal dealType='chuseokDeal24' />
      )}
      <RenderWelcomeModal />
      {/* <TempNoticeModal
        isOpen={isTempModalOpen}
        onClose={() => {
          setIsTempModalOpen(false)
        }}
      /> */}
    </div>
  )
})

export default WelcomeScreen
