import React, { useEffect, useState } from 'react'
import commaNumber from 'comma-number'
import backendApis from '../../utils/backendApis'
import Table from '../ADS/molecules/table'
import Badge from '../ADS/atoms/badge'
import Pagination from './pagination'

const AdCreditLogTable = () => {
  const limit = 10
  const [page, setPage] = useState(1)
  const [logData, setLogData] = useState([])
  const [logsCount, setLogsCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const typeTranslator = (type) => {
    if (type === 'buy-ad-credit') {
      return <Badge text='광고 크레딧 충전' />
    }
    if (type === 'bonus-credit') {
      return <Badge text='무료 크레딧 지급' color='green' />
    }
    if (type === 'ticket-credit') {
      return <Badge text='크레딧 티켓 사용' color='yellow' />
    }
  }

  const getAdCreditLogInfo = async (limit, logPage) => {
    const count = await backendApis.getAdCreditLogCount()
    if (count?.status === 200) {
      setLogsCount(count?.data)
    } else setLogsCount(0)

    const result = await backendApis.getAdCreditLogInfo(limit, logPage)
    if (result?.status === 200) {
      const creditLog = result?.data.map((e, idx) => {
        const cardName =
          `${e?.paymentInfo?.paymentMethodInfo?.cardName || '-'} / ` +
          `${e?.paymentInfo?.paymentMethodInfo?.cardFirstNumbers || '-'}`
        const creditAmount = commaNumber(e?.paymentInfo?.creditAmount || 0)
        const cancelAmount = commaNumber(
          e?.cancelInfo?.reduce((acc, cur) => acc + cur?.cancelAmount, 0) || 0,
        )
        let availableCreditAmount = 0
        if (e?.type === 'buy-ad-credit') {
          availableCreditAmount = commaNumber(e?.availableCreditAmount || 0)
        }
        return {
          index: idx + 1,
          cardName,
          type: typeTranslator(e?.type),
          creditAmount: `${creditAmount}`,
          cancelAmount: `${cancelAmount}`,
          availableCreditAmount: `${availableCreditAmount}`,
          paymentStartTime: new Date(e?.paymentStartTime).toLocaleString(),
        }
      })
      setLogData(creditLog)
    } else {
      setLogData([])
      setPage(1)
      alert(
        '충전 내역을 불러오는 중 문제가 발생했습니다. 새로고침 후 다시 시도해주세요.',
      )
    }
  }

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true)
    getAdCreditLogInfo(limit, page)
    setIsLoading(false)
  }, [page])

  const columns = [
    { Header: '번호', accessor: 'index' },
    { Header: '결제수단', accessor: 'cardName' },
    { Header: '유형', accessor: 'type' },
    { Header: '충전금액', accessor: 'creditAmount' },
    { Header: '환불가능금액', accessor: 'availableCreditAmount' },
    { Header: '환불금액', accessor: 'cancelAmount' },
    { Header: '결제일시', accessor: 'paymentStartTime' },
  ]

  return (
    <div className='flex flex-col flex-1 w-full'>
      <Table columns={columns} data={logData} />
      <Pagination
        total={logsCount}
        limit={limit}
        page={page}
        setPage={setPage}
        size='md'
      />
    </div>
  )
}
export default AdCreditLogTable
