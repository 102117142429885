import React from 'react'
import { observer } from 'mobx-react-lite'
import CancelingShippingOrders from '../components/CancelingShippingOrders'
import CanceledShippingOrders from '../components/CanceledShippingOrders'
import RefundingOrders from '../components/RefundingOrders'
import RefundedOrders from '../components/RefundedOrders'
import OrderStore from '../stores/OrderStore'
import WithdrawCancelingPreShippingOrderModal from './WithdrawCancelingPreShippingOrderModal'
import Modal from 'react-modal'
import Title from '../components/ADS/atoms/title'
import Tabs from '../components/ADS/molecules/tabs'
import RefundingApprovedOrders from '../components/RefundingApprovedOrders'
import Button from '../components/atoms/button'

const RefundsScreen = observer(() => {
  const refunding = OrderStore.displayedOrders.filter(
    (order) =>
      order?.status === 'refunding' && !order?.refundInfo?.refundStatus,
  )

  const refundingAfterUploadShipping = OrderStore.displayedOrders.filter(
    (order) =>
      order?.status === 'refunding' &&
      (order?.refundInfo?.refundStatus === 'refunding-retrieving' ||
        order?.refundInfo?.refundStatus === 'refunding-retrieved' ||
        order?.refundInfo?.refundStatus === 'refunding-arrived'),
  )

  const refunded = OrderStore.displayedOrders.filter(
    (order) => order.status === 'refunded',
  )

  const tabContArr = [
    {
      title: `배송중인 상품 배송중지 요청 (${(
        OrderStore.ordersNumPerStatus['canceling-shipping'] || 0
      ).toLocaleString()})`,
      content: (
        <CancelingShippingOrders
          orders={OrderStore.displayedOrders.filter(
            (order) => order.status === 'canceling-shipping',
          )}
        />
      ),
    },
    {
      title: `배송중인 상품 배송중지 완료 (${(
        OrderStore.ordersNumPerStatus['canceled-shipping'] || 0
      ).toLocaleString()})`,
      content: (
        <CanceledShippingOrders
          orders={OrderStore.displayedOrders.filter(
            (order) => order.status === 'canceled-shipping',
          )}
        />
      ),
    },
    {
      title: `반품 접수 (${(refunding?.length || 0).toLocaleString()})`,
      content: <RefundingOrders orders={refunding} />,
    },
    {
      title: `반품 중 (${(
        refundingAfterUploadShipping?.length || 0
      ).toLocaleString()})`,
      content: (
        <RefundingApprovedOrders orders={refundingAfterUploadShipping} />
      ),
    },
    {
      title: `반품 완료 (${(refunded?.length || 0).toLocaleString()})`,
      content: <RefundedOrders orders={refunded} />,
    },
  ]
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: '0',
          textAlign: 'center',
          justifyContent: 'center',
          alignmentBaseline: 'baseline',
        }}
      >
        <Title text='반품 관리' />
        <a
          href='https://drive.google.com/u/0/uc?id=1hcXlC-c90Le8oqIRhBWkCqeA6ePvgZ_E&export=download'
          target='_blank'
          rel='noreferrer'
          style={{ marginLeft: 10, padding: '40px 0px 20px' }}
        >
          <Button size='sm'>반품관리 매뉴얼</Button>
        </a>
      </div>
      <Tabs tabContArr={tabContArr} />
      <Modal
        isOpen={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
        onRequestClose={() => {
          OrderStore?.setOpenWithdrawCancelingPreShippingOrderModal(false)
        }}
        style={{
          overlay: {
            zIndex: 20,
          },
          content: {
            left: '20%',
            right: '20%',
            top: '20%',
            bottom: '20%',
            padding: 0,
            // zIndex: 20,
            // marginRight: '-50%',
          },
        }}
      >
        <WithdrawCancelingPreShippingOrderModal
          id='modal'
          setOpenModal={OrderStore?.openWithdrawCancelingPreShippingOrderModal}
          // phoneNumberForSearch={UserStore?.searchedUsers?.phoneNumber}
          // searchedQuestions={searchedQuestions}
          // users={ItemStore.itemQuestions.find(
          //   (orderTemp) => orderTemp?.orderId === orderId,
          // )}
          // analytics={analytics}
          // logEvent={logEvent}
        />
      </Modal>
    </>
  )
})

export default RefundsScreen
