import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import ItemStore from '../stores/ItemStore'
import queryString from 'query-string'
import Page from '../components/atoms/page'
import {
  FcOpenedFolder,
  FcFolder,
  FcPackage as PackageIcon,
} from 'react-icons/fc'
import Card from '../components/atoms/card'
import SubTitle from '../components/atoms/subTitle'
import Button from '../components/atoms/button'
import GoodEx from '../images/goodEx.png'
import BadEx from '../images/badEx.png'

import Tag from '../components/atoms/tag'
import Input from '../components/atoms/input'
import Log from '../utils/log'

const TrendFashionItemApplicationScreen = observer(() => {
  const [mainImagesData, setMainImagesData] = useState([])
  const [tradeMarkRightImage, setTradeMarkRightImage] = useState('')
  const [croppedMainImagesData, setCroppedMainImagesData] = useState([])
  const [keyWord, setKeyWord] = useState('')
  const [route, setRoute] = useState(false)
  const [urlLink, setUrlLink] = useState('')
  const [subUrlLink, setSubUrlLink] = useState('')
  const [sellerInfo, setSellerInfo] = useState([])
  const [headTitle, setHeadTitle] = useState('')
  const [sellerType, setSellerType] = useState('')

  useEffect(async () => {
    const result = await backendApis.getTrendFashionSellerInfo()

    if (result?.status === 2000) {
      setSellerInfo(result?.data[0])
    }
    headTitleSetter()
  }, [])

  const headTitleSetter = () => {
    if (sellerInfo?.trendFashionInfo?.awaitingApproval) {
      return setHeadTitle('승인 대기중')
    }
    return setHeadTitle('입점 신청')
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const { search } = useLocation()
  const { recommender } = queryString.parse(search)

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  return (
    <div className='max-w-7xl'>
      <Page title={headTitle} Icon={PackageIcon}>
        <Card title='스타일 패션 등록'>
          <div className='mb-5 '>
            <div className='flex items-center mb-5 '>
              <SubTitle>판매자 정보를 입력해주세요</SubTitle>
            </div>
            판매 상품 유형을 선택해주세요
            <div className='flex flex-row items-center mb-5'>
              <input
                className='mr-3'
                type='radio'
                name='route'
                value='trendFashion'
                onChange={(e) => {
                  setSellerType(e.target.value)
                }}
              />
              쇼핑몰
              <input
                className='mx-3'
                type='radio'
                name='route'
                value='brandFashion'
                onChange={(e) => {
                  setSellerType(e.target.value)
                }}
              />
              브랜드
            </div>
            {sellerType === 'trendFashion' ? (
              <div>
                <b>
                  *자사의 단일 브랜드 이름을 입력해주세요
                  <span className='text-red-500'>
                    {' '}
                    (해당 브랜드 이름은 모든 아이템에 노출됩니다. 신중히
                    작성해주세요)
                  </span>
                </b>
                <Input
                  className='w-1/2'
                  type='text'
                  placeholder='자사의 단일 브랜드 이름을 입력해주세요'
                  value={keyWord}
                  onChange={(e) => {
                    setKeyWord(e.target.value)
                  }}
                />
                <div className='mt-5'>
                  <ImageUploading
                    multiple
                    value={tradeMarkRightImage}
                    onChange={(imageList, addUpdateIndex) => {
                      setTradeMarkRightImage(imageList)
                      const croppedMainImagesDataTemp = JSON.parse(
                        JSON.stringify(croppedMainImagesData),
                      )
                      if (addUpdateIndex > croppedMainImagesData.length) {
                        croppedMainImagesDataTemp.push({ sourceUri: '' })
                        setCroppedMainImagesData(croppedMainImagesDataTemp)
                      } else {
                        croppedMainImagesDataTemp[addUpdateIndex] = {
                          sourceUri: '',
                        }
                        setCroppedMainImagesData(croppedMainImagesDataTemp)
                      }
                    }}
                    maxFileSize={5e6}
                    acceptType={['jpg', 'png', 'jpeg']}
                    resolutionType='more'
                    resolutionWidth={800}
                    resolutionHeight={800}
                    onError={(e) => {
                      if (e.maxFileSize) {
                        alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                      } else if (e.acceptType) {
                        alert(
                          '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                        )
                      } else if (e.resolution) {
                        alert(
                          '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                        )
                      }
                    }}
                    allowNonImageType='false'
                    maxNumber={10}
                    dataURLKey='sourceUri'
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      dragProps,
                    }) => (
                      <div {...dragProps}>
                        <Button
                          type='button'
                          onClick={onImageUpload}
                          appearance='neutralSub'
                        >
                          상표권 등록하기
                        </Button>
                        <div>
                          <div className='flex flex-row overflow-x-auto '>
                            {imageList.map((image, index) => (
                              <div key={JSON.stringify(image)}>
                                <div className='flex mr-10 w-80 h-80'>
                                  <img
                                    alt='img'
                                    src={image.sourceUri}
                                    className='object-cover object-center border-2 shadow-lg h-80 w-80 border-sub-300'
                                  />
                                </div>
                                {/* <MainImageComponent
                    image={image}
                    index={index}
                    onImageRemove={onImageRemove}
                    onImageUpdate={onImageUpdate}
                    croppedMainImagesData={croppedMainImagesData}
                    setCroppedMainImagesData={setCroppedMainImagesData}
                  /> */}
                                <div>
                                  <button
                                    className='px-2 py-2 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                    type='button'
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    사진변경
                                  </button>
                                  <button
                                    className='px-2 py-2 mx-10 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                    type='button'
                                    onClick={() => {
                                      onImageRemove(index)
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            ) : (
              <div>
                <b>
                  *자사의 대표 브랜드 이름을 입력해주세요
                  <span className='text-red-500'>
                    {' '}
                    (아이템마다 단 하나의 브랜드 코드가 발급됩니다)
                  </span>
                </b>
                <Input
                  className='w-1/2'
                  type='text'
                  placeholder='자사의 단일 브랜드 이름을 입력해주세요'
                  value={keyWord}
                  onChange={(e) => {
                    setKeyWord(e.target.value)
                  }}
                />
                <div className='mt-5'>
                  <ImageUploading
                    multiple
                    value={tradeMarkRightImage}
                    onChange={(imageList, addUpdateIndex) => {
                      setTradeMarkRightImage(imageList)
                      const croppedMainImagesDataTemp = JSON.parse(
                        JSON.stringify(croppedMainImagesData),
                      )
                      if (addUpdateIndex > croppedMainImagesData.length) {
                        croppedMainImagesDataTemp.push({ sourceUri: '' })
                        setCroppedMainImagesData(croppedMainImagesDataTemp)
                      } else {
                        croppedMainImagesDataTemp[addUpdateIndex] = {
                          sourceUri: '',
                        }
                        setCroppedMainImagesData(croppedMainImagesDataTemp)
                      }
                    }}
                    maxFileSize={5e6}
                    acceptType={['jpg', 'png', 'jpeg']}
                    resolutionType='more'
                    resolutionWidth={800}
                    resolutionHeight={800}
                    onError={(e) => {
                      if (e.maxFileSize) {
                        alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                      } else if (e.acceptType) {
                        alert(
                          '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                        )
                      } else if (e.resolution) {
                        alert(
                          '고화질 대표이미지로 더 많은 상품 노출을 획득하세요!\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                        )
                      }
                    }}
                    allowNonImageType='false'
                    maxNumber={10}
                    dataURLKey='sourceUri'
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      dragProps,
                    }) => (
                      <div {...dragProps}>
                        <Button
                          type='button'
                          onClick={onImageUpload}
                          appearance='neutralSub'
                        >
                          상표권 등록하기
                        </Button>
                        <div>
                          <div className='flex flex-row overflow-x-auto '>
                            {imageList.map((image, index) => (
                              <div key={JSON.stringify(image)}>
                                <div className='flex mr-10 w-80 h-80'>
                                  <img
                                    alt='img'
                                    src={image.sourceUri}
                                    className='object-cover object-center border-2 shadow-lg h-80 w-80 border-sub-300'
                                  />
                                </div>
                                {/* <MainImageComponent
                    image={image}
                    index={index}
                    onImageRemove={onImageRemove}
                    onImageUpdate={onImageUpdate}
                    croppedMainImagesData={croppedMainImagesData}
                    setCroppedMainImagesData={setCroppedMainImagesData}
                  /> */}
                                <div>
                                  <button
                                    className='px-2 py-2 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                    type='button'
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    사진변경
                                  </button>
                                  <button
                                    className='px-2 py-2 mx-10 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                                    type='button'
                                    onClick={() => {
                                      onImageRemove(index)
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            )}
            <div>
              <h2 className='mt-5 '>공식 홈페이지 URL</h2>
              <Input
                className='w-1/2'
                type='text'
                placeholder='공식 홈페이지 URL을 입력해주세요'
                value={urlLink}
                onChange={(e) => {
                  setUrlLink(e.target.value)
                }}
              />
            </div>
            <h2 className='mt-5 '>자사몰 제외 대표 판매 채널</h2>
            <Input
              className='w-1/2'
              type='text'
              placeholder='자사몰 제외 대표 판매 채널의 URL을 입력해주세요'
              value={subUrlLink}
              onChange={(e) => {
                setSubUrlLink(e.target.value)
              }}
            />
          </div>
          <Tag text={ItemStore?.itemInfoOnEdit?.itemTitle} />
        </Card>

        <div className='flex flex-row items-center justify-center flex-1 mb-20'>
          <Button
            size='xxl'
            className='mt-5'
            type='button'
            onClick={async () => {
              if (sellerInfo?.trendFashionInfo?.awaitingApproval) {
                alert(
                  '승인 대기 중입니다. 너무 오래 걸릴 시 판매자 지원센터에 문의해주세요',
                )
                return
              }
              if (SellerStore.loadSellerInfo())
                Log.event(
                  'TrendFashionApplicationScreen',
                  'TrendFashionApplicationScreen',
                  {
                    click: 'TrendFashionApplicationScreen',
                  },
                )

              // if (mainImagesData?.length === 0) {
              //   alert('상품 메인 이미지를 업로드해주세요.')
              //   return
              // }

              if (!keyWord) {
                alert('브랜드 이름을 입력해주세요.')
                return
              }
              if (tradeMarkRightImage?.length > 1 && route) {
                alert('상표권 등록은 한가지 브랜드만 가능합니다')
                return
              }
              if (!urlLink) {
                alert('사이트 URL을 입력하세요')
                return
              }

              if (!subUrlLink) {
                alert('사이트 URL을 입력하세요')
                return
              }
              if (!sellerType) {
                alert('판매 상품 유형을 선택해주세요')
                return
              }
              const result =
                await backendApis.uploadTrendFashionApplicationInfo(
                  keyWord,
                  urlLink,
                  subUrlLink,
                  sellerType,
                )
              if (tradeMarkRightImage) {
                await backendApis.uploadTradeMarkImages(
                  SellerStore.sellerInfo._id,
                  tradeMarkRightImage,
                )
              }
              if (result?.status === 2000) {
                setKeyWord('')
                setMainImagesData([])
                setCroppedMainImagesData([])
                alert(
                  `브랜드 신청 등록이 완료되었습니다. 결과는 문자와 메일을 통해 전달됩니다`,
                )
              } else {
                alert(`문제가 발생했습니다. 판매가 지원센터에 문의해주세요.`)
              }
            }}
          >
            입점 신청 완료하기
          </Button>
        </div>
      </Page>
    </div>
  )
})

export default TrendFashionItemApplicationScreen
