import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'

import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'

import queryString from 'query-string'

const MobileRegisterSellerScreen = observer(() => {
  const history = useHistory()
  const { search } = useLocation()
  const { recommender } = queryString.parse(search)
  const [sellerName, setSellerName] = useState('')
  const [password, setPassword] = useState('')
  const [sellerDisplayName, setSellerDisplayName] = useState('')

  const [phoneNumber, setPhoneNumber] = useState('')

  const [email, setEmail] = useState('')
  const [imageData, setImageData] = useState([])
  const [representorName, setRepresentorName] = useState('')
  const [recommenderName, setRecommenderName] = useState(recommender || '')
  const [route, setRoute] = useState('')

  return (
    <div className='absolute inset-0 flex flex-col items-center justify-center w-screen h-screen prose text-center bg-white rounded-md select-none'>
      <div className='flex flex-col items-center justify-center w-full my-10 text-center'>
        <h2>
          <span className='text-theme-500'>올웨이즈</span>와 함께 하기 위해{' '}
          <br />
          입점사 신청을 부탁드려요
        </h2>
        <input
          className='w-2/3 my-2 bg-red-100 rounded-md h-1/10'
          type='text'
          placeholder='판매처 아이디 (영문)'
          value={sellerName}
          onChange={(e) => {
            setSellerName(e.target.value)
          }}
        />
        <input
          className='w-2/3 my-2 bg-red-100 rounded-md h-1/10'
          type='text'
          placeholder='판매처 비밀번호'
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
        />
        <input
          className='w-2/3 my-2 bg-red-100 rounded-md h-1/10'
          type='text'
          value={sellerDisplayName}
          placeholder='  판매처 업체명'
          onChange={(e) => {
            setSellerDisplayName(e.target.value)
          }}
        />
        <input
          className='w-2/3 my-2 bg-red-100 rounded-md h-1/10'
          type='text'
          value={representorName}
          placeholder='대표자 성함'
          onChange={(e) => {
            setRepresentorName(e.target.value)
          }}
        />
        <input
          className='w-2/3 my-2 bg-red-100 rounded-md h-1/10'
          type='text'
          value={phoneNumber}
          placeholder='대표자 핸드폰 번호: (예: 01012345678)'
          onChange={(e) => {
            setPhoneNumber(e.target.value)
          }}
        />
        <input
          className='w-2/3 my-2 bg-red-100 rounded-md h-1/10'
          type='email'
          value={email}
          placeholder='  판매처 이메일'
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />
        <input
          className='w-2/3 mt-2 bg-red-100 rounded-md h-1/10'
          type='text'
          value={recommenderName}
          placeholder='추천인 아이디'
          onChange={(e) => {
            setRecommenderName(e.target.value)
          }}
        />
        <div className='flex flex-col items-center my-4'>
          유입 경로를 선택해주세요
          <div className='flex flex-row items-center'>
            <input
              className='mr-3'
              type='radio'
              name='route'
              value='Marketing'
              onChange={(e) => {
                setRoute(e.target.value)
              }}
            />
            마케팅
            <input
              className='mx-3'
              type='radio'
              name='route'
              value='People'
              onChange={(e) => {
                setRoute(e.target.value)
              }}
            />
            지인소개
            <input
              className='mx-3'
              type='radio'
              name='route'
              value='Call'
              onChange={(e) => {
                setRoute(e.target.value)
              }}
            />
            전화영업
          </div>
        </div>
        <button
          className='w-2/3 py-4 mb-10 text-lg font-black text-white rounded-md bg-theme-500'
          type='button'
          onClick={async () => {
            if (
              !sellerName ||
              !password ||
              !sellerDisplayName ||
              !representorName ||
              //   !description ||
              !phoneNumber ||
              //   !contactNumber ||
              !email ||
              //   !address ||
              //   !mailOrderBusinessNumber ||
              //   !businessRegistrationNumber ||
              //   !defaultShippingCompanyId ||
              //   !bankAccountInfo.bankCode ||
              //   !bankAccountInfo.depositorName ||
              //   !receivingAccountNumber ||
              !route
            ) {
              console.log(`seller information is not complete!`)
              alert('아직 입력하시지 않은 정보가 있습니다.')
              return
            }
            if (SellerStore.isLoading) {
              return
            }
            console.log(`seller info complete`)
            SellerStore.setIsLoading(true)
            if (!recommenderName) {
              const recommenderAbsentConfirm = window.confirm(
                '추천인 아이디를 입력하시면 상단노출을 해드려요! 그냥 가입하시겠어요?',
              )
              if (!recommenderAbsentConfirm) {
                SellerStore.setIsLoading(false)
                return
              }
            } else {
              const sellerBySellerNameResult =
                await backendApis.getSellerBySellerName(recommenderName)
              if (sellerBySellerNameResult?.status !== 2000) {
                alert(
                  '추천인 아이디가 잘못되었습니다. 추천인 아이디를 수정하시거나 지우고 가입해주세요. \n문제가 해결되지 않을 경우 올웨이즈 판매자 지원센터로 연락주세요.\n판매자 지원센터 링크: http://pf.kakao.com/_vxefts/chat',
                )
                SellerStore.setIsLoading(false)
                return
              }
            }

            const result = await backendApis.registerSeller(
              sellerName,
              password,
              sellerDisplayName,
              representorName,

              //   description,
              phoneNumber?.replace(/[- ]/g, ''),
              //   contactNumber,
              email,
              //   mailOrderBusinessNumber,
              //   businessRegistrationNumber,
              //   defaultShippingCompanyId,
              //   {
              //     ...bankAccountInfo,
              //     bankAccountNumber: receivingAccountNumber.replace(/-/g, ''),
              //   },
              recommenderName,
              route,
            )
            if (result?.status === 200) {
              if (imageData.length === 1) {
                await backendApis.setSellerImage(
                  result?.data.sellerId,
                  imageData,
                )
              }
              if (result?.status === 200) {
                SellerStore.setIsLoading(false)
                setSellerName('')
                setSellerDisplayName('')
                setPassword('')
                setPhoneNumber('')
                setEmail('')
                setRepresentorName('')
                AuthStore.setToken(result.data.token)
                localStorage.setItem('@alwayz@seller@token@', result.data.token)
                const loadSellerResult = await SellerStore.loadSellerInfo()
                if (loadSellerResult) {
                  history.replace('/register-basics')
                } else {
                  alert(
                    '정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.',
                  )
                }
              } else if (result.status === 401) {
                alert('이미 존재하는 아이디입니다.')
              } else {
                alert(
                  '등록에 실패했습니다. 전화번호와 이메일 주소가 맞는지 확인해주세요.',
                )
              }

              SellerStore.setIsLoading(false)
            }
          }}
        >
          입점사 신청
        </button>
      </div>
    </div>
  )
})

export default MobileRegisterSellerScreen
