import { FiArrowRight } from 'react-icons/fi'
import { Tooltip } from '../ADS/atoms'

const DealOneClickBanner = () => {
  return (
    <>
      <div className='mb-2 font-bold text-blue-500'>
        자동 행사 진행 프로세스
      </div>
      <div className='flex flex-row items-center flex-1 gap-2'>
        <div className='flex items-center justify-center h-12 p-2 font-bold text-center text-white bg-green-500 w-36 rounded-xl'>
          <div>딜 원클릭 등록</div>
          <Tooltip text='원클릭 등록 대상 상품은 외부 인기 상품으로<br/>올웨이즈에서 제안합니다.' />
        </div>
        <FiArrowRight className='text-2xl text-blue-400' />
        <div className='flex items-center justify-center h-12 p-2 font-bold text-center text-white bg-blue-400 rounded-xl w-28'>
          행사 자동배정
        </div>
        <FiArrowRight className='text-2xl text-blue-400' />
        <div className='flex items-center justify-center h-12 p-2 font-bold text-center text-white bg-green-500 rounded-xl w-28'>
          <div>진행동의</div>
          <Tooltip text='행사 진행 전 판매자센터에서 진행 동의를 받고 진행합니다.<br/> 담당 MD를 통해 연락이 갈 수 있습니다.' />
        </div>
        <FiArrowRight className='text-2xl text-blue-400' />
        <div className='flex items-center justify-center h-12 p-2 font-bold text-center text-white bg-blue-500 rounded-xl w-28'>
          딜 진행
        </div>
      </div>
      <div className='mt-5 text-sm'>
        <div>
          딜 전용상품은 일반 매대에서 판매가 진행되지 않으며, 등록시 자동으로
          특별 행사 제안을 받아보실 수 있습니다.
        </div>
      </div>
    </>
  )
}
export default DealOneClickBanner
