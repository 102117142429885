import React, { useState, useEffect } from 'react'
import backendApis from '../../utils/backendApis'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const DealFamilyModal = ({ dealType = 'familyDeal' }) => {
  const [showModal, setShowModal] = useState(true)
  const onClose = () => setShowModal(false)
  const Header = ({ dealType }) => {
    let modalTitle = ''
    if (dealType === 'familyDeal') modalTitle = '가정의달 기획전 안내'
    if (dealType === 'summerDeal24')
      modalTitle = '여름 블랙프라이데이 연장 신청 안내'
    if (dealType === 'tripleSevenDeal')
      modalTitle = '777 페스티벌 진행 연기 안내'
    if (dealType === 'chuseokDeal24') modalTitle = '추석 앵콜딜 신청 안내'
    return (
      <div className='flex flex-row items-center justify-between w-full p-4 border-b border-solid border-slate-200'>
        <h3 className='text-lg font-semibold text-black'>{modalTitle}</h3>
        <div className='flex flex-row items-center justify-end flex-1'>
          <TooltipOrigin
            arrow='true'
            theme='dark'
            title='❎ 키보드 esc로도 종료하실 수 있어요'
            animation='fade'
            position='top'
          >
            <button
              type='button'
              onClick={onClose}
              className='px-2 py-1 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
            >
              esc
            </button>
          </TooltipOrigin>
        </div>
      </div>
    )
  }

  const Body = ({ dealType }) => {
    let content = <></>
    if (dealType === 'tripleSevenDeal') {
      content = (
        <>
          <div>
            <br />
            안녕하세요, 성장 파트너 올웨이즈입니다.
          </div>
          먼저, 저희 올웨이즈와의 협력에 대해 항상 깊은 감사의 말씀을 드립니다.
          저희가 이전에 안내드린 [777페스티벌] 프로모션에 대해 중요한 공지를
          드리게 되어 매우 죄송스럽습니다.
          <br />
          내부 사정으로 인해 부득이하게 이번 특가 상품 프로모션을 연기해야 하는
          상황이 발생하였습니다.
          <div className='mt-5'>
            기존 7월 1일부터 28일까지 4주간 진행될 예정이었던 777 페스티벌은 2주
            미뤄진{' '}
            <span className='font-bold' style={{ color: '#1258A4' }}>
              7월 15일부터 28일까지
            </span>{' '}
            2주간 진행될 예정입니다.
            <br />
            <br />
            갑작스러운 연기로 인해 귀사에 불편을 끼쳐드려 정말 죄송합니다.
            <br />
            일전 신청해주신 상품은 그대로 7월 15일부터 28일까지 진행되는 777
            페스티벌에 참여하게 됩니다.
            <br />
            다시 한 번 불편을 끼쳐드린 점 깊이 사과드리며, 양해 부탁드립니다.
          </div>
          <br />
        </>
      )
    }
    if (dealType === 'summerDeal24') {
      content = (
        <>
          <div>
            <br />
            셀러님들과 고객님들의 뜨거운 성원에 힘입어 여름 블랙프라이데이
            행사를 2주 연장하게 되었습니다.
          </div>
          <div className='mt-5'>
            여름 블랙프라이데이 기획전은{' '}
            <span className='font-bold' style={{ color: '#1258A4' }}>
              6월 23일까지
            </span>{' '}
            2주 추가 진행될 계획입니다.{' '}
            <span className='font-bold' style={{ color: '#1258A4' }}>
              기존 상품들의 매출 성과가 굉장히 잘 나온 만큼,{' '}
            </span>
            더 많은 셀러님들과 동반 성장할 수 있도록 올웨이즈도 더 많은 지원금과
            구좌를 열어드릴 계획입니다.
            <br />
            <br />
            <div>
              셀러님께서는 기존 신청된 상품을 그대로 유지하거나 변경하실 수
              있습니다.
            </div>
            <br />
            <div>
              할인율은{' '}
              <span className='font-bold' style={{ color: '#1258A4' }}>
                4~20% 사이로 직접 선택하여 신청이 가능
              </span>
              하며, 높은 할인율로 신청시 부스팅 전용 구좌에 노출되며 더 높은
              매출을 보장해드립니다.
            </div>
          </div>
          <br />
        </>
      )
    }
    if (dealType === 'chuseokDeal24') {
      content = (
        <>
          <div className='flex flex-row items-center'>
            올웨이즈 추석 앵콜딜에 참여해주세요.
          </div>
          <div className='flex flex-row items-center'>
            이번 추석, 올웨이즈는 셀러님들에게 특별한 기회를 제공드리고자
            합니다.
          </div>

          <br />

          <div className='flex flex-row items-center'>
            참여한 상품에는 [추석 앵콜딜] 뱃지가 부착됩니다.
          </div>
          <div className='flex flex-row items-center'>
            높은 할인율로 참여하실 경우, 상품노출이 증대되어 더 높은 매출을
            기대하실 수 있습니다.
          </div>

          <div className='flex flex-row items-center'>감사합니다.</div>

          <br />

          <div>
            <li className='mb-1'>
              <span className='font-bold text-[25px]'>추석 앵콜딜 기간: </span>
              <span
                className='font-bold text-[25px]'
                style={{ color: '#FF4A71' }}
              >
                9월 11일 ~ 9월 23일
              </span>
            </li>
            <li className='mb-1'>
              <span className='font-bold text-[25px]'>판매 수수료: </span>
              <span
                className='font-bold text-[25px]'
                style={{ color: '#FF4A71' }}
              >
                8%
              </span>
            </li>
          </div>
        </>
      )
    }
    if (dealType === 'familyDeal')
      content = (
        <>
          <div className='flex flex-row items-center'>
            올웨이즈 가정의달 기획전에 참여해주세요.
          </div>
          <div className='mt-5'>
            가정의달 기획전은{' '}
            <span className='font-bold' style={{ color: '#FF4A71' }}>
              4.26 ~ 5.17
            </span>{' '}
            동안 진행하는{' '}
            <span className='font-bold' style={{ color: '#FF4A71' }}>
              상반기 최대 규모{' '}
            </span>
            행사입니다.
            <div>
              기획전 오픈 3일만에 다음과 같은{' '}
              <span className='font-bold' style={{ color: '#FF4A71' }}>
                비즈니스 효과
              </span>
              가 발생했습니다.
            </div>
          </div>
          <br />

          <div>
            <li className='mb-1'>
              <span
                className='font-bold text-[25px]'
                style={{ color: '#FF4A71' }}
              >
                일 거래액 218% 증가 (ex. 1000만원 → 2180만원)
              </span>
            </li>
            <li className='mb-1'>
              <span
                className='font-bold text-[25px]'
                style={{ color: '#FF4A71' }}
              >
                상품 노출량 388% 증가
              </span>
            </li>
          </div>
        </>
      )

    return <div className='p-5'>{content}</div>
  }

  const Footer = ({ dealType }) => {
    const history = useHistory()
    if (dealType === 'tripleSevenDeal') {
      return (
        <div className='p-4 rounded-b-lg'>
          <Button
            type='primary'
            size='large'
            className='flex items-center justify-center w-full'
            style={{
              backgroundColor: '#1258A4',
              color: 'white',
              borderColor: '#1258A4',
              borderWidth: '2px',
            }}
            onClick={async () => {
              history.push('/triple-seven-deal')
            }}
          >
            상품 신청하러 가기
          </Button>
        </div>
      )
    }
    if (dealType === 'chuseokDeal24') {
      return (
        <div className='p-4 rounded-b-lg'>
          <Button
            type='primary'
            size='large'
            className='flex items-center justify-center w-full'
            style={{
              backgroundColor: '#1258A4',
              color: 'white',
              borderColor: '#1258A4',
              borderWidth: '2px',
            }}
            onClick={async () => {
              history.push('/chuseokdeal24-apply')
            }}
          >
            상품 신청하러 가기
          </Button>
        </div>
      )
    }

    if (dealType === 'summerDeal24') {
      return (
        <div className='p-4 rounded-b-lg'>
          <Button
            type='primary'
            size='large'
            className='flex items-center justify-center w-full'
            style={{ backgroundColor: '#1258A4', color: '#FFF' }}
            onClick={async () => {
              alert(
                '기존 신청하신 상품들에 대해서 성공적으로 연장되었습니다. 여름 블랙프라이데이 기획전은 5.28 ~ 6.23까지 진행됩니다.',
              )
              await backendApis.updateDealExtensionApproval()
              history.push('/summer-deal-24')
            }}
          >
            6/23일까지 연장 동의하기
          </Button>
        </div>
      )
    }
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onClose])

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex px-2 py-10 overflow-y-scroll outline-none'>
            <div className='relative w-full max-w-2xl mx-auto my-auto shadow-lg'>
              <div className='relative flex flex-col justify-between flex-1 w-full bg-white border-0 rounded-lg outline-none'>
                <Header dealType={dealType} />
                <Body dealType={dealType} />
                <Footer dealType={dealType} />
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
}

export default DealFamilyModal
