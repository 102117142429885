import React, { useState, useEffect } from 'react'
import Page from '../components/atoms/page'
import { FcPositiveDynamic as TitleIcon } from 'react-icons/fc'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import sellerInfoParser from '../utils/sellerInfoParser'
import OneClickItemComponent from '../components/ItemComponents/OneClickItemComponent'
import OneClickItemDetailInfo from '../components/ItemComponents/OneClickItemDetailInfo'
import { Input, Pagination, Select, Space } from 'antd'
import Button from '../components/atoms/button'
import { observer } from 'mobx-react-lite'
import AuthStore from '../stores/AuthStore'
import OrderStore from '../stores/OrderStore'
import ListLimitSelector from '../components/atoms/listLimitSelector'

const OneClickItemRegisterScreen = observer(() => {
  const defaultSalesRatio = 10
  const [loadComplete, setLoadComplete] = useState(false)
  const [externalItemData, setExternalItemData] = useState([])
  const [totalLength, setTotalLength] = useState(0)
  const [selectedItemInfo, setSelectedItemInfo] = useState(null)
  const [salesRatio, setSalesRatio] = useState(defaultSalesRatio)
  const [chunkSize, setChunkSize] = useState(20)
  const [crawlQueueInfo, setCrawlQueueInfo] = useState(null)
  const [generalUrl, setGeneralUrl] = useState('https://smartstore.naver.com/')
  const [shippingInfo, setShippingInfo] = useState({
    freeShipping: true,
    shippingFeeInfo:
      '무료배송· 도서산간 추가 배송비- 제주 지역:3000원 -도서산간 지역: 5000원',
    returnFee: 3000,
    returnFeeInfo: '반품 배송비 3000원',
    shippingCompany: 'CJ대한통운',
    shippingDays: 2,
    shippingMethod: '순차배송',
    shippingFee: 0,
  })

  const [uniqueUrl, setUniqueUrl] = useState('')
  const [soldOut, setSoldOut] = useState(false)
  const [selectedCatalogIds, setSelectedCatalogIds] = useState([])
  const [page, setPage] = useState(1)
  const realSalesRatio = (100 - parseInt(salesRatio, 10)) / 100
  const sellerInfo = SellerStore?.sellerInfo
  const bizNumber = sellerInfoParser.bizNumParser(
    sellerInfo?.businessRegistrationNumber,
  )
  const regex = /[^A-Za-z0-9\s\-_]/
  const selectedItemId = selectedItemInfo?._id?.toString()
  const loadNum = externalItemData?.length
  const condition = {
    validBizNumArray: { $in: [bizNumber] },
    registeredBizNumArray: { $nin: [bizNumber] },
    failedBizNumArray: { $nin: [bizNumber] },
  }

  const fetchItemData = async (
    renew = false,
    condition,
    loadNum,
    chunkSize,
  ) => {
    if (!bizNumber) return setLoadComplete(true)
    const result = await backendApis.getSellerWinnerItemData(
      condition,
      loadNum,
      chunkSize,
    )
    if (result?.status === 200) {
      if (renew) {
        setExternalItemData(result?.data)
        setSelectedItemInfo(result?.data[0])
      } else setExternalItemData([...externalItemData, ...result?.data])
    }
    setLoadComplete(true)
  }

  const checkTotalLength = async (condition) => {
    if (!bizNumber) return
    const result = await backendApis.getSellerExternalWinnerDataTotalLength(
      condition,
    )
    if (result?.status === 200) setTotalLength(result?.data)
  }

  const changePage = async (page) => {
    setPage(page)
    await fetchItemData(true, condition, (page - 1) * chunkSize, chunkSize)
  }

  const registerItem = async (
    totalLength,
    chunkSize,
    type = 'all',
    selectedCatalogIds = [],
    shippingInfo = {},
  ) => {
    const confirmText = {
      all: `원클릭 등록을 시작하시겠습니까?

확인 버튼을 누르실 경우 등록이 시작되며,
등록 후에는 개별 상품에 대한 세부 수정이 가능합니다.`,
      selected: `선택하신 상품을 원클릭 등록하시겠습니까?

확인 버튼을 누르실 경우 등록이 시작되며,
등록 후에는 개별 상품에 대한 세부 수정이 가능합니다.`,
    }
    if (window.confirm(confirmText[type]) === false) return
    SellerStore.setIsUploading(true)
    SellerStore.setUploadingStatus({
      type: 'winnerOneClick',
      msg: '원클릭 등록 중입니다. 화면을 종료하지 마세요.',
      doneMsg: '축하드립니다. 상품 등록이 완료되었습니다.',
      total: totalLength,
      success: 0,
      done: 0,
      fail: 0,
    })
    let successCount = 0
    let doneCount = 0
    let failCount = 0
    backendApis.recordEventLog(
      'WinnerOneClickNudge',
      'winner one click register start',
    )
    for (let i = 0; i < totalLength; i += chunkSize) {
      const result = await backendApis.oneClickRegisterWinnerItems(
        chunkSize,
        10,
        soldOut,
        selectedCatalogIds,
        shippingInfo,
      )
      if (result?.status === 200) {
        const { success, fail } = result?.data
        doneCount += success + fail
        successCount += success
        failCount += fail
        SellerStore?.setUploadingStatus({
          ...SellerStore?.uploadingStatus,
          done: parseInt(doneCount, 10),
          success: parseInt(successCount, 10),
          fail: parseInt(failCount, 10),
        })
        if (totalLength - i < chunkSize) {
          await checkTotalLength(condition)
          await fetchItemData(true, condition, loadNum, chunkSize)
          SellerStore?.setUploadingStatus({
            ...SellerStore?.uploadingStatus,
            isComplete: true,
            isAdNudgeOn: true,
          })
        }
      } else {
        window.alert('원클릭 등록 중 문제가 발생했습니다. 다시 시도해주세요.')
        SellerStore.setIsUploading(false)
        SellerStore.setUploadingStatus({
          type: '',
          msg: '',
          doneMsg: '',
          total: 0,
          done: 0,
          success: 0,
          fail: 0,
        })
      }
    }
    await checkTotalLength(condition)
    await fetchItemData(true, condition, loadNum, chunkSize)
  }

  useEffect(() => {
    // checkCrawlQueue()
    checkTotalLength(condition)
    fetchItemData(true, condition, loadNum, chunkSize)
  }, [bizNumber])

  useEffect(() => {
    fetchItemData(true, condition, 0, chunkSize)
  }, [chunkSize])

  return (
    <div>
      <Page title='위너 원클릭 등록' Icon={TitleIcon}>
        <div>
          <div className='flex flex-col flex-1 w-full p-10 mb-5 bg-white shadow-xl rounded-xl'>
            <div>
              셀러님께서 제공하실 수 있는{' '}
              <span className='font-bold text-blue-500'>위너 상품</span>을
              모았습니다.
            </div>
            <div>
              원클릭 기능을 통해 등록된 상품은{' '}
              <span className='font-bold text-blue-500'>위너 연동</span>과 함께{' '}
              <span className='font-bold text-blue-500'>노출량 확대</span>{' '}
              혜택을 드립니다.
            </div>
            <div className='font-bold text-red-500'>
              * 어뷰징 문제로 인해 연동 후 상품을 삭제하신 경우, 재연동은
              불가합니다.
            </div>
            <div className='flex flex-col flex-1 p-5 mt-4 bg-gray-100 rounded-xl'>
              <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                위너 원클릭 등록이란?
              </span>
              <ul className='mx-4 list-disc'>
                <li>
                  외부 쇼핑몰에서 판매 중인 위너 상품 데이터를 최소한의 노력으로
                  한 번에 등록하실 수 있는 기능입니다.
                </li>
                <li>
                  수정이 필요한 경우, 원클릭 등록 후 상품 수정 페이지에서 수정
                  가능합니다.
                </li>
                <li>등록과 동시에 옵션별 위너 상품 매핑이 완료됩니다.</li>
              </ul>
            </div>
          </div>
          {loadComplete && (
            <>
              <div>
                {externalItemData?.length ? (
                  <>
                    <div className='flex flex-row items-center flex-1 mb-5 juestify-between'>
                      <div className='flex items-center'>
                        <div className='mr-2 font-bold'>할인율</div>
                        <div className='mr-5'>
                          <Select
                            defaultValue={defaultSalesRatio}
                            style={{ width: 120 }}
                            onChange={(e) => setSalesRatio(e)}
                            options={[
                              { value: 0, label: '0%' },
                              { value: 5, label: '5%' },
                              { value: 10, label: '10%' },
                              { value: 15, label: '15%' },
                              { value: 20, label: '20%' },
                            ]}
                          />
                        </div>
                        <div className='mr-2 font-bold'>초기 상태</div>
                        <div className='mr-5'>
                          <Select
                            defaultValue={soldOut}
                            style={{ width: 120 }}
                            onChange={(e) => setSoldOut(e)}
                            options={[
                              {
                                value: false,
                                label: '판매중',
                              },
                              {
                                value: true,
                                label: '품절',
                              },
                            ]}
                          />
                        </div>
                        <div className='mr-2 font-bold'>택배사</div>
                        <div className='mr-5'>
                          <Select
                            defaultValue='==='
                            style={{ width: 220 }}
                            onChange={(e) => {
                              const correspondingShippingCompanyInfo =
                                OrderStore.shippingCompaniesInfo.find(
                                  (shippingCompanyInfo) =>
                                    shippingCompanyInfo?.shippingCompanyCode ===
                                    e,
                                )
                              if (correspondingShippingCompanyInfo) {
                                setShippingInfo({
                                  ...shippingInfo,
                                  shippingCompany:
                                    correspondingShippingCompanyInfo.shippingCompanyName,
                                })
                              }
                            }}
                            value={
                              OrderStore.shippingCompaniesInfo.find(
                                (shippingCompanyInfo) =>
                                  shippingCompanyInfo?.shippingCompanyName ===
                                  shippingInfo.shippingCompany,
                              )?.shippingCompanyCode
                            }
                          >
                            <option value=''>===</option>
                            {OrderStore.shippingCompaniesInfo.map(
                              (shippingCompanyInfo) => (
                                <option
                                  key={shippingCompanyInfo?.shippingCompanyCode}
                                  value={
                                    shippingCompanyInfo?.shippingCompanyCode
                                  }
                                >
                                  {shippingCompanyInfo?.shippingCompanyName}
                                </option>
                              ),
                            )}
                          </Select>
                        </div>
                        <div className='mr-2 font-bold'>반품배송비</div>
                        <div className='mr-5'>
                          <Select
                            defaultValue={3000}
                            style={{ width: 120 }}
                            onChange={(e) => {
                              setShippingInfo({
                                ...shippingInfo,
                                returnFee: e,
                                returnFeeInfo: `반품 배송비 ${e}원`,
                              })
                            }}
                            options={[
                              {
                                value: 3000,
                                label: '3000',
                              },
                              {
                                value: 4000,
                                label: '4000',
                              },
                              {
                                value: 5000,
                                label: '5000',
                              },
                              {
                                value: 6000,
                                label: '6000',
                              },
                              {
                                value: 7000,
                                label: '7000',
                              },
                              {
                                value: 8000,
                                label: '8000',
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className='flex flex-row flex-1 justify-end'>
                        <ListLimitSelector
                          limit={chunkSize}
                          setLimit={setChunkSize}
                        />
                      </div>
                    </div>

                    <div className='flex flex-row justify-center flex-1 gap-10 mb-5'>
                      <Button
                        onClick={() =>
                          registerItem(
                            totalLength,
                            chunkSize,
                            'all',
                            [],
                            shippingInfo,
                          )
                        }
                        size='flexlg'
                      >
                        전체 등록하기
                      </Button>
                      <Button
                        appearance='positiveSub'
                        onClick={() =>
                          registerItem(
                            selectedCatalogIds?.length,
                            chunkSize,
                            'selected',
                            selectedCatalogIds,
                            shippingInfo,
                          )
                        }
                        size='flexlg'
                      >
                        선택상품 등록하기
                      </Button>
                    </div>
                    <div className='mb-5'>
                      선택상품 수 : {selectedCatalogIds?.length}개
                    </div>
                    <div className='flex flex-row flex-1 whitespace-normal'>
                      <div className='w-[30vw] mr-10'>
                        {externalItemData.map((item) => (
                          <div
                            key={item?.id}
                            className='mb-5 cursor-pointer'
                            onClick={() => setSelectedItemInfo(item)}
                            aria-hidden
                          >
                            <OneClickItemComponent
                              itemInfo={item}
                              selected={selectedItemId === item?._id.toString()}
                              checkBox
                              onChecked={(value) => {
                                if (value) {
                                  setSelectedCatalogIds([
                                    ...selectedCatalogIds,
                                    item?._id,
                                  ])
                                } else {
                                  setSelectedCatalogIds(
                                    selectedCatalogIds.filter(
                                      (id) => id !== item?._id,
                                    ),
                                  )
                                }
                              }}
                              salesRatio={realSalesRatio}
                            />
                          </div>
                        ))}
                      </div>
                      <OneClickItemDetailInfo itemInfo={selectedItemInfo} />
                    </div>
                  </>
                ) : (
                  <div className='flex flex-col items-center justify-center mb-5 bg-white shadow-xl h-84 rounded-xl'>
                    <div className='mt-10 mb-10 text-lg font-bold'>
                      등록 가능한 상품이 없습니다.
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                defaultCurrent={1}
                current={page}
                onChange={changePage}
                showSizeChanger={false}
                total={totalLength}
              />
            </>
          )}
        </div>
      </Page>
    </div>
  )
})
export default OneClickItemRegisterScreen
