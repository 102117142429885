import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import OrderStore from '../stores/OrderStore'
import backendApis from '../utils/backendApis'
import { FaFileExcel } from 'react-icons/fa'
import SellerStore from '../stores/SellerStore'
import * as ExcelJS from 'exceljs'
import moment from 'moment'
import { saveAs } from 'file-saver'
import Format from '../utils/format'
import tableUtils from '../utils/tableUtils'
import CancelOrderButton from './buttons/CancelOrderButton'
import Table from './atoms/table'
import shortUUID from 'short-uuid'
import {
  getCouponNotSettlementAmount,
  getCouponSettlementAmount,
  getSettlementAmount,
} from '../helper/order'

const PAGE_ORDER_NUM = 10

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

const headers = [
  { header: '주문아이디', key: 'orderId', width: 20 },
  { header: '상품아이디', key: 'itemId', width: 20 },
  { header: '합배송아이디', key: 'addressId', width: 20 },
  { header: '판매자 상품코드', key: 'optionCode', width: 20 },
  { header: '상품명', key: 'itemTitle', width: 30 },
  { header: '옵션', key: 'option', width: 40 },
  { header: '수량', key: 'quantity' },
  { header: '상품가격', key: 'totalPrice' },
  { header: '배송비', key: 'shippingFee' },
  { header: '추가지원금', key: 'platformSupportPrice' },
  {
    header: '올웨이즈 부담 쿠폰 할인금',
    key: 'couponSettlementAmount',
  },
  {
    header: '판매자 부담 쿠폰 할인금',
    key: 'couponNotSettlementAmount',
  },
  { header: '총 쿠폰 할인금', key: 'finalDiscountAmount' },
  { header: '정산대상금액(수수료 제외)', key: 'settlementAmount' },
  { header: '주소', key: 'address', width: 50 },
  { header: '우편번호', key: 'postcode' },
  { header: '공동현관 비밀번호', key: 'frontDoorPassword', width: 20 },
  { header: '수령 방법', key: 'detailForReceivingMethod' },
  { header: '수령인', key: 'recipient' },
  { header: '수령인 연락처', key: 'recipientPhoneNumber', width: 20 },
  { header: '주문 시점', key: 'payedAt', width: 20 },
  { header: '택배사', key: 'shippingCompany' },
  { header: '운송장번호', key: 'shippingNumber', width: 30 },
]

const ArrivedOrders = observer(({ orders, setOrders }) => {
  const translator = shortUUID()

  const [pageIndex, setPageIndex] = useState(0)
  const columns = useMemo(
    () => [
      { Header: '번호', accessor: 'index' },
      { Header: '주문아이디', accessor: '_id' },
      { Header: '상품아이디', accessor: 'itemId' },
      { Header: '상품명', accessor: 'itemTitle' },
      { Header: '옵션', accessor: 'option' },
      { Header: '수량', accessor: 'quantity' },
      {
        Header: '택배사',
        accessor: 'shippingCompanyCode',
        Cell: ({ row }) => {
          const shippingCompanyName =
            OrderStore.shippingCompaniesInfo?.find(
              (shippingCompany) =>
                shippingCompany?.shippingCompanyCode ===
                row?.state?.shippingCompanyCode,
            )?.shippingCompanyName || '-'
          return (
            // <SelectableCell
            //   value={row?.state?.shippingCompanyCode}
            //   options={[
            //     { value: '', text: '===' },
            //     ...(OrderStore.shippingCompaniesInfo?.map(
            //       (shippingCompanyInfo) => ({
            //         value: shippingCompanyInfo.shippingCompanyCode,
            //         text: shippingCompanyInfo.shippingCompanyName,
            //       }),
            //     ) || []),
            //   ]}
            //   onChange={(e) => {
            //     row?.setState((prev) => ({
            //       ...prev,
            //       shippingCompanyCode: e.target.value,
            //     }))
            //   }}
            // />

            <div>{shippingCompanyName}</div>
          )
        },
      },
      {
        Header: '운송장번호',
        accessor: 'shippingNumber',
        Cell: ({ value, row, column, cell }) => {
          return (
            // <EditableCell
            //   value={row?.state?.shippingNumber}
            //   onChange={(e) => {
            //     row?.setState((prev) => ({
            //       ...prev,
            //       shippingNumber: e.target.value,
            //     }))
            //   }}
            // />
            <div>{row?.state?.shippingNumber}</div>
          )
        },
      },
      { Header: '우편발송 여부', accessor: 'postalShipping' },
      { Header: '직접배송 여부', accessor: 'directShipping' },
      { Header: '상품가격', accessor: 'totalPrice' },
      { Header: '추가지원금', accessor: 'platformSupportPrice' },
      {
        Header: '올웨이즈 부담 쿠폰 할인금',
        accessor: 'couponSettlementAmount',
      },
      {
        Header: '판매자 부담 쿠폰 할인금',
        accessor: 'couponNotSettlementAmount',
      },
      { Header: '총 쿠폰 할인금', accessor: 'finalDiscountAmount' },
      { Header: '정산대상금액(수수료 제외)', accessor: 'settlementAmount' },
      { Header: '배송비', accessor: 'shippingFee' },
      { Header: '배송지', accessor: 'address' },
      { Header: '상세 주소', accessor: 'detailAddress' },
      { Header: '수령인', accessor: 'recipient' },
      { Header: '수령인 연락처', accessor: 'recipientPhoneNumber' },
      { Header: '주문 시점', accessor: 'timePast' },
      { Header: '주문 취소', accessor: 'cancel' },
    ],
    [],
  )
  const data = useMemo(() => {
    return orders
      ?.filter(
        (order) => order.status === 'arrived' || order.status === 'reviewed',
      )
      ?.sort((a, b) => new Date(b.payedAt) - new Date(a.payedAt))
      .slice(pageIndex * PAGE_ORDER_NUM, (pageIndex + 1) * PAGE_ORDER_NUM)
      ?.map((order, index) => {
        let timePast = Math.ceil(
          (new Date().getTime() - new Date(order.payedAt).getTime()) /
            (1000 * 60 * 60),
        )
        const daysPast = Math.floor(timePast / 24)
        timePast %= 24
        if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
          if (order?.teamDealInfo?.subAddressInfo) {
            order.addressInfo = order?.teamDealInfo?.subAddressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          } else {
            order.addressInfo = order?.teamDealInfo?.addressInfo
            order.itemInfo.itemTitle = `[동네 공동구매] ${order?.itemInfo?.itemTitle}`
          }
        }
        return {
          ...order,
          index: index + 1 + PAGE_ORDER_NUM * pageIndex,
          itemTitle: (
            <a
              href={order.url
                ?.replace('m.coupang', 'coupang')
                ?.replace('/vm/', '/vp/')}
            >
              {order.itemInfo?.itemTitle}
            </a>
          ),
          option: tableUtils.parseOptionText(
            order?.itemInfo?.optionsInfo,
            order?.selectedOption,
          ),
          itemId: order?.itemInfo?.itemId,
          addressId: `${Format.DateToday(order?.payedAt)}${
            checkIfValidUUID(order?.addressInfo?.addressId) &&
            translator.fromUUID(order?.addressInfo?.addressId || order?._id)
          }`.substring(2),
          optionCode: order?.selectedOption?.optionCode || '',
          shippingCompanyCode:
            !order?.shippingInfo?.isPostalShipping &&
            !order?.shippingInfo?.isDirectShipping &&
            order?.shippingInfo?.shippingCompanyCode,
          shippingNumber: order?.shippingInfo?.shippingNumber,
          uploadShippingNumber: order,
          postalShipping: order?.shippingInfo?.isPostalShipping ? 'O' : '-',
          directShipping: order?.shippingInfo?.isDirectShipping ? 'O' : '-',
          totalPrice: order?.totalPrice,
          // option: optionsText.map((option) => <div key={option}>{option}</div>),
          platformSupportPrice:
            (order.selectedOption?.platformSupportPrice || 0) *
            (order.quantity || 1),
          couponSettlementAmount: getCouponSettlementAmount(order),
          couponNotSettlementAmount: getCouponNotSettlementAmount(order),
          finalDiscountAmount:
            order.selectedCouponInfo?.finalDiscountAmount || 0,
          settlementAmount: getSettlementAmount(order),
          shippingFee: order?.shippingFee,
          address: `${order.addressInfo?.postcode} ${order.addressInfo?.postcodeAddress}`,
          detailAddress: order.addressInfo?.detailAddress,
          recipient: Format.Recipient(order.addressInfo?.recipient),
          recipientPhoneNumber: order.addressInfo?.recipientPhoneNumber,
          timePast: (
            <>
              <div>{moment(order.payedAt).format('YYYY-MM-DD HH:mm')}</div>
              <div>
                ({daysPast > 0 ? `${daysPast}일 ` : ''} {timePast} 시간 전)
              </div>
            </>
          ),
          cancel: (
            <CancelOrderButton
              order={order}
              setOrders={setOrders}
              status='arrived'
            />
          ),
        }
      })
  }, [
    orders?.filter(
      (order) => order.status === 'arrived' || order.status === 'reviewed',
    )?.length,
    pageIndex,
  ])

  return (
    <div id='arrivedOrders' className='flex flex-col flex-1 w-full mb-20'>
      <h1 className='p-5 text-2xl font-bold'>
        배송완료 (
        {setOrders
          ? orders?.filter(
              (order) =>
                order.status === 'arrived' || order.status === 'reviewed',
            )?.length
          : OrderStore?.ordersNumPerStatus.arrived || 0}
        )
      </h1>
      <div
        className='export'
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: 5,
        }}
      >
        <button
          className='flex px-3 py-3 mr-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
          type='button'
          onClick={async () => {
            // if (!shippingInfo?._id) {
            //   alert('택배사를 선택해주세요!')
            //   return
            // }
            if (SellerStore.isLoading) {
              return
            }
            SellerStore.setIsLoading(true)
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('주문 내역')

            const worksheetData = orders
              .filter(
                (order) =>
                  // validPreShippingOrderIds.includes(order._id) &&
                  order.status === 'arrived' || order.status === 'reviewed',
                //  &&
                // !order.excelDownloadedAt,
              )
              // .filter((order) => {
              //   if (filteredItemId) {
              //     return order.itemInfo.itemId === filteredItemId
              //   }
              //   return true
              // })
              .map((order) => {
                const optionsText = order.itemInfo.optionsInfo.optionNames.map(
                  (value, optionIndex) => {
                    const optionDetail =
                      order.itemInfo.optionsInfo.totalOptions[optionIndex][
                        order.selectedOption[optionIndex]
                      ].name
                    return `${optionIndex}. ${value}: ${optionDetail}`
                  },
                )

                if (order?.itemInfo?.characteristicItem?.isApartmentTeamDeal) {
                  if (order?.teamDealInfo?.subAddressInfo) {
                    order.addressInfo = order?.teamDealInfo?.subAddressInfo
                  } else {
                    order.addressInfo = order?.teamDealInfo?.addressInfo
                  }
                }
                const recipientPhoneNumber =
                  order.addressInfo?.recipientPhoneNumber

                const recipientPhoneNumberProcessed = recipientPhoneNumber

                return {
                  orderId: order._id,
                  itemId: order?.itemInfo?.itemId,
                  addressId: `${Format.DateToday(order?.payedAt)}${
                    checkIfValidUUID(order?.addressInfo?.addressId) &&
                    translator.fromUUID(
                      order?.addressInfo?.addressId || order?._id,
                    )
                  }`.substring(2),
                  optionCode: order?.selectedOption?.optionCode || '',
                  itemTitle: order.itemInfo?.itemTitle,
                  option: optionsText.join('\n'),
                  quantity: order.quantity,
                  address: `${order.addressInfo?.postcodeAddress} ${order.addressInfo?.detailAddress}`,
                  postcode: order.addressInfo?.postcode,
                  frontDoorPassword: order.addressInfo?.frontDoorPassword,
                  detailForReceivingMethod:
                    order.addressInfo?.receivingMethod === 'door'
                      ? '문 앞'
                      : order.addressInfo?.detailForReceivingMethod,
                  recipient: Format.Recipient(order.addressInfo?.recipient),
                  recipientPhoneNumber: recipientPhoneNumberProcessed,
                  payedAt: moment(order.payedAt).format('YYYY-MM-DD HH:mm'),
                  shippingCompany: order.shippingInfo.shippingCompanyName,
                  shippingNumber: order.shippingInfo.shippingNumber,
                  totalPrice: order?.totalPrice,
                  // option: optionsText.map((option) => <div key={option}>{option}</div>),
                  platformSupportPrice:
                    (order.selectedOption?.platformSupportPrice || 0) *
                    (order.quantity || 1),
                  couponSettlementAmount: getCouponSettlementAmount(order),
                  couponNotSettlementAmount:
                    getCouponNotSettlementAmount(order),
                  finalDiscountAmount:
                    order.selectedCouponInfo?.finalDiscountAmount || 0,
                  settlementAmount: getSettlementAmount(order),
                  shippingFee: order?.shippingFee,
                }
              })
            worksheet.columns = headers
            worksheet.addRows(worksheetData)
            worksheet.getColumn(3).alignment = { wrapText: true }
            const headerRow = worksheet.getRow(1)
            headers.forEach((header, index) => {
              headerRow.getCell(index + 1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFEA3F49' },
              }
            })
            worksheet.getRow(1).font = {
              color: { argb: 'FFFFFFFF' },
              bold: true,
            }
            const mimeType = {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }
            const buffer = await workbook.xlsx.writeBuffer()
            const blob = new Blob([buffer], mimeType)
            const saveDate = new Date()
            const year = saveDate.getFullYear()
            const month = saveDate.getMonth() + 1
            const date = saveDate.getDate()
            saveAs(
              blob,
              `${
                SellerStore.sellerInfo.sellerDisplayName
              }_배송 완료 내역_${year}-${month < 10 ? `0${month}` : month}-${
                date < 10 ? `0${date}` : date
              }.xlsx`,
            )
            SellerStore.setIsLoading(false)
            alert(
              '발송중, 배송중, 배송완료 주문의 경우 50개씩 다운로드가 가능합니다.',
            )
          }}
        >
          <FaFileExcel />
          엑셀 추출하기
        </button>
        <button
          className='flex px-3 py-3 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
          type='button'
          onClick={() => {
            if (pageIndex > 0) {
              setPageIndex(pageIndex - 1)
            }
          }}
        >
          이전
        </button>
        <div className='flex items-center mb-5'>{pageIndex + 1}</div>
        {/* {`  ${pageIndex + 1}  `} */}
        <button
          className='flex px-3 py-3 mx-3 bg-white border-2 border-black rounded-md mr-30 hover:bg-black hover:text-white'
          type='button'
          onClick={async () => {
            if (SellerStore.isLoading) {
              return
            }
            if (
              pageIndex + 1 <
              orders.filter(
                (order) =>
                  order?.status === 'arrived' || order?.status === 'reviewed',
              ).length /
                PAGE_ORDER_NUM
            ) {
              setPageIndex(pageIndex + 1)
            } else if (orders.length > 0 && !setOrders) {
              SellerStore.setIsLoading(true)
              const result =
                await backendApis.getOrdersInStatusBeforeCertainTimeStamp(
                  'arrived',
                  orders.slice(-1)?.length > 0 && orders.slice(-1)[0]?.payedAt,
                )
              if (result?.status === 200 && result.data?.length > 0) {
                OrderStore.setDisplayedOrders([
                  ...OrderStore.displayedOrders,
                  ...result.data,
                ])
                setPageIndex(pageIndex + 1)
              }
              SellerStore.setIsLoading(false)
            }
          }}
        >
          다음
        </button>
      </div>
      <Table columns={columns} data={data} />
    </div>
  )
})

export default ArrivedOrders
